.home-page {

    @keyframes ball-bounce {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(60px);
        }
    }

    @keyframes ball-roll {
        0% {
            rotate: 0deg;
            translate: 0;
        }

        100% {
            rotate: -90deg;
            translate: -20%;
        }
    }


    /* Components */

    .hero {
        overflow: hidden;
        position: relative;
        max-width: 90rem;
        margin: auto;

        @media (min-width: 1024px) {
            margin-top: 2rem;
        }

        &-container {
            padding-top: 2rem;
            padding-bottom: 2rem;
            display: grid;
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            /* md */
            @media screen and (min-width: 640px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            /* lgMax */
            @media screen and ((min-width: 640px) and (max-width: 1024px)) {
                text-align: center;
                display: flex;
                width: 44rem;
            }

            /* lg */
            @media screen and (min-width: 1024px) {
                padding-top: 4rem;
                padding-bottom: 4rem;
                padding-right: 0;
                gap: 5rem;
            }

            /* xl */
            @media screen and (min-width: 1280px) {
                gap: 10rem;
            }
        }

        &-content {
            display: grid;
            gap: 2rem;
        }

        &__title {
            font-weight: 500;
            font-size: 2.25rem;
            line-height: 1.2;
            font-family: 'Neue Power';

            @media (min-width: 768px) {
                font-size: 3.75rem;
            }

            @media (min-width: 1024px) {
                font-size: 3rem;
            }

            @media (min-width: 1280px) {
                font-size: 3.75rem;
            }

            &--highlight {
                font-weight: 400;
                font-style: italic;
                color: var(--primary-color-2);
            }
        }

        &__description {
            font-size: 1.125rem;
            color: var(--text-soft-color);
            line-height: 1.5;

            @media (min-width: 1024px) {
                font-size: 1.25rem;
            }
        }

        &__actions {
            display: flex;
            align-items: center;

            /* smMax */
            @media screen and (max-width: 640px) {
                justify-content: space-around;
                row-gap: 0.5rem;
            }

            /* md & lgMax */
            @media screen and (min-width: 768px) {
                gap: 1.5rem;

                @media screen and (max-width: 1024px) {
                    justify-content: center;
                    gap: 1.5rem;
                }
            }
        }


        &-partners {
            margin-top: 3rem;
            padding: 0.79rem;
            border: 1px solid rgba(var(--text-soft-color), 0.2);
            border-radius: 0.5rem;

            @media (min-width: 1280px) {
                margin-top: 8rem;
                margin-bottom: 8rem;
            }

            &__title {
                margin-bottom: 0.5rem;
                font-size: 0.75rem;
                font-family: 'Neue Power';
                color: var(--text-soft-color);

                @media (min-width: 1024px) {
                    margin-bottom: 0.82rem;
                    font-size: 0.875rem;
                }
            }

            &__images {
                width: 100%;
                aspect-ratio: 329 / 25;
            }
        }

        &-img {
            display: flex;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: flex-start;

            @media (min-width: 1024px) {
                position: absolute;
                width: 40%;
            }

            &__container {
                position: relative;
                padding-top: 2rem;
            }

            &__ball-1 {
                aspect-ratio: 1 / 1;
                position: absolute;
                left: 1rem;
                top: -1rem;
                width: 33.333333%;

                animation: ball-bounce 2.8s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;

                @media (min-width: 1024px) {
                    top: 0;
                    left: -1rem;
                }
            }

            &__ball-2 {
                aspect-ratio: 1 / 1;
                display: grid;
                position: absolute;
                place-content: center;
                width: 75%;
                bottom: 6%;
                left: 13%;

                animation: ball-roll 2.8s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            }

            &__ball-img {
                filter: hue-rotate(320deg);
            }

            &__hand-container {}
        }
    }

    .meet-some {
        background-color: white;
        display: grid;
        gap: 1.75rem;

        @media (max-width: 1024px) {
            padding-top: 4rem;
        }

        /* lgMax */
        @media screen and (min-width: 768px) {
            padding-bottom: 3rem;
        }

        /* md */
        @media screen and (min-width: 1024px) {
            padding-bottom: 6rem;
        }

        /* lg */

        &__title {
            font-family: 'Neue Power';
            font-weight: 600;
            font-size: 1.875rem;
            line-height: 2.25rem;

            @media (min-width: 768px) {
                font-size: 2.25rem;
                line-height: 2.5rem;
            }

            @media (min-width: 1280px) {
                font-size: 3rem;
                line-height: 1;
            }

            span {
                font-weight: normal;
                color: var(--primary-color-2);
                font-style: italic;
            }
        }

        .carousel-large {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            column-gap: 1rem;
            width: 100%;

            /* mdMax */
            @media (max-width: 767px) {
                display: none;
            }

            @media (min-width: 768px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (min-width: 1024px) {
                column-gap: 1.5rem;
            }

            .small-card {
                position: relative;
                border-radius: 1rem;
                width: 100%;
                background-color: rgba(113, 135, 156, 0.5);
                height: 19rem;

                &__ball-1 {
                    position: absolute;
                    left: 0;
                    top: 0.75rem;
                    border-top-right-radius: 1rem;
                    width: auto;
                }

                &__ball-2 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-top-right-radius: 1rem;
                    width: auto;
                }

                &__ball-3 {
                    position: absolute;
                    bottom: 0;
                    border-top-right-radius: 1rem;
                    width: auto;

                    @media (max-width: 767px) {
                        margin-right: 0;
                    }

                    @media (min-width: 768px) {
                        right: -1.25rem;
                    }

                    @media (min-width: 1024px) {
                        right: 0;
                    }

                    @media (min-width: 1280px) {
                        right: 1.25rem;
                    }
                }
            }
        }

        .carousel-small {
            overflow: hidden;
            position: relative;
            padding-bottom: 2.5rem;


            @media (min-width: 768px) {
                display: none;
            }

            @media (min-width: 1024px) {
                grid-column: span 8 / span 8;
                grid-column-start: 3;
            }

            .carousel-small__content {
                display: grid;
                position: relative;
                place-items: center;
                border-radius: 1rem;
                width: 100%;
                height: 14.9rem;
                background-color: rgba(113, 135, 156, 0.5);
            }
        }

        .play-button {
            padding: 0.75rem;
            border-radius: 9999px;
            background-color: rgba(255, 255, 255, 0.4);
        }

        .card-name {
            font-family: 'Neue Power';
            color: white;
            position: absolute;
            bottom: 1.5rem;
            left: 1.75rem;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
        }

        .indicators {

            width: 100%;
            text-align: center;
            display: flex;
            margin-left: 0.5rem;
            margin-top: 2rem;
            margin-left: 1rem;



            &__selecters {
                display: flex;
                gap: 0.5rem;
            }

            &__selecter {
                color: #afafaf;
                cursor: pointer;
                transition: 200ms;
                border-radius: 50%;

                &--active {
                    color: #222;
                }

                span {
                    width: 100%;
                    display: flex;
                    align-items: inherit;
                    justify-content: inherit;
                }

                svg {
                    font-size: 15px;
                    fill: currentColor;
                    width: 1em;
                    height: 1em;
                    display: inline-block;
                    font-size: 1.5rem;
                    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    flex-shrink: 0;
                    user-select: none;
                }
            }
        }
    }

    .qualities {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;

        &__container {
            display: grid;
            gap: 6rem;
            overflow: hidden;
            padding-right: 0 !important;
            margin-left: auto;
            margin-right: auto;
            padding-top: 4rem;
            padding-bottom: 4rem;
            background-color: rgba(113, 135, 156, 0.05);
            align-items: center;
            border-radius: 1.5rem;

            @media (min-width: 1280px) {
                gap: 13rem;
            }

            /* lg */
            @media screen and (min-width: 1024px) {
                grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
            }
        }

        &__article {
            display: grid;
            padding-left: 0.25rem;
            padding-right: 1.5rem;
            gap: 2rem;
            max-width: 34rem;
        }

        &__title {
            font-family: 'Neue Power';
            font-size: 1.875rem;
            line-height: 2.25rem;
            font-weight: 600;

            @media (min-width: 768px) {
                font-size: 2.25rem;
                line-height: 2.5rem;
            }

            @media (min-width: 1280px) {
                font-size: 3rem;
                line-height: 1;
            }

            span {
                font-weight: normal;
                color: var(--primary-color-2);
                font-style: italic;
            }
        }

        &__description {
            line-height: 140%;
            color: var(--text-soft-color);

            @media (min-width: 768px) {
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }

        &__cta-button {
            display: inline-flex;
            flex-shrink: 0;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
            gap: 0.5rem;
            align-items: center;
            border-radius: 9999px;
            color: #ffffff;
            background-color: var(--primary-color-2);
        }

        &__images {
            display: flex;
            justify-content: flex-end;
            opacity: 0;
            transform: translateX(50%);
            transition-duration: 500ms;
            transition-property: opacity transform;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;

            &.isVisible {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &__image-mobile {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }
        }

        &__image-desktop {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .stats {
        margin-left: auto;
        margin-right: auto;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 3.5rem;
        padding-bottom: 7rem;

        &__title {
            font-family: 'Neue Power';
            margin-bottom: 3.5rem;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 500;
            text-align: center;


            @media (min-width: 768px) {
                font-size: 1.875rem;
                line-height: 2.25rem;
            }

            @media (min-width: 1024px) {
                font-size: 3rem;
                line-height: 1;
                font-weight: 600;
            }

            span {
                font-weight: normal;
                color: var(--primary-color-2);
                font-style: italic;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 2.5rem;
            justify-content: space-evenly;
            align-items: center;

            &-item {
                display: grid;
                gap: 1rem;
                place-content: center;
                font-weight: 500;
                text-align: center;
                flex-shrink: 0;

                &-value {
                    font-family: 'Neue Power';
                    color: var(--text-dark-color);
                    font-size: 2.25rem;
                    line-height: normal;


                    @media (min-width: 768px) {
                        font-size: 3rem;
                        line-height: 1;
                    }

                    @media (min-width: 1280px) {
                        font-size: 4rem;
                    }

                    &--sp {
                        display: flex;
                        align-items: center;

                        @media (min-width: 1280px) {
                            font-size: 2.25rem;
                        }

                        @media (min-width: 1024px) {
                            font-size: 4rem;
                        }
                    }
                }

                &-suffix {
                    color: var(--primary-color-2);
                    font-size: 1rem;
                    line-height: 1.5rem;

                    @media (min-width: 1024px) {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }
                }

                &-imgc {
                    &m {

                        display: none;
                        align-items: center;

                        @media (max-width: 1023px) {
                            display: flex;
                        }
                    }

                    &d {
                        @media (max-width: 1023px) {
                            display: none;
                        }
                    }

                    img {
                        display: inline-block;
                    }
                }

            }

        }

    }

    .st-cards {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: repeat(4, minmax(0, 1fr));
        gap: 2.75rem;
        padding-bottom: 6rem;

        .st-card {
            position: sticky !important;
            width: 100%;
            top: 8%;
            transform: translateY(var(--plan-offset));

            &__wrapper {
                position: relative;
                height: 100%;
            }

            &__container {
                background-color: var(--bg);
                display: grid;
                height: 100%;
                align-items: center;
                padding-top: 2rem;
                padding-bottom: 2rem;
                border-radius: 1.5rem;


                @media (min-width: 768px) {
                    grid-template-columns: 4fr 3fr;
                    padding-top: 2.5rem;
                    padding-bottom: 2.5rem;
                }

                @media (min-width: 1024px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                @media (min-width: 1280px) {
                    gap: 8rem;
                }
            }

            &__article {
                display: grid;
                gap: 2rem;
            }

            &__title {
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: 600;
                line-height: 1.25;
                font-family: "Neue Power";

                @media (min-width: 768px) {
                    font-size: 2.25rem;
                    line-height: 2.5rem;
                }

                @media (min-width: 1280px) {
                    font-size: 3rem;
                    line-height: 1;
                }
            }

            &__description {
                color: var(--text-soft-color);
                line-height: 140%;

                @media (min-width: 1024px) {
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
            }

            &__cta a {
                display: inline-flex;
                align-items: center;
                gap: .5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                border-radius: 9999px;
                font-weight: 500;
                background-color: rgb(113 135 156 / 0.05);

                @media screen and (max-width: 640px) {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                }
            }

            &__img {
                overflow: hidden;
            }
        }
    }

    .est {
        /* estimation */
        padding-top: 5rem;
        padding-bottom: 5rem;


        @media (min-width: 1024px) {
            padding-top: 7rem;
            padding-bottom: 6rem;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            @media (min-width: 1024px) {
                border: solid 1px rgba(var(--text-soft-color), 0.1);
                border-radius: 1.5rem;
                box-shadow: 0 25px 30px 0 rgba(233, 233, 233, 0.4);
                padding: 2.75rem;
            }
        }

        &__container {
            @media (max-width: 1023px) {
                margin-top: 3.5rem;
            }
        }

        .form {
            width: 100%;

            .form__title {
                margin-bottom: 2rem;
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: 700;
                text-align: center;

                @media (min-width: 1024px) {
                    font-size: 2.25rem;
                    line-height: 2.5rem;
                }
            }


            .form__inputs {
                display: flex;
                flex-direction: column;
                margin-top: 1.5rem;
                gap: 1.5rem;
                justify-content: space-between;
                align-items: flex-start;

                @media (min-width: 1024px) {
                    margin-top: 0;
                    flex-direction: row;
                    column-gap: 1.5rem;
                }

                .form__input {
                    display: flex;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                    flex-direction: column;
                    width: 100%;
                    background-color: rgb(113 135 156 / 0.05);

                    @media (min-width: 1024px) {
                        padding-left: 0;
                        padding-right: 0;
                        background-color: #ffffff;
                    }

                    &-label {
                        font-weight: 500;
                        text-align: center;
                        color: var(--text-dark-color);

                        @media (min-width: 1024px) {
                            text-align: left;
                        }
                    }

                    &-sublabel {
                        color: var(--text-soft-color);
                        margin-top: 1rem;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        text-align: center;



                        @media (min-width: 1024px) {
                            order: 2;
                            text-align: left;
                        }
                    }

                    &-slider-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 1rem;
                        gap: 1rem;

                        @media (max-width: 1024px) {
                            flex-direction: column;
                            margin-left: 1rem;
                            margin-top: 0;
                        }

                        .ant-slider {
                            width: 100%;
                            padding: 1.25rem 0;
                        }

                        .ant-slider-handle {
                            transform: translate(-50%, 160%) !important;

                            &::after {
                                box-shadow: 0 0 0 2px #555;
                            }

                            :hover {
                                &::after {
                                    box-shadow: 0 0 0 2.5px #222;
                                    outline: 6px solid #242b342b;
                                }
                            }
                        }

                        .ant-slider-track {
                            background-color: rgb(45, 45, 45);
                            height: 2px !important;
                            border-radius: 999px;
                        }

                        &>p {
                            font-size: max(.83333vw, 16px);
                            text-wrap: nowrap;
                            color: rgb(28, 35, 43);
                        }
                    }
                }
            }

            .form__result {
                margin-top: 2.5rem;
                text-align: center;
                color: var(--text-dark-color);

                &-a {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-top: .25rem;

                    @media (min-width: 1024px) {
                        font-size: 1.25rem;
                        line-height: 1.75rem;
                        color: var(--text-soft-color);
                    }

                    @media (max-width: 1023px) {
                        font-weight: 500;
                    }
                }

                &-b {
                    font-size: 2rem;
                    color: var(--text-dark-color);
                    margin-top: .25rem;

                    @media (min-width: 1024px) {
                        font-size: 3rem;
                        line-height: 1;
                    }
                }

                &-c {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: var(--primary-color-2);
                    margin-top: .25rem;

                    @media (min-width: 1024px) {
                        font-size: 1.25rem;
                        line-height: 1.75rem;
                    }

                    @media (max-width: 1023px) {
                        font-weight: bold;
                    }
                }
            }

        }

        .results {
            margin-top: 2.75rem;
            margin-bottom: 2.75rem;
            width: 100%;

            &__wrapper-1 {
                display: grid;
                place-items: center;
            }

            &__wrapper-2 {
                display: flex;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
                align-items: center;
                font-size: 0.75rem;
                line-height: 1rem;
                background-color: rgb(113 135 156 / 0.05);
            }

            &__item {
                display: flex;
                margin-left: 0.5rem;
                align-items: center;

                &:not(:first-child) {
                    margin-left: 1.75rem;
                }

                span {
                    border-radius: 9999px;
                    width: 0.25rem;
                    height: 0.25rem;
                    background-color: var(--text-soft-color);
                }

                &-label {
                    color: var(--text-soft-color);
                    font-weight: 500;
                    margin-left: .5rem;
                }

                &-value {
                    color: var(--text-dark-color);
                    font-weight: 600;
                    margin-left: .5rem;

                    &--2 {
                        text-overflow: ellipsis;
                        overflow: hidden;

                        @media (min-width: 1024px) {
                            width: fit-content;
                        }
                    }
                }
            }
        }

        .chart {}
    }

    .request {
        display: grid;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: center;
            gap: 2.5rem;
        }

        @media (min-width: 1024px) {
            gap: 8rem !important;
        }

        &__img-container {
            display: grid;
            position: relative;
            padding-top: 3.5rem;
            place-items: center;
            border-radius: 1.875rem;
            background-color: rgb(65 188 196);

        }

        &__img {
            transform: translateY(0);
            opacity: 1;
            transition-property: all;
            transition-duration: 300ms;
            transition-delay: 300ms;
        }

        &__article {
            margin-top: 2rem;
        }

        &__title {
            margin-bottom: 1rem;
            font-weight: 500;
            font-size: 2rem;
            color: var(--text-dark-color);
            font-family: 'Neue Power';

            @media (min-width: 1024px) {
                font-size: 3rem;
                line-height: 1;
            }

            span {
                color: var(--primary-color-2);
                font-style: italic;
            }
        }

        &__description {
            color: var(--text-soft-color);
            margin-top: 2rem;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 500;

            @media (min-width: 1024px) {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }

        &__cta {
            margin: 2rem 0 0;
            padding-inline: 1.75rem !important;
            color: var(--primary-color-2);
        }
    }

    .meet {
        @media (min-width: 1024px) {
            margin-top: 6rem;
            margin-bottom: 6rem;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;



            @media (min-width: 1024px) {
                flex-direction: row;
                align-items: flex-start;
            }
        }

        &__left {
            font-family: 'Neue Power';
            font-size: 2rem;
            color: var(--text-dark-color);
            display: flex;
            margin-bottom: 2rem;



            @media (min-width: 1024px) {
                flex-direction: column;
                font-size: 3rem;
                line-height: 1;
            }
        }

        &__title {
            font-weight: 600;
            line-height: 3.7rem;

            span {

                color: var(--primary-color-2);
                font-style: italic;
                font-weight: 500;
            }
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, max-content)) !important;
            column-gap: 1.25rem;
            row-gap: 2rem;
            justify-content: center;
            list-style: none;


            @media (min-width: 768px) {
                grid-template-columns: repeat(3, minmax(0, max-content)) !important;
            }

            li {
                position: relative;

                &:hover img {
                    --transform-translate-y: -0.625rem;
                }

                img {
                    --transform-translate-y: 0;
                    background-color: #1acd81;
                    border-radius: 0.75rem;
                    transition-property: transform;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 500ms;
                    transform: translateY(var(--transform-translate-y));
                }

                h3 {
                    margin-top: 1rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 700;
                    font-family: 'Neue Power';
                    color: var(--primary-color-2);
                }

                p {
                    font-weight: 700;
                    font-size: 0.813rem;
                    color: var(--text-soft-color);
                }
            }
        }
    }

    .news {
        display: grid;
        padding-top: 5rem;
        padding-bottom: 5rem;

        @media (min-width: 768px) {
            padding-top: 4rem;
            padding-bottom: 4rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @media (max-width: 1023px) {
                column-gap: 2.5rem;
            }
        }

        @media (max-width: 767px) {
            row-gap: 2rem;
        }

        &__show {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        &__title {
            font-size: 2rem;
            font-family: "Neue Power";
            font-weight: 500;
            color: var(--text-dark-color);

            @media (min-width: 768px) {
                font-size: 3rem;
                line-height: 1;
            }

            br {
                @media (max-width: 1023px) {
                    display: none;
                }
            }
        }

        &__description {
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--text-soft-color);

            @media (min-width: 768px) {
                padding-right: 1.25rem;
            }

            @media (min-width: 1024px) {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }

            @media (min-width: 1280px) {
                width: 75%;
            }
        }

        &__cta {
            padding: 0.75rem 1.75rem;
            background-color: var(--primary-color-2) !important;
            color: white;

            &--mob {
                @media (min-width: 768px) {
                    display: none;
                }
            }

            &--des {
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }

        &__links {
            @media (min-width: 768px) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            @media (min-width: 1024px) {
                border-left: solid 3px rgb(113 135 156 / 0.1);
                padding: 1rem;
                padding-left: 5rem;
            }

            &-item {
                display: grid;
                grid-template-columns: 6.5rem 1fr auto;
                align-items: center;
                height: 6.5rem;
                padding-left: 1rem;
                padding-right: 2rem;
                @extend .transition;

                &:hover {
                    background-color: rgb(248 249 249);

                    .arrow {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }

                .img {
                    aspect-ratio: 1 / 1;
                    border-radius: 0.5rem;
                    height: 80%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .title {
                    display: grid;
                    gap: .5rem;

                    &__category {
                        color: black;
                    }

                    &__title {
                        color: var(--text-soft-color);
                        width: 100%;
                        overflow: hidden;
                        line-clamp: 3;


                        @media (min-width: 768px) {
                            padding-right: 1.25rem;
                        }

                        @media (min-width: 1024px) {
                            width: 16rem;
                        }
                    }
                }

                .arrow {
                    opacity: 0;
                    transform: translateX(-50%);
                    color: var(--primary-color-2);
                }
            }
        }

    }

    .joinc {
        padding-bottom: 1.25rem;

        &__wrapper-1 {
            background-image: url("https://risevest.com/images/community.webp");
            background-repeat: no-repeat;
            background-position: right bottom;
            background-color: #059b54;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 13rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            color: #ffffff;
            
            --shadow: 0 25px 30px 0 #04673966 !important;
            --shadow-colored: 0 25px 30px 0 var(--shadow-color) !important;
            box-shadow: var(--ring-offset-shadow, 0 0 #0000), var(--ring-shadow, 0 0 #0000), var(--shadow) !important;

            @media (min-width: 768px) {
                padding-bottom: 10rem;
                border-radius: 1.5rem;
                box-shadow: 0px 25px 30px 0px rgba(65, 188, 196 0.4);
            }

            @media (min-width: 1024px) {
                padding-bottom: 0;
            }
        }

        &__wrapper-2 {
            padding-top: 2.75rem;
            padding-bottom: 2.75rem;
        }

        &__article {
            display: grid;
            gap: 2rem;
        }

        &__title {
            font-size: 2rem;
            color: white;
            font-weight: 600;
            line-height: 1.25;

            @media (min-width: 1024px) {
                font-size: 2.25rem;
                line-height: 2.5rem;
            }
        }

        &__description {
            max-width: 28rem;
            font-size: 1.25rem;
            line-height: 1.75rem;
            line-height: 1.5;



            @media (min-width: 1024px) {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }

        &__cta {
            display: inline-flex;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
            gap: 0.5rem;
            align-items: center;
            border-radius: 9999px;
            font-weight: 600;
            border: solid 1px #fff2;
            color: #ffffff;
        }
    }
}