.statistics-container{
    display: flex;

}
.statistics-title-line{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.statistics-title-line-text{
    margin-left: 10px;
    color: #4B8DFB;
    font-size: 20px;
    font-weight: 600;
    background-color: #F2F4F8;
    padding: 5px;
    border-radius: 7px;
}
.statistics-title-line-text2{
    padding-bottom: 14px;
   
}
.statistics-card{
    min-width: 280px;
    height: 110px;
    margin-bottom: 13px;
    border: 1px solid #D7DBE7;
    background: white;
    border-radius: 25px;
    padding-top: 0px;
    
}
.statistics-title-month {
    font-size: 16px;
    font-weight: 500;
    color: black;
    padding-top: 0px;

}
.statistics-title-day{
    margin-left: 4px;
    color: #75767A;
}
.statistics-title-line-calendar{
    margin-left: 20px;
    position: relative;
    cursor: pointer;
}

.statistics-title-line-calendar img {
    width: 2.5rem;
    height: 2.5em;
}

.statistics-title-details{
    margin-left: 88px;
    color: #2475FE;
}

.statistics-container .ant-statistic-content {
    padding-inline: .5rem;
    color: white;
    border-radius: .5rem;
    font-weight: 700;
    font-size: 22;
    display: flex;
    justify-content: space-between;
    width: fit-content;
}