.asset-breakdown-list {
  margin-top: 2.5rem;

  .ant-list-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 0.5rem;
  }

  .asset-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(37, 35, 35, 0.2);
    padding-bottom: 0.5rem;

    .asset-info {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      line-height: 0.6;

      .asset-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: rgba(8, 152, 160, 0.1);
        margin-bottom: 4px;

        svg {
          width: 1em;
          height: 1em;
        }
      }

      p {
        margin: 0;
      }

      .asset-percentage {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .ant-progress {
          width: 10rem;

          .ant-progress-inner {
            background-color: transparent;
          }

          .ant-progress-bg {
            height: 4px !important;
          }
        }

        span {
          font-size: 0.75rem;
        }
      }
    }

    .asset-amount {
      font-weight: 500;
    }
  }
}