.content-wrapper {
  padding: 2rem 1rem;
  max-width: 600px;
  width: 100%;

  .circular-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: #f5f5f5;
    margin: 0 auto;
    border-radius: 50%;

    .email-icon {
      color: var(--action-primary);
      width: 2rem;
      height: 2.5rem;
    }
  }

  .main-title {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
  }

  .description {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
    color: #7d7d7d;
  }

  .otp-section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .resend-code {
      margin-top: 1rem;
      color: var(--action-primary);
      font-weight: 600;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .alert {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    opacity: 0;

    &.error-alert {
      color: #d32f2f;

      .alert-icon {
        margin-right: 0.5rem;
      }
    }
  }
}
