.withdraw {
  padding: 20px;
  
  &__header {
    position: relative;
    margin-bottom: 32px;
  }

  &__back-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    color: #666;

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    &:hover {
      color: #333;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

  &__content {
    margin-top: 32px;
  }

  &__option {
    height: 64px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    
    &-info {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    &-title {
      font-size: 16px;
    }

    &-subtitle {
      font-size: 12px;
      color: #666;
    }

    &-arrow {
      svg {
        width: 24px;
        height: 24px;
        fill: #666;
      }
    }

    &-button {
      position: absolute;
      inset: 0;
      width: 100%;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.circular-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  height: 48px;
  width: 48px;
  border-radius: 50%;

  svg {
    font-size: 28px;
    color: #007bff;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}