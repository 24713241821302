.dashboard-main-content {
  grid-area: content;
  padding: 1.75rem 1.25rem;
  background-color: white;
  overflow-y: auto;

  @media (min-width: 1024px) {
    padding: 2.25rem 1.75rem 3rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .user-info {
      .greeting {
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
      }

      .username {
        color: #222222;
        font-size: 18px;
        margin-top: 0px;
      }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 22px;

    .refer-button {
        background-color: #EDFFFF;
        border: 3px solid #c5f5f8;
        border-radius: 20px;
        color: #222222;
        font-weight: 500;
        padding-left: 11px;
        padding-right: 11px;
        height: auto;
        display: flex;
        align-items: center;
       

        img {
        width: 16px;
        height: 16px;
        }

        &:hover, &:focus {
        background-color: #D6FFFF;
        border-color: #7FCFD5;
        }
    }

  .notification-badge {
    .ant-badge-count {
      box-shadow: 0 0 0 1px #fff;
    }
  }

  .notification-button {
    color: #222222;
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:focus {
      background: none;
      color: #000000;
    }

    .notification-icon {
      width: 28px;
      height: 28px;
      fill: currentColor;
    }
  }
    }
  }

  .mobile-carousel {
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .balance-card {
    margin-bottom: 2rem;
    box-shadow: 0px 16px 32px rgba(113, 135, 156, 0.2);
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30rem;
    margin-left: 4rem;

    .balance-label {
      color: #71879C;
    }

    .balance-amount {
      font-size: 2rem;
      color: #333333;
    }

    .gains {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .gains-amount {
        color: #27BF41;
      }
    }
  }

  .add-money-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    font-size: 16px;
    font-weight: bold;
    height: 3.5rem;
    color: black;
    background-color: #FFFFFF;
    border: 1px solid #d3d3d3;
    transition: background-color 0.3s;
    border-radius: 5px;

     &:hover {
          background-color: #0055660A !important;
          color: #000000 !important;
          border: 1px solid #d3d3d3 !important;
        }

     .add-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        fill: currentColor;
      }
    }

  .plans-section {
    margin-bottom: 24px;

    .plans-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        }
    
    .plans-scroll-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; 
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

        .plans-title {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        }

        .view-all-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #71879C;
        font-size: 0.975rem;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: darken(#71879C, 10%);
        }

        span {
            margin-right: 0.25rem;
        }

        .chevron-right {
            width: 24px;
            height: 24px;
            fill: currentColor;
        }
     }

     .plans-row {
      display: flex;
      gap: 16px;
      padding-bottom: 16px; 
    }

    .plan-card {
      flex: 0 0 auto;
      width: 11.75rem;
      height: 250px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

        &.new-plan {
            background-color: #F4F6F8;
            border: none;

             &:hover {
                cursor: pointer;
            }

            .circular-container {
                width: 42px;
                height: 42px;
                background-color: white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                margin-left: 2rem;

                .anticon {
                    font-size: 24px;
                    color: #0898A0;
                }
            }

            .plan-text {
            color: #000000;
            font-size: 14px;
            font-weight: 500;
            }
        }

        &.existing-plan {
            background-color: #E3E2FC;
            border: none;
            justify-content: flex-end;
            align-items: flex-start;
            background-image: url('https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg');
            background-size: cover;
            background-position: center;
            padding: 0 !important;
           
            &:hover {
                cursor: pointer;
            }
        

             .plan-content {
                text-align: left;
                align-self: flex-start; 
                margin-top: auto;  
                }

            .plan-name {
            color: #ffffff;
            font-size: 14px;
            }

            .plan-balance {
            color: #ffffff;
            margin: 4px 0;
            }

            .plan-type {
            color: #ffffff;
            font-size: 14px;
            }
        }
        
    }
    }
  .customer-corner {
    margin-bottom: 2rem;
  }

  .quote-section {
    padding: 1.25rem;
    border-radius: 1rem;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3KLsKw0jLKi6EOWlMs2QnOvqlopxW-8i54w&s');
    background-position: 0% 100%;
    background-size: cover;
    background-repeat: no-repeat;

    .quote-label {
      font-weight: bold;
      color: #71879C;
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      display: block;
    }

    .quote-text {
      display: block;
      margin-bottom: 1.25rem;
    }

    .quote-author {
      font-weight: bold;
      color: #71879C;
      font-size: 0.875rem;
    }
  }
  .rise-logo {
  width: 100%;
  max-width: 70px;
  height: auto;
  fill: rgba(113, 135, 156, 0.2);
  display: block;
  margin: 2rem auto;
}


.rise-logo {
  font-size: 16px;
}

@media (min-width: 768px) {
  .rise-logo {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .rise-logo {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1024px) {
  .rise-logo {
    font-size: 24px; 
    margin-top: 5rem;
  }
}
}