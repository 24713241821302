.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;

}

.AppHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: .5rem 1rem;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .AppHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 50ms ease-in-out;
  }

  .AppHeader--fixed {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .AppHeader .ant-space {
    margin-bottom: 10px;
  }
}

.headerDropDown {
  margin-left: 10px;
}

.AppFooter {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

}

.SideMenuAndPageContent {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
}

.SideMenu {
  height: 100%;
  background-color: white;
}

.SideMenuVertical {
  height: 100%;

}

.SideMenuVertical .ant-menu-item {
  display: flex;
  align-items: center;
}

.PageContent {
  overflow: hidden;
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color: white;
}

@media (max-width: 768px) {
  .SideMenuVertical {
    border-inline-end: none !important;
    padding-right: 4rem;
    overflow: auto;
  }

  .SideMenuVertical .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .SideMenuAndPageContent {
    margin-top: 2.5rem;
  }

  .SideMenu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding-top: 1rem;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.1s ease-out;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .SideMenu.active {
    transform: translateX(0);
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  }
}

.SideMenuHorizontal .ant-menu-item-selected,
.SideMenuVertical .ant-menu-item-selected {
  background-color: transparent !important;
}


.sideBarDropDown2 {
  display: flex;
  align-items: center;
}

.sideBarDropDown {
  padding-left: 21px;
  background: white;
  width: 100%;
}

.sideBarDropDown--disabled {
  color: #A3A8BA;
}

.sideBarDropDown2 {
  display: flex;
  align-items: center;
  margin-left: 9px;
}

.sideBarDropDown img {
  width: 20px;
  height: 20px;
}

.sideBarDropDown span {
  padding-left: 9px;
  font-size: 16px;
  font-weight: 600;
}

.sideMenuItemList {
  list-style-type: disc;
  padding-left: 30px;
}

.sideMenuItem {
  font-size: 14px;
  font-weight: 500;
}

.accountlabellist {
  list-style-type: disc;
  padding-left: 30px;
}


.dropdown-overlay {
  margin-left: 30px !important;
  width: 87%;
}

.accountLabel {
  font-size: 14px;
  font-weight: 500;
}

#selectAccount {
  padding-left: 21px;
  background: white;
  width: 100%;
}

#selectAccountIcon {
  width: 20px;
  height: 20px;
}

#selectAccountItems {
  padding-left: 9px;
  font-size: 16px;
  font-weight: 600;
}

#selectAccountDownOutline {
  padding-right: 8px;
  padding-left: 4px;
  padding-top: 3px;
}

.app-header-menu {
  width: 170px;
  padding: 0;
}

.app-header-ul {
  list-style-type: disc;
}

.menu-label {
  font-size: 16px;
  font-weight: 600;
  color: #A3A8BA;
}

.menu-label--sub {
  margin-left: .8rem;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #A3A8BA;
}

.menu-label-black {
  color: black;
}


.menu-icon {
  width: 20px;
  height: 20px;
}

.menu-icon-black {
  color: black;
}

.badge-img {
  padding: calc(0.28em + 0.25vw);
  border: 1px solid #D7DBE7;
  border-radius: 50%;
}

.badge-img2 {
  padding: calc(0.21em + 0.18vw);
  border: 1px solid #D7DBE7;
  border-radius: 50%;
  margin-right: 30px;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-1k979oh).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0px solid rgba(5, 5, 5, 0.06);
}

body.widget_frame_base.tgme_widget.body_widget_login,
div#widget_login,
button.btn.tgme_widget_login_button {
  width: 100%;
}

button.btn.tgme_widget_login_button {
  font-size: 1.5rem;
}

.ant-layout {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

.ant-card:not(.ant-card-bordered) {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}