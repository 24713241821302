.portfolio-editor {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;

  .editor-header {
    position: relative;
    margin-bottom: 24px;
    text-align: center;

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      color: #333;
      
      &:hover {
        color: #000;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .chart-container {
    position: relative;
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;

    .donut-chart {
      width: 260px;
      height: 183px;
    }

    .chart-icon {
      position: absolute;
      top: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      height: 52px;
      width: 52px;
      border-radius: 50%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(188, 16, 88, 0.1);
      font-size: 30px;
    }
  }

  .portfolio-description {
    text-align: center;
    margin: 20px 0 40px;
    color: #666;
    line-height: 1.6;
    padding: 0 20px;

    .emphasis {
      color: #000;
      font-weight: 500;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 24px;

    p {
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }

    .divider {
      height: 1.5px;
      flex-grow: 1;
      background: linear-gradient(to right, #eee, transparent);
    }
  }

  .type-options {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 48px;

    .type-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 28px;
      width: 128px;
      padding: 24px 0 16px;
      border-radius: 12px;
      opacity: 0.45;
      transition: all 0.15s;

      &.active {
        background: rgba(0, 0, 0, 0.05);
        opacity: 1;
      }

      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        width: 52px;
        border-radius: 50%;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.05);

        .icon {
          font-size: 30px;
        }
      }

      p {
        font-size: 15px;
        color: #333;
        text-transform: capitalize;
      }
    }
  }

  .asset-control {
    display: flex;
    align-items: center;
    padding-bottom: 0;

    .asset-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: rgba(188, 16, 88, 0.1);
      color: rgb(188, 16, 88);
    }

    .asset-info {
      margin: 0 12px;
      
      p {
        font-size: 15px;
        color: #333;
      }
    }

    .asset-inputs {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 20px;

      .percentage-input {
        width: 68px;
        text-align: center;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 15px;
        color: #333;
        background: #f5f5f5;

        &:disabled {
          opacity: 0.7;
        }
      }

      .adjustment-buttons {
        display: flex;

        .adjust-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          width: 44px;
          background: rgba(188, 16, 88, 0.05);
          border: none;
          cursor: pointer;
          color: rgb(188, 16, 88);

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          &.decrease {
            border-top-left-radius: 999px;
            border-bottom-left-radius: 999px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }

          &.increase {
            border-top-right-radius: 999px;
            border-bottom-right-radius: 999px;
          }

          &:not(:disabled):hover {
            background: rgba(188, 16, 88, 0.1);
          }
        }
      }
    }
  }

  .action-buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;

    .btn {
      width: 100%;
      padding: 16px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &.primary {
        background: rgb(188, 16, 88);
        color: white;

        &:hover {
          background: darken(rgb(188, 16, 88), 5%);
        }
      }

      &.secondary {
        background: #f5f5f5;
        color: #333;

        &:hover {
          background: darken(#f5f5f5, 5%);
        }
      }
    }
  }
}