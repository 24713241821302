.debit-form {
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100%;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
  margin-top: 20px;

  &__header {
    position: relative;
    margin-bottom: 32px;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--action-primary);

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
}

.wallet-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;

  .text-soft {
    color: #71879C;
  }
}

.arrow-icon {
  margin: -48px auto 0;
  color: var(--action-primary);

  svg {
    width: 24px;
    height: 24px;
  }
}

.currency-inputs {
  margin-top: 32px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.currency-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
  text-transform: uppercase;

  label {
    text-transform: uppercase;
  }

  input {
    flex-grow: 1;
    height: 64px;
    text-align: right;
    border: none;
    outline: none;
    font-weight: 600;
    
    &::placeholder {
      color: black;
    }
  }
}

.exchange-rate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px;
  border-radius: 9999px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  span {
    margin: 0 16px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .rate-info {
    background-color: rgba(0, 169, 166, 0.1);
    border-radius: 9999px;
    padding: 4px 12px;
    color: var(--action-primary);

    button {
      margin-bottom: 1px;
      white-space: nowrap;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

.limit-text {
  color: #71879C;
  margin-top: 16px;
  font-size: 0.875rem;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.submit-button {
  width: 100%;
  margin-top: 32px;
  padding: 16px;
  background-color: var(--action-primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  span {
    padding: 0 40px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}