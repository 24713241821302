/* CurrencyInputForm.css */
.currency-form {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.currency-form__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
}

.currency-form__wrapper {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100vh;
  padding: 1.75rem 1.25rem;
  transition: duration 300ms;
}

@media (min-width: 1024px) {
  .currency-form__wrapper {
    padding: 1.75rem;
  }
}

.currency-form__content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.currency-form__inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.currency-form__rate-container {
  position: relative;
  height: 0;
  z-index: 1;
  border-top: 1px solid #eee;
  margin: 0 16px;
}

.currency-form__rate {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  align-items: center;
  gap: 0;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 999px;
  padding: 2px;
  height: 28px;
  z-index: 2;
}

.currency-form__rate-text {
  padding: 0 12px;
  font-size: 13px;
  color: #333;
  white-space: nowrap;
  line-height: 24px;
  font-weight: 400;
}

.currency-form__info-button {
  font-size: 13px;
  background-color: #e8f3ff;
  color: #006aff;
  padding: 2px 12px;
  border: none;
  border-radius: 999px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  font-weight: 400;
  transition: background-color 0.2s ease;
}

.currency-form__info-button:hover {
  background-color: rgba(0, 106, 255, 0.15);
}

.currency-form__submit {
  width: 100%;
  margin-top: 2.5rem;
}


.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000 !important;
}

.modal {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  padding: 0 1rem;
  background-color: transparent;
  z-index: 1050;
}

.modal__content {
  background-color: white;
  border-radius: 16px;
  padding: 1.75rem 1.25rem;
  position: relative;
  margin-top: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.modal__header {
  position: relative;
}

.modal__close-button {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #006aff;

}

.close-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.modal__title {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.3rem;
  font-weight: 500;
  color: #000;
  margin: 0;
  white-space: nowrap; 
}

.currency-input__wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
}

.currency-input__wrapper label {
  padding: 0 0.5rem;
}

.currency-input__wrapper input {
  flex-grow: 1;
  height: 4rem;
  text-align: right;
  border: none;
  background: none;
  font-weight: inherit;
}

.currency-input__wrapper input:focus {
  outline: none;
}