.layout {
    display: grid;
    min-height: 100vh;

    @media (min-width: 1024px) {
        height: 100vh;
        grid-template-columns: minmax(30rem, 33.33%) 1fr;
    }
}

.aside {
    display: none;
    color: white;
    background-color: var(--primary-color-2);
    padding: 6rem 3rem;
    flex-shrink: 0;

    @media (min-width: 1024px) {
        display: block;
    }

    .slogan {
        font-family: 'Neue Power';
        margin-top: 2.5rem;
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 600;
    }
}

.body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.header {
    padding: 1.25rem 2.5rem;
    margin-bottom: 2.5rem;
    color: white;
    background-color: var(--primary-color-2);

    @media (min-width: 640px) {
        padding-left: 5.25rem;
        padding-right: 5.25rem;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}

.content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;

    @media (min-width: 640px) {
        margin-left: 4rem;
    }

    @media (min-width: 1280px) {
        margin-left: 10rem;
    }

    .wrapper1 {
        max-width: 28rem;
    }

    .wrapper2 {
        padding: .5rem 1.25rem 2rem 1.25rem;

        @media (min-width: 640px) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }
}

.footer {
    padding: 1.25rem;
    max-width: 42rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-top: 1px solid #e5e7eb;
    color: var(--text-soft-color);
    margin-top: auto;
    
    @media (min-width: 640px) {
        padding: 2.5rem 0;
    }
    
    .footerText {
        flex-grow: 1;
    }

    .footerRight {
        margin-top: 1rem;
    }
}