.welcome-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;

}
.welcome-title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: 10px;
    margin-top: 15px;
}
.welcome-title{
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    width: 300px;
    color: #14213D;
    margin-bottom: 40px;
    margin-top: 30px;
}
.welcome-card{
    border: solid 1px red;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.welcome-card2{
    background: rgba(217, 217, 217, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 10px;
}
.welcome-card-text1{
    text-align: center;
    max-width: 405px;
    margin-bottom:35px ;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 300;

}

.welcome-card-bottom-text{
    font-weight: 300;
    margin-top: 32px;
    margin-bottom: 8px;
}

.welcome-card-text2-container{
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 25px;
}
.welcome-card-text2{
    text-align: start;
    min-width: 300px;
    max-width: 400px;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 300;
}
.welcome-card-button{
    padding: 14px;
    border-radius: 9px;
    border: solid 0 ;
    width: 300px;
    background: #42CC7D;
    cursor: pointer;
}
.welcome-card-button.disabled {
    background-color: grey; /* Inactive color */
    cursor: not-allowed;
}
.welcome-card-button-text{
    font-size: 20px;
    font-weight: 500;
    color: white;
}
.welcome-title2{
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    width: 300px;
    color: #14213D;
    margin-top: 35px;
    margin-bottom: 20px;
}
