.balance-card-container {
  margin: 2rem auto;
  width: 24rem;
  position: relative;

  @media (min-width: 1280px) {
    width: 27.5rem;
  }
}

.swipeable-card {
  border-radius: 0.75rem;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 10px 40px -10px rgba(113, 135, 156, 0.2);
  position: relative;
}

.nav-button-container {
  @media (max-width: 1023px) {
    display: none;
  }
}

.nav-button {
  display: flex;
  position: absolute;
  color: #D2D9DB;
  top: 50%;
  transform: translateY(-50%);
  transition: all 150ms ease-in-out;
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
  z-index: 10;

  &:hover {
    color: #71879C;
    transform: translateY(-50%) scale(1.1);
  }

  &:active {
    color: #0898A0;
  }

  &.prev {
    left: 0.5rem;
    &:hover {
      transform: translateY(-50%) scale(1.1) translateX(-0.25rem);
    }
  }

  &.next {
    right: 0rem;
    &:hover {
      transform: translateY(-50%) scale(1.1) translateX(0.25rem);
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }

  &.hidden {
    display: none;
  }
}

.card-content {
  padding: 1.95rem 1.95rem 2.5rem;
  /* background-color: white; */
  border-radius: 0.75rem;
}

.balance-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.balance-label {
  color: #71879C;
  font-weight: 400;
  font-size: 16px;
  font-family: 'ui-monospace', sans-serif;
}

.hide-balance-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #71879C;
  
  &:hover {
    opacity: 0.8;
  }
}

.visibility-icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.eye-slash {
  stroke: currentColor;
  stroke-width: 1.6;
  stroke-linecap: round;
  transition: opacity 0.3s ease;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.balance-amount {
  margin-top: 0.75rem;
  font-family: 'Tomato', sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.balance-divider {
  margin: 0.75rem auto;
  max-width: 12.25rem;
  border: none;
  border-top: 1px solid #E0E0E0;
}

.total-gains {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #71879C;
  font-size: 16px;
  font-weight: 500;
  font-family: 'ui-monospace', sans-serif;
}

.gains-label {
  color: #71879C;
}

.gains-amount {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #27AE60;
}

.gains-icon {
  font-size: 0.875rem;
  transform: rotate(45deg);
}

.wallet-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #71879C;
  text-decoration: none;
  font-family: 'ui-monospace', sans-serif;

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
}

.balance-tabs {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.balance-tab-dot {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: #D2D9DB;
  cursor: pointer;

  &.active {
    background-color: #71879C;
    width: 0.7rem;
    height: 0.3rem;
    border-radius: 25%;
  }
}