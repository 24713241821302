.details-container{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.details-titles-alignment{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-weight: 510;
    font-size: 30px;
}

.details-titles-alignment2{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-weight: 510;
    font-size: 30px;
    margin-top: 15px;
}

.ant-table-cell {
    border-radius: 3px;
}

.green-dot-badge .ant-badge-dot {
  background-color: green;
}

.details-titles-span{
    margin-bottom: 13px;
    margin-top: 20px;
}
.segmented{
    margin-right: 25px;
    margin-left: 15px;
}
.treemap-tab{
     display: 'flex';
     flex-direction: column;
     margin-top: 5px;
}
.treemap{
    width: 100%; 
    height: 700px;
}