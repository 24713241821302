.invest__link {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1rem !important;
    font-weight: 500;
    background-color: var(--primary-color-2);
    color: white !important;
    border-radius: 9999px;
    gap: 0.62rem;
    flex-shrink: 0;

    @media (max-width: 640px) {
        font-size: 0.875rem;
    }

    @media (min-width: 1024px) {
        padding: 1rem 1.5rem !important;
    }

    &:hover svg {
        transform: translateX(50%);
    }

    svg {
        transform: translateX(0);
        transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1) !important;
    }
}

.da-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem !important;
    font-family: "Neue Power";
    border-radius: 9999px;
    background-color: #f7f8fa !important;
    color: var(--text-dark-color);
    gap: 0.5rem;
    flex-shrink: 0;

    @media (max-width: 640px) {
        font-size: 0.875rem;
    }

    @media (min-width: 1024px) {
        padding: 1rem 1.5rem !important;
    }

    &__icon {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.75rem;
    }

    &__icon-sep {
        display: inline-block;
        border-left: solid 1px var(--text-soft-color);
        width: 0;
        height: 0.75rem;
    }

    &__text {
        font-weight: 500;
    }
}

.cta-button {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    padding: 1rem 1.5rem !important;
    border-radius: 9999px;
    font-weight: 500;
    background-color: rgb(113 135 156 / 0.05);
    width: fit-content;
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: primary;
  padding: 0.3rem;
  border-radius: 50%;
  background-color: rgb(238, 238, 238) !important;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
}

.close-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(227, 228, 227);
  border: none;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background: darken(rgb(227, 228, 227), 5%);
  }

  &:focus {
    outline: 2px solid var(--action-primary);
    outline-offset: 2px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--action-primary);
    display: block;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


.save-button {
  width: 100%;
  padding: 16px;
  background:  var(--action-primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background: var(--action-primary-hover);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
  }
}

.cancel-button {
    width: 100%;
    padding: 0.875rem 1.75rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 0.9375rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;
    background-color: #f5f5f5;
    color: #333;
    
    &:hover {
        background-color: #e0e0e0;
        transform: translateY(-1px);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    &:focus {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

}