.bank-selector {
  height: 100vh;
  padding: 18px 20px;
  transition: all 0.3s;

  @media (min-width: 1024px) {
    padding: 28px 28px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }

  &__header {
    position: relative;
    margin-bottom: 2rem;
  }

  &__back-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: inherit;

    &:hover {
      opacity: 0.8;
    }
  }

  &__back-icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }

  &__title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }

  &__search {
    margin-top: 2rem;
    width: 100%;
  }

  &__search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 8px 12px;
  }

  &__search-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: currentColor;
  }

  &__search-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  &__list {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e2e8f0;
  }

  &__list-item {
    height: 3rem;
    text-align: left;
    padding: 0 12px;
    background: none;
    border: none;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f7fafc;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}