@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('./fonts.css');


:root {
  --text-color: #252430;
  --text-color-light: #3e3d50;
  --text-dark-color: rgb(28, 35, 43);
  --text-soft-color: rgb(113, 135, 156);

  --primary-color: #046739;
  --primary-color-2: #059b54;
  --primary-color-3: #30EC95;
  --primary-color-3: #E1FBEE;

  --teal-50: #f0fdfd;
  --teal-100: #ccf7f8;
  --teal-200: #99ecef;
  --teal-300: #66dfe3;
  --teal-400: #33c5cb;
  --teal-500: #088A91; 
  --teal-600: #077a80;
  --teal-700: #056266;
  --teal-800: #034a4d;
  --teal-900: #023235;

  /* Primary Colors */
  --primary-50: #f0f9ff;
  --primary-100: #e0f2fe;
  --primary-200: #bae6fd;
  --primary-300: #7dd3fc;
  --primary-400: #38bdf8;
  --primary-500: #0ea5e9;
  --primary-600: #0284c7;
  --primary-700: #0369a1;
  --primary-800: #075985;
  --primary-900: #0c4a6e;

  /* Neutral Colors */
  --neutral-50: #f8fafc;
  --neutral-100: #f1f5f9;
  --neutral-200: #e2e8f0;
  --neutral-300: #cbd5e1;
  --neutral-400: #94a3b8;
  --neutral-500: #64748b;
  --neutral-600: #475569;
  --neutral-700: #334155;
  --neutral-800: #1e293b;
  --neutral-900: #0f172a;

  /* Success Colors */
  --success-50: #f0fdf4;
  --success-500: #22c55e;
  --success-900: #14532d;

  /* Error Colors */
  --error-50: #fef2f2;
  --error-500: #ef4444;
  --error-900: #7f1d1d;

  /* Semantic Colors */
  --text-primary: var(--neutral-900);
  --text-secondary: var(--neutral-600);
  --text-disabled: var(--neutral-400);
  
  --background-primary: var(--neutral-50);
  --background-secondary: var(--neutral-100);
  
  --border-color: var(--neutral-200);
  
  /* Updated to use teal as primary action color */
  --action-primary: var(--teal-500);
  --action-primary-hover: var(--teal-600);
  --action-primary-disabled: var(--teal-200);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: SFPro, Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input {
  border: 0 solid;
}

ul {
  list-style-type: none;
}


/* Utils */
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 68rem;

  @media screen and (min-width: 640px) {
    max-width: 640px !important;
  }

  @media screen and (min-width: 768px) {
    max-width: 768px !important;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px !important;
  }
}

.container.wide {
  max-width: 90rem !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.transition {
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1) !important;
}