.button {
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.full-width {
    width: 100%;
  }

  &.default {
    background-color: #f5f5f5;
    color: #333;

    &:hover {
      background-color: #e0e0e0;
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }
  }

  &.primary {
    background-color: var(--action-primary);
    color: white;

    &:hover:not(:disabled) {
      background-color: var(--action-primary-hover);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &.cancel {
    background-color: #f5f5f5;
    color: #333;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}
