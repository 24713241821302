.income-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;

  &__content {
    height: 100%;
    padding: 28px 20px;
    
    @media (min-width: 1024px) {
      padding: 28px 28px;
    }
  }

  &__header {
    position: relative;
    margin-bottom: 40px;

    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }
  }

  &__progress {
    margin-top: 40px;
  }

  &__progress-text {
    color: #666;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__progress-bar {
    height: 10px;
    background-color: #e6e6e6;
    border-radius: 20px;
    overflow: hidden;
  }

  &__progress-fill {
    height: 100%;
    background-color: var(--action-primary);
    border-radius: 20px;
    transition: width 0.3s ease;
  }

  &__question {
    margin-top: 32px;
    font-size: 18px;
    color: #333;
  }

  &__input-wrapper {
    margin-top: 16px;
    width: 100%;
  }

  &__input-container {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.2s ease;

    &:focus-within {
      border-color: var(--action-primary);
    }
  }

  &__currency {
    padding: 0 8px 0 12px;
    color: var(--action-primary);
    font-weight: 500;
  }

  &__input {
    width: 100%;
    padding: 16px 14px;
    border: none;
    outline: none;
    font-size: 16px;
    background: transparent;
    caret-color:  var(--action-primary);

    &::placeholder {
      color: #999;
    }
  }

  &__helper-text {
    margin-top: 4px;
    font-size: 12px;
    color: #666;
  }

  &__submit-button {
    width: 100%;
    margin-top: 40px;
    padding: 16px;
    border: none;
    border-radius: 4px;
    background-color: var(--action-primary);
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover:not(:disabled) {
      opacity: 0.9;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}