.confirm-amount {
  @media (min-width: 1024px) {
    padding: 28px 28px 0;
  }

  &__header {
    position: relative;
    margin-bottom: 32px;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  &__content {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.total-amount {
  font-family: 'Tomato Grotesk', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;

  .currency {
    font-size: 1rem;
    font-weight: 600;
  }
}

.amount-details {
  margin-top: 56px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    p {
      &:first-child {
        color: #71879C;
      }

      &:last-child {
        font-family: 'Tomato Grotesk', sans-serif;
      }
    }
  }
}

.confirm-button {
  width: 100%;
  margin-top: 40px;
  padding: 16px;
  background-color: var(--action-primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--action-primary-hover);
  }

  span {
    font-weight: bold;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}