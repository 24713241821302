.success-view {
  height: 100vh;
  padding: 20px;
  
  @media (min-width: 1024px) {
    padding: 28px;
  }

  .success-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
  }

  .circular-container {
    height: 5.625rem;
    width: 5.625rem;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--action-primary);
      stroke-width: 3px;
    }
  }

  h1 {
    margin-top: 32px;
    text-align: center;
    font-size: 20px;
    max-width: 300px;
    line-height: 1.4;
  }

  p {
    margin-top: 12px;
    color: #666;
  }

  .view-plan-button {
    margin-top: 48px;
    width: 100%;
    padding: 16px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--action-primary);
    }

    &:active {
      transform: scale(0.98);
    }
  }
}