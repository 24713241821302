.preferences-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;

  &__content {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 1024px) {
      padding: 28px;
    }
  }

  &__header {
    position: relative;
    margin-bottom: 40px;

    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      max-width: 70%;
      margin: 0 auto;
    }
  }

  &__progress {
    margin-top: 40px;
  }

  &__progress-text {
    color: #666;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__progress-bar {
    height: 10px;
    background-color: #e6e6e6;
    border-radius: 20px;
    overflow: hidden;
  }

  &__progress-fill {
    height: 100%;
    background-color: var(--action-primary);
    border-radius: 20px;
    transition: width 0.3s ease;
  }

  &__question {
    margin-top: 32px;
    font-size: 18px;
    color: #333;
  }

  &__options {
    margin: 16px 0 24px;
    border: none;
    padding: 0;
  }

  &__radio-group {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #666;
  }

  &__radio-button {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__radio-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .preferences-form__radio-custom {
        .preferences-form__radio-unchecked {
          opacity: 0;
        }
        .preferences-form__radio-checked {
          opacity: 1;
        }
      }
    }

    &:focus + .preferences-form__radio-custom {
      outline: 2px solid var(--action-primary);
      border-radius: 50%;
    }
  }

  &__radio-custom {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      fill: var(--action-primary);
      transition: opacity 0.2s ease;
    }
  }

  &__radio-unchecked {
    opacity: 1;
  }

  &__radio-checked {
    opacity: 0;
  }

  &__radio-text {
    font-size: 16px;
    user-select: none;
  }

  &__info-box {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(243, 244, 246, 0.5);
    border-radius: 6px;
    padding: 16px;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--action-primary);
    }

    span {
      color: #666;
      font-size: 15px;
    }
  }

  &__submit-button {
    width: 100%;
    margin-top: 40px;
    padding: 16px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s ease;
    margin-top: auto;

    &:hover:not(:disabled) {
      opacity: 0.9;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}