.grid {
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr; */
  
  .container {
    /* grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100vh;
    transition: duration-300; */
    
    .content {
      /* padding: 28px 20px; */
      
      @media (min-width: 1024px) {
        padding: 28px 28px;
      }
      
      .inner-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding-bottom: 112px;
        
        .header {
          position: relative;
          h2 {
            text-align: center;
            font-weight: 500;
            font-size: 24px;
          }
          
          .back-button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
            padding: 8px;
            &:hover {
              background: rgba(0, 0, 0, 0.04);
              border-radius: 50%;
            }
          }
        }

        .form-section {
          margin-top: 32px;
          .helper-text {
            color: #666;
            line-height: 1.5;
          }
          
          .input-wrapper {
            margin-top: 24px;
            
            .input-container {
              position: relative;
              border-radius: 4px;
              padding: 16px;
              display: flex;
              align-items: center;
              
              &:focus-within {
                border-color: var(--action-primary);
              }
              
              .at-symbol {
                color: #666;
                margin-right: 8px;
              }
              
              .username-input {
                width: 100%;
                border: none;
                outline: none;
                font-size: 16px;
                
                &::placeholder {
                  color: #999;
                }
              }
            }
          }
          
          .error-text {
            color: #d32f2f;
            font-size: 14px;
            margin-top: 8px;
            min-height: 20px;
          }
          
          .alert {
            margin-top: 16px;
            padding: 12px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            
            &.error {
              background-color: #fdeded;
              color: #d32f2f;
            }
            
            .alert-icon {
              margin-right: 12px;
            }
          }
          
          .submit-button {
            width: 100%;
            margin-top: 16px;
            padding: 12px;
            background-color: var(--action-primary);
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            
            &:disabled {
              background-color: rgba(0, 0, 0, 0.12);
              color: rgba(0, 0, 0, 0.26);
              cursor: not-allowed;
            }
            
            &:hover:not(:disabled) {
              background-color: var(--action-primary);
            }
          }
        }
      }
    }
  }
}