.plan-review {
  height: 100vh;
  
  .review-container {
    padding: 20px;
    
    @media (min-width: 1024px) {
      padding: 28px;
    }
  }

  .review-header {
    position: relative;
    margin-bottom: 24px;

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      color: #333;
      
      &:hover {
        color: #000;
      }
    }

    h2 {
      text-align: center;
      font-size: 24px;
      max-width: 448px;
      margin: 0 auto;
    }
  }

  .plan-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 32px 0;

    .banner-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 160px;
      background-color: #f5f5f5;
      border-radius: 12px;
      padding: 0 20px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media (min-width: 1024px) {
        width: 80%;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
      }

      .banner-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: white;
        z-index: 1;

        h2 {
          font-size: 24px;
          font-weight: 500;
        }

        h3 {
          font-weight: 300;
        }
      }
    }
  }

  .plan-details {
    .detail-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid #e5e7eb;

      .label {
        color: #666;
      }

      .value {
        font-weight: 400;
        color: #000;

        &.highlight {
          color: var(--action-primary);
        }
      }
    }
  }

  .action-button {
    margin-top: 40px;

    .create-plan {
      width: 100%;
      padding: 16px;
      background-color: var(--action-primary);
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--action-primary);
      }
    }
  }
}