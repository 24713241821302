.account-statement {
  height: 100vh;

  .page-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    margin-left: 1.3rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }
  

  .content {
    padding: 2.5rem 1.25rem;

    form {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;

      .plans-selector {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border: 1px solid #ddd;
        background: none;
        text-align: left;
        cursor: pointer;

        &:hover {
          background: #f9f9f9;
        }
      }

      .selected-plans {
        display: flex;
        gap: 1.25rem;
        margin-top: 1rem;
        flex-wrap: wrap;

        .plan-tag {
          background: #f5f5f5;
          color: var(--action-primary);
          padding: 0.25rem 0.75rem;
          border-radius: 1rem;
          font-size: 0.75rem;
        }
      }

      .form-field {
        margin-top: 2rem;
        position: relative;

        label {
          position: absolute;
          top: -0.75rem;
          left: 0.75rem;
          padding: 0 0.25rem;
          background-color: white;
          color: #666;
          font-size: 0.875rem;
          z-index: 1;
        }

        .date-input {
          position: relative;

          input {
            width: 100%;
            padding: 0.75rem;
            border: 1px solid #ddd;
            border-radius: 4px;

            &:focus {
              outline: none;
              border-color: var(--action-primary);

              & + label {
                color: var(--action-primary);
              }
            }
          }

          .calendar-button {
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            color: var(--action-primary);
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .email-notice {
        margin-top: 2rem;
        color: #666;
      }

      .submit-button {
        width: 100%;
        margin-top: 4rem;
        padding: 1rem;
        background: var(--action-primary);
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          background: var(--action-primary-hover);
        }
      }

      .error-alert {
        margin-top: 1rem;
        padding: 1rem;
        background: #fee;
        color: #d32f2f;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
    }
  }

  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 90%;
    max-width: 400px;
    text-align: center;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      h2 {
        font-size: 1.25rem;
        margin: 0;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        color: #666;

        &:hover {
          color: #000;
        }
      }
    }

    .plan-list {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      text-align: left;

      .plan-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        input[type='checkbox'] {
          cursor: pointer;
        }

        label {
          cursor: pointer;
          font-size: 1rem;
          color: #333;
        }
      }
    }

    .modal-footer {
      margin-top: 2rem;

      .select-button {
        width: 100%;
        padding: 0.75rem;
        background: #008080;
        color: white;
        border: none;
        border-radius: 6px;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          background: #006666;
        }
      }
    }
  }
}

}
