.projection-view {
  padding: 28px 20px;
  max-width: 800px;
  margin: 0 auto;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  margin-left: 10px;

  --primary-color: var(--action-primary);
  --secondary-color: #838F91;
  --text-soft: rgba(0, 0, 0, 0.6);
  --border-light: rgba(255, 255, 255, 0.2);
  --background-light: rgba(128, 128, 128, 0.1);

  .header {
    position: relative;
    margin-bottom: 24px;

    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin: 0;
    }

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      color: var(--text-soft);
      transition: color 0.2s;

      svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
      }

      &:hover {
        color: #333;
      }
    }
  }

  .goal-summary {
    text-align: center;
    margin-bottom: 20px;

    .label {
      font-size: 13px;
      color: var(--text-soft);
      margin-bottom: 4px;
    }

    .amount {
      font-size: 28px;
      font-weight: bold;
      margin: 4px 0;
      line-height: 1;
    }

    .date {
      font-size: 14px;
      color: var(--text-soft);
    }
  }

  .legend {
    display: flex;
    justify-content: center;
    gap: 28px;
    margin: 20px 0;
    font-size: 14px;
    color: var(--text-soft);

    .legend-item {
      display: flex;
      align-items: center;
      gap: 6px;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.investment {
          background-color: #808080;
        }

        &.returns {
          background-color: var(--primary-color);
        }
      }

      .separator {
        width: 3px;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 50%;
      }
    }
  }

  .chart-container {
    height: 347px;
    margin: 0 -16px;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
    }

    .investment-area,
    .returns-area {
      transition: opacity 0.2s;
      
      &:hover {
        opacity: 0.8;
      }
    }

    .grid-lines {
      line {
        shape-rendering: crispEdges;
      }
    }
  }

  .chart-tooltip {
    background: var(--primary-color);
    border-radius: 8px;
    padding: 12px;
    color: white;
    font-size: 13px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    
    .tooltip-date {
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .tooltip-value {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 4px;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid white;

        &.investment {
          background-color: rgba(128, 128, 128, 0.5);
        }

        &.returns {
          background-color: var(--primary-color);
        }
      }
    }
  }

  .monthly-investment {
    margin-top: 24px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      margin: 0;

      span:first-child {
        color: var(--text-soft);
      }

      span:last-child {
        font-weight: 500;
      }
    }
  }

  .disclaimer {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    background-color: var(--background-light);
    border-radius: 6px;
    padding: 16px;
    margin-top: 28px;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--primary-color);
      flex-shrink: 0;
      margin-top: 2px;
    }

    span {
      font-size: 14.5px;
      color: var(--text-soft);
      line-height: 1.5;

      a {
        color: var(--primary-color);
        text-decoration: none;
        margin-left: 4px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .settings-note {
    text-align: center;
    font-size: 14px;
    color: var(--text-soft);
    margin-top: 16px;
    margin-bottom: 0;
  }

  .continue-button {
    width: 100%;
    margin-top: 40px;
    padding: 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: darken(var(--action-primary), 5%);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}


@media (max-width: 640px) {
  .projection-view {
    padding: 20px 16px;

    .chart-container {
      height: 300px;
    }

    .legend {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .goal-summary {
      .amount {
        font-size: 24px;
      }
    }
  }
}


@media (prefers-color-scheme: dark) {
  .projection-view {
    --background-light: rgba(255, 255, 255, 0.05);
    
    .monthly-investment {
      border-bottom-color: rgba(255, 255, 255, 0.1);
    }

    .disclaimer {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}


@media print {
  .projection-view {
    .chart-container {
      page-break-inside: avoid;
    }

    .continue-button {
      display: none;
    }
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}