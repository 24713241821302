.fixed-income-plan {
  .plan-header {
    position: relative;
    height: 8rem;
    color: white;
    padding: 1.25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3); 
          z-index: 1;
      }

      & > * {
          position: relative;
          z-index: 2;
      }

   
    .options-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: white;
    }
    .back-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      svg{
        width: 16px;
        height: 16px;
        color: hsl(0, 0%, 100%);
      }
    }

    .back-button {
      left: 1rem;
    }

    .options-button {
      right: 1rem;
    }

    .plan-title {
      z-index: 10;

      h2 {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
  }

  .plan-content {
    padding: 1.75rem 1.25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .plan-balance,
    .plan-gains {
      margin-bottom: 0.625rem;
      font-size: 18px;
      font-weight: 400;


      .text-soft {
        color: #6b7280;
        margin-bottom: 0.5rem;
      }
    }

    .balance-mask {
      font-size: 1rem;
    }

    .gains-value {
      color: #10b981;
    }

    .returns-update {
      margin-top: 2rem;
      background-color: #72889d1a !important;
      color: hsl(210 18% 40%) !important;
      padding: 0.5rem 1.25rem;
      border-radius: 9999px;
      font-size: 0.775rem;
    }

    .button-container {
      display: flex;
      gap: 1rem;
      margin-top: 3rem;
      width: 58%;
      height: 50px;

      button {
        flex: 1;
        padding: 0.5rem 0.5rem;
        border-radius: 0.2rem;
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 0.5rem;
          height: 1rem;
          width: 1rem;
        }
      }

      .fund-button,
      .withdraw-button {
        background-color: hsla(209, 18%, 53%, 10%);
        color: var(--action-primary);
        border: 1px solid transparent;

        &:hover {
          background-color: darken(hsl(184, 47%, 93%), 5%);
        }
      }

      .withdraw-button {
        border-color: hsl(184, 47%, 93%);
      }
    }

    .auto-invest {
      width: 100%;
      margin-top: 3rem;
      border-top: 0.03rem solid #d1d5db;

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
        color: inherit;
        text-decoration: none;

        .auto-invest-status {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .ant-tag {
            margin-right: 0;
            font-size: 0.75rem;
            line-height: 1.5;
            padding: 0.15rem 0.5rem;
            border-radius: 9999px;
            background-color: #ed6c02;
            color: #ffffff;
          }
        }
      }
    }

    .performance-summary {
      width: 100%;
      margin-top: 2.5rem;

      .summary-title {
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #6b7280;
        font-size: 0.9375rem;
        text-align: left;
        margin-bottom: 1rem;
      }

      .summary-content {
        background-color: rgba(243, 244, 246, 0.5);
        border-radius: 0.75rem;
        padding: 0.25rem 1.25rem;

        .summary-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 4rem;
          border-top: 1px solid #e5e7eb;
          font-weight: 400;

          &:first-child {
            border-top: none;
          }

          .text-soft {
            color: #6b7280;
          }

          .text-success {
            color: #10b981;
          }
        }
      }
    }
  }
}

.fund-plan-modal-wrapper {
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 0.02rem 0.02rem;
  }

  .ant-modal-close {
    top: 2rem;
    left: 2rem;
    right: auto;
    width: 42px;
    height: 42px;
    background-color: #f1f3f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon-close {
        color: #00a2a2;
        font-size: 16px;
      }
    }
    &:hover {
      background-color: darken(#f1f3f4, 5%);
    }
  }

  .fund-plan-modal {
    text-align: center;

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      width: 20ch;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      color: #6b7280;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 4rem;
    }

    .modal-buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 0.5rem;

       button {
        height: 3rem;
        border-radius: 8px;
        font-weight: 500;
        transition: background-color 0.3s ease;

        &:first-child {
          background-color: #00a2a2;
          color: #ffffff;
          border: none;

          &:hover {
            background-color: darken(#00a2a2, 5%);
          }
        }

        &:last-child {
          background-color: #f1f3f4;
          color: #00a2a2;
          border: none;

          &:hover {
            background-color: darken(#f1f3f4, 5%);
          }
        }
      }
    }
  }
}