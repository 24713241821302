.container {
  .full-page {
    height: 100vh;

    .content-wrapper {
      padding: 1.25rem;
      display: grid;
      grid-template-rows: auto 1fr;

      .header-title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }

      .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .form-title {
          font-size: 1.25rem;
          font-weight: normal;
          margin-top: 2rem;
        }

        .form-description {
          font-size: 0.875rem;
          color: #555;
          margin-top: 0.5rem;
        }

        .otp-container {
          display: flex;
          justify-content: center;
          gap: 0.5rem;
          margin-top: 2rem;

          .otp-input {
            width: 2.5rem;
            height: 2.5rem;
            text-align: center;
            font-size: 1.25rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            transition: border-color 0.3s;

            &:focus {
              border-color: #00b4b4;
            }
          }
        }

        .resend-timer {
          font-size: 0.875rem;
          color: #999;
          margin-top: 1rem;

          .highlight {
            color: #e53935;
            font-weight: bold;
          }
        }

        .submit-button {
          margin-top: 1.5rem;
          padding: 0.75rem;
          width: 100%;
          max-width: 300px;
          background-color: #00b4b4;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;

          &:hover {
            background-color: #008888;
          }
        }
      }
    }
  }
}
