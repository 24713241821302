.affiliate-referrals {
  margin-top: 1.5rem;
  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 15px;
    

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
    }

  }

  .subtitle {
    text-align: center;
    color: #71879C;
    font-style: italic;

    .highlight {
      color: #0898A0;
    }
  }

  .content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .referral-card {
    background-color: #f0f8ff; 
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 0 auto;

    .total-referrals {
      text-align: center;
      margin-bottom: 24px;

      h2 {
        font-size: 22px;
        font-weight: 300;
        color: #000;
        margin: 0;
      }

      p {
        color: #000000;
        font-size: 12px;
        margin: 4px 0 0;
      }
    }

    .stats {
      .stat-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        font-size: 12px;

        p {
          color: #4682b4;
          margin: 0;
        }

        .value {
          color: #000;
          font-weight: 500;
        }

        .label-with-info {
          display: flex;
          align-items: center;

          .info-button {
            background: none;
            border: none;
            color: #4682b4;
            font-size: 16px;
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .start-earning {
    margin-top: 2rem;
    width: 93%;
   

    hr {
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      width: 100%;
    }

    .hr2 {
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0rem 8.5rem;
      margin-bottom: 1rem;
      width: 50%;
    }

    p {
      text-align: center;
      color: #303a42;
      font-size: 0.675rem;
    }

    .referral-info {
      margin-top: 1rem;

      .referral-code, .referral-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        p {
          text-align: left;
          
        }

        .code, a {
          font-size: 1.125rem;
        }

        .copy-button {
          background-color: rgba(113, 135, 156, 0.05);
          border: 1px solid rgba(113, 135, 156, 0.1);
          border-radius: 1rem;
          padding: 0.4rem 0.6rem;
          font-weight: 400;
          color: var(--action-primary);
        }
      }
    }

    .faq-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: inherit;
      margin-bottom: 1rem;
      p {
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          
        }
      
    }
  }

  .commission-history {
    width: 100%;
  
    margin-top: 2rem;

    h2 {
      font-size: 1.0rem;
      font-weight: 500;
      margin-bottom: 1rem;
      margin-left: 1.2rem;
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #71879C;

      .icon {
        width: 68px;
        height: 68px;
        background-color: rgba(113, 135, 156, 0.1);
        border-radius: 50%;
        margin-bottom: 1rem;
      }
    }
  }
}