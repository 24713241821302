.rise-portfolio {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;

     .back-button {
      background: hsla(209, 18%, 53%, 10%);
      border-radius: 50%;
      cursor: pointer;
      margin-right: 1rem;
      color: var(--action-primary);
      height: 35px;
      width: 35px;
      position: absolute;
      left: 20px;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .content {
    padding: 1.25rem;
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    margin-top: 0.75rem;

    &-item {
      background-color: #f5f5f5;
      padding: 0.75rem;
      border-radius: 0.5rem;

      p {
        font-size: 0.75rem;
        color: #666;
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-top: 0.25rem;

        &.success {
          color: #28a745;
        }
      }
    }
  }

  .historical-performance {
    margin-top: 2rem;

    h3 {
      font-size: 1.125rem;
    }

    .performance-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
      margin-top: 1.75rem;
    }

    .performance-item {
      background-color: #f5f5f5;
      padding: 0.75rem;
      border-radius: 0.5rem;

      p {
        font-size: 0.75rem;
        color: #666;
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-top: 0.25rem;
        color: #28a745;
      }
    }

    .disclaimer {
      font-size: 0.875rem;
      margin-top: 0.75rem;
      color: #666;
    }
  }

  .asset-mix {
    margin-top: 2.5rem;

    h2 {
      font-size: 1.125rem;
    }

    .asset-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 1.5rem;
      border-radius: 50px;
      
    }
  }

  .asset-class {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    .asset-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      margin-right: 0.75rem;
      background-color: hsla(209, 18%, 53%, 10%);
    }

    .percentage-bar {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      .bar {
        width: 10rem;
        height: 0.25rem;
        background-color: #f5f5f5;
        border-radius: 9999px;
        margin-right: 0.5rem;

        .fill {
          height: 100%;
          border-radius: 9999px;
        }
      }

      .percentage {
        font-size: 0.75rem;
      }
    }
  }
}