.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 43px;
}

.register-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: 10px;
    margin-top: 15px;
}

.register-title {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    color: #14213D;
    margin-bottom: 40px;
    margin-top: 30px;
}

.welcome-card {
    border: solid 1px red;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-card2 {
    background: rgba(217, 217, 217, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 10px;
}

.register-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.register-card-title {
    color: #AAAAAA;
    margin-right: 18px;
    margin-left: 18px;
    font-size: 14px;
    font-weight: 400;
}

.register-card-lines {
    flex: 1;
    color: #DDDDDD;
    height: 1px;
    border-width: 0;
    background-color: #DDDDDD;
    margin-inline: 8px;
}

.error {
    margin: 1rem auto;
    color: #ff5050;
    text-align: center;
}

ul.error {
    text-align: left;
}

.form-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.input-display {
    display: flex;
    flex-direction: column;
}

.input-label-display {
    margin-bottom: 7px;
    font-weight: 400;
    font-size: 14px;
    margin-top: 20px;
}

.input-containers {
    min-width: 300px;
    height: 48px;
    border: 1px solid #AAAAAA;
    border-radius: 9px;
    background-color: rgba(217, 217, 217, 0.12);
    outline: none;
    font-size: 14px;
    font-weight: 400;
    padding-inline: 15px;
    margin-bottom: 7px;
    margin-top: 5px;
}

.input-containers:hover {
    border-color: #09797A;
}


.password-containers {
    min-width: 300px;
    height: 48px;
    border: 1px solid #AAAAAA;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(217, 217, 217, 0.12);
    padding-inline: 15px;
    margin-bottom: 7px;
    margin-top: 5px;
}

.password-containers:hover {
    border-color: #09797A;
}

.input-password {
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    background-color: rgba(217, 217, 217, 0.05);
    font-weight: 400;
    font-size: 14px;
}


.submit-button {
    min-width: 325px;
    width: 100%;
    height: 54px;
    background-color: #42CC7D;
    border: 1px solid #42CC7D;
    border-radius: .5rem;
    margin-top: 20px;
    margin-bottom: 1.4rem;
    cursor: pointer;
}

.submit-button--loading {
    background-color: #AAAAAA !important;
    border: 1px solid #AAAAAA !important;
    cursor: wait;
}

.submit-text {
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.telegram-button-container {
    background: #54a9eb;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    padding: .5rem 0;
    border-radius: 0.5rem;
}