.currencies-container{
    display: flex;
    flex-direction: column;
    width: 100%;

}
.currency-title{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: -10px ;
}

.scrollable-container {
    overflow-x: auto;
    white-space: nowrap;
}

.currency-flag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 50%;
}

img.currency-flag {
    --size: 40px;
    height: var(--size);
    width: var(--size);
    object-fit: cover;
}