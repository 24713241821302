.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: auto;
  padding: 0;
}

.close {
  margin-left: 1.75rem;
  margin-bottom: 1rem;
}

.menu-button {
  display: none;
}

.menu-button svg {
  color: var(--text-color);
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }
}