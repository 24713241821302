.reset-password-container {
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-left: 10px;
}


.header {
  position: relative;
  text-align: center;
  margin-bottom: 1.8rem;

  .back-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
   
  }

  h2{
    font-weight: 400;
  }

  .title {
    font-size: 2rem;
    font-weight: 500;
  }
}

.sub-title {
  font-size: 1.5rem;
  font-weight: 400;
}

.desc{
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.otp-container {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;

  .otp-input {
    width: 2.3rem;
    height: 2.5rem;
    text-align: center;
    font-size: 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
}

.resend-container {
  margin-top: 1rem;
  color: var(--action-primary);
  font-size: 16;
  font-weight: 500;

  .timer {
    color: #d9534f;
    font-weight: bold;
  }
}

.alert {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #d9534f;
  background: #f8d7da;
  border-radius: 0.25rem;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .icon {
    margin-right: 0.5rem;
  }

  .message {
    font-size: 0.875rem;
  }
}
