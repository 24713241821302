.dashboard {
  &__content {
    height: 100vh;
    transition: duration 300ms;
  }

  &__container {
    padding: 1.75rem 1.25rem;
    
    @media (min-width: 1024px) {
      padding: 1.75rem;
    }
  }

  &__header {
    position: relative;
    text-align: center;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: primary;
  padding: 0.3rem;
  border-radius: 50%;
  background-color: rgb(238, 238, 238);
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.arrow-icon {
  width: 24px;
  height: 24px;
  
  &.rotate-45 {
    transform: rotate(-45deg);
  }
  
  &.rotate-180 {
    transform: rotate(180deg);
  }
  
  &.rotate-225 {
    transform: rotate(-225deg);
  }
}

.plans-grid {
  margin-top: 2.5rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
}


.loader {
  margin-top: 2.5rem;
  text-align: center;
  grid-column: 1 / -1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}