.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.modal {
  width: 100%;
  max-width: 28rem;
  margin-top: 40px;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  outline: none;

  &__content {
    background-color: white;
    border-radius: 12px;
    padding: 28px 20px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
      width: 15ch;
    }
  }

  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(230, 231, 231);
    border-radius: 50%;
    border: none;
    padding: 6px;
    cursor: pointer;
    color: var(--action-primary);

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }
  }

  &__body {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    p {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: none;
      }

      span {
        &:first-child {
          color: #71879C;
          white-space: nowrap;
        }

        &:last-child {
          text-align: right;
          word-break: break-words;
        }
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 32px;
    padding: 16px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--action-primary-hover);
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}