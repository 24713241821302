.plan-card {
  &.existing-plan {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 200px;
    padding: 16px;
    
    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      transition: box-shadow 0.3s ease-in-out;
    }

    .plan-content {
      text-align: left;
      align-self: flex-start;
      margin-top: auto;
      width: 100%;
    }

    .plan-name {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .plan-balance {
      color: #ffffff;
      margin: 4px 0;
      font-size: 24px;
      font-weight: 600;
    }

    .plan-type {
      color: #ffffff;
      font-size: 14px;
      opacity: 1.5;
    }
  }
}