@font-face {
    font-family: 'Neue Power';
    font-style: normal;
    font-weight: 400;
    src: local('Neue Power Regular'), url('/public/fonts/NeuePower/NeuePower-Regular-qo7lrz.woff') format('woff');
}

@font-face {
    font-family: 'Neue Power';
    font-style: normal;
    font-weight: 300;
    src: local('Neue Power Light'), url('/public/fonts/NeuePower/NeuePower-Light.woff') format('woff');
}

@font-face {
    font-family: 'Neue Power';
    font-style: normal;
    font-weight: 500;
    src: local('Neue Power Medium'), url('/public/fonts/NeuePower/NeuePower-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Neue Power';
    font-style: normal;
    font-weight: 800;
    src: local('Neue Power Ultra'), url('/public/fonts/NeuePower/NeuePower-Ultra.woff') format('woff');
}


@font-face {
    font-family: 'SFPro';
    src: url('/public/fonts/SF-Pro.ttf') format('truetype');
}