.plan-card2 {
  display: block;
  border-radius: 1rem;
  color: white;
  text-decoration: none;
  overflow: hidden;
  /* aspect-ratio: 1; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  
  &:hover {
    opacity: 0.95;
    color: #fff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, 
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,0.3) 100%
    );
    pointer-events: none;
  }

  .card-content2 {
    position: relative;
    padding: 0.75rem;
    height: 25rem;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
  }

  .title {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
  }

   .stats {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    .left-stats {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .right-stats {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0;
      text-align: right;
    }

    .title {
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0;
      line-height: 1;
    }

    .balance {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1;
    }

    .percentage {
      display: inline-flex; 
      align-items: center;
      gap: 0.25rem;
      font-size: 0.875rem;
      line-height: 1;
      margin-bottom: 2px;
    }

    .arrow-icon {
      width: 18px;
      height: 18px;
      transform: rotate(-45deg);
      fill: white;
      
      &.negative {
        transform: rotate(225deg);
      }
    }

    .amount {
      font-size: 0.875rem;
      line-height: 1;
      opacity: 0.9;
    }
  }
}