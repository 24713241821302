.rise-dashboard {
  min-height: 100vh;
  overflow: hidden;
  max-width: 72rem;
  margin: auto;
  max-height: 100vh;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(12rem, 15rem) 1fr 20rem;
  grid-template-areas: "nav content aside";

  > * {
    overflow-y: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }

  @media screen and (max-width: 768px) {
    grid-template-areas: "content";
    grid-template-columns: 1fr;
  }

  .left-sider {
    height: 100vh;
    background-color: #ffffff;
    z-index: 1000;
    overflow-y: auto;
    border-right: 1px solid #e8e8e8;
    padding: 2rem .5rem;
    grid-area: nav;

    @media (min-width: 1024px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .logo-container {
      padding-left: 8px;
      margin-bottom: 24px;

      .rise-logo {
        width: 40%;
        height: auto;
        color: #1d93a8;
      }
    }

    .ant-menu {
      border-right: none;
      font-weight: 400;
      flex-grow: 1;
      overflow-y: auto;

      .menu-label{
        color: hsl(210, 18%, 40%);
      }

      .menu-item {
        position: relative;
        height: 50px;
        line-height: 50px;
        color: hsl(210, 18%, 40%);
        padding-left: 14px;
        display: flex;
        align-items: center;

        .menu-icon {
          width: 24px;
          height: 24px;
          position: relative;
          margin-right: 20px;
          margin-left: 10px;
          

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: 10px;
            transform: translate(-50%, -50%);
            transition: opacity 0.3s ease;
          }

          .default-icon {
            opacity: 1;
          }

          .selected-icon {
            opacity: 0;
          }
        }

        .menu-label {
          flex: 1;
        }

        &.ant-menu-item-selected {
          background-color: #72889d1a;
          font-weight: bold;
          color: #000000;
          border-radius: 5px 0px 0px 5px;

          .menu-label{
            color: hsl(210, 21%, 5%);
          }

          .menu-icon {
            .default-icon {
              opacity: 0;
            }

            .selected-icon {
              opacity: 1;
            }
          }

          .highlight-bar {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 5px;
            background-color: #000000;
          }

          &:hover {
            color: #000000;
            background-color: #72889d1a;
          }
        }

        &:hover {
          color: #757575;
          background-color: transparent;
        }
      }
    }
}

  .ant-layout {
    flex: 1;
    display: flex;

    @media (max-width: 768px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .scrollable-content {
    width: 20%;
    flex: 1;
    overflow-y: auto;
    padding: 24px;

    .balance-card {
      margin-bottom: 24px;

      .gains {
        color: #00b48d;
      }
    }

    .add-money-btn {
      margin-bottom: 24px;
    }

    .plans-section {
      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .plan-card {
        /* height: 150px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.new-plan {
          background-color: #f5f5f5;
          border: 2px dashed #d9d9d9;
        }

        &.existing-plan {
          background-color: #e6e6fa;
        }
      }
    }
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .right-sider {
    grid-area: aside;

    .right-sider-content {
      background: #ffffff;
      border-left: 1px solid #e8e8e8;
      height: 100vh;
      width: 100%;

      @media (min-width: 1280px) {
        width: 20.31rem !important;
      }

      @media (min-width: 1024px) {
        display: block !important;
      }

      @media (min-width: 1024px) {
        position: sticky !important;
      }
    }

    .scrollable-right-content {
      height: 100%;
      overflow-y: auto;
      padding: 24px;
      flex: 1;

      .custom-carousel {
        background: linear-gradient(to right, #ffffff, #ffffff);
        border-radius: 10px;
        padding: 3px;
        position: relative;
        margin-bottom: 10px;

        .ant-carousel {
          .slick-slide {
            text-align: center;
            height: 190px;
            line-height: 5px;
            background: #f0f1f3;
            overflow: hidden;
            border-radius: 10px;
          }

          .slick-dots {
            bottom: -20px;

            li {
              margin: 0 4px;
              width: 10px;
              height: 10px;

              button {
                background: #d9d9d9;
                opacity: 0.4;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                padding: 0;
              }

              &.slick-active {
                width: 12px;
                height: 5px;

                button {
                  opacity: 1;
                  width: 12px;
                  height: 5px;
                  border-radius: 30%;
                  background: #818181;
                }
              }
            }
          }
        }
      }

      .customer-corner {
        .ant-card-body {
          padding: 16px;
        }

        .ant-typography {
          margin-bottom: 1px;
          padding-bottom: 8px;
          font-weight: 400;
        }

        .ant-btn-link {
          height: auto;
          padding: 12px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: inherit;

          &:hover {
            background-color: #f5f5f5;
          }

          .icon-wrapper {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;

            &.teal {
              background-color: #00B48D;
            }

            &.blue {
              background-color: #0898A0;
            }

            .anticon {
              font-size: 19px;
              color: white;
            }
          }

          .content {
            flex: 1;
            text-align: left;

            .ant-typography {
              display: block;
              margin: 0;
              padding: 0;
              border: none;

              &-secondary {
                font-size: 12px;
                color: #71879C;
              }
            }
          }

          .anticon-right {
            font-size: 12px;
            color: #71879C;
          }
        }
      }

      .make-best {
        margin-bottom: 16px;
      }

      .quote-card {
        background-color: #f5f5f5;
        border-radius: 12px;

        .quote-label {
          display: block;
          color: #8c8c8c;
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .quote-text {
          display: block;
          margin-bottom: 16px;
        }

        .quote-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

  }

  @media (max-width: 768px) {
    .left-sider {
      display: none;
    }

    .ant-layout {
      margin-left: 0;
    }

    .right-sider {
      display: none;
    }

    .scrollable-content {
      padding-bottom: 60px;
    }

    .mobile-menu-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      border-top: 1px solid #e8e8e8;
      z-index: 1000;
    }

    .mobile-menu {
      display: flex;
      justify-content: space-around;
      padding: 8px 0;
      margin: 0;

      .ant-menu-item {
        flex: 1;
        text-align: center;
        margin: 0 !important;
        padding: 4px 0 !important;
        border-bottom: none !important;

        &::after {
          display: none !important;
        }

        .menu-item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
        }

        .menu-icon {
          font-size: 24px;
          margin-bottom: 4px;

          svg {
            width: 24px;
            height: 24px;

            path,
            rect,
            line {
              stroke: currentColor;
              stroke-width: 1.1;
              transition: fill 0.3s ease, stroke 0.3s ease;
            }

            .fill-on-select {
              fill: none;
            }

            .no-fill {
              fill: none;
            }
          }
        }

        .menu-text {
          font-size: 16px;
          font-weight: 500;
          color: #727070;
          transition: opacity 0.3s ease;
        }

        .menu-dot {
          width: 8px;
          height: 8px;
          background-color: #000000;
          border-radius: 50%;
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &.ant-menu-item-selected {
          background-color: transparent !important;

          .menu-icon svg {
            .fill-on-select {
              fill: #000000;
            }

            path,
            rect,
            line {
              stroke: #000000;
            }
          }

          .menu-text {
            opacity: 0;
          }

          .menu-dot {
            opacity: 1;
          }
        }
      }
    }

    @media (min-width: 769px) {
      .mobile-menu-wrapper {
        display: none;
      }
    }
  }
}