.otp-inputs {
  display: flex;
  gap: 0.5rem;
}

.otp-input {
  width: 2rem;
  height: 2rem;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: border-color 0.3s;

  &:focus {
    outline: var(--action-primary);
    border-color: var(--action-primary);
     box-shadow: 0 0 0 2px var(--action-primary);
  }
}

