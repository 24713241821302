.alert-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  background-color: #fdecea;
  border: 1px solid #f5c2c7;
  padding: 0.5rem;
  border-radius: 5px;
  color: #d32f2f;

  .alert-icon {
    margin-right: 0.5rem;

    svg {
      width: 20px;
      height: 20px;
      fill: #d32f2f;
    }
  }

  .alert-message {
    font-size: 0.875rem;
  }
}
