.bank-details {
  height: 100%;
  padding: 28px 20px 0;

  @media (min-width: 1024px) {
    padding: 28px 28px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    margin-top: 24px;
    color: var(--action-primary);

    svg {
      width: 35px;
      height: 35px;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 16px;
    max-width: 14ch;
  }

  &__description {
    font-size: 0.875rem;
    color: #71879C;
    margin-top: 24px;
    max-width: 40ch;
  }

  &__account {
  width: 100%;
  margin-top: 40px;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    &:first-child {
      text-align: left;
      p {
        text-align: left;
        &:first-child {
          color: var(--action-primary);
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  .account-number {
    font-size: 1.125rem;
    font-family: 'Tomato Grotesk', sans-serif;
  }
}

  .copy-button {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px;
    color: var(--action-primary);
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__rate {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  div {
    &:first-child {
      text-align: left;
      
      p {
        text-align: left;
        
        &:first-child {
          font-size: 1rem;
          margin-bottom: 4px;
        }
      }
      
      .rate-description {
        font-size: 0.875rem;
        color: #71879C;
        text-align: left;
      }
    }

    &:last-child {
      text-align: right;
      
      .rate-amount {
        font-family: 'Tomato Grotesk', sans-serif;
        font-size: 1rem;
      }
    }
  }
}&__rate {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  div {
    &:first-child {
      text-align: left;
      
      p {
        text-align: left;
        
        &:first-child {
          font-size: 1rem;
          margin-bottom: 4px;
        }
      }
      
      .rate-description {
        font-size: 0.875rem;
        color: #71879C;
        text-align: left;
      }
    }

    &:last-child {
      text-align: right;
      
      .rate-amount {
        font-family: 'Tomato Grotesk', sans-serif;
        font-size: 1rem;
      }
    }
  }
}

  &__footer {
    width: 100%;
    display: grid;
    place-items: center;
    margin: 32px 0;

    .done-button {
      width: 100%;
      max-width: 60%;
      padding: 16px;
      background-color: var(--action-primary);
      color: white;
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--action-primary-hover);
      }

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }
}

.back-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--action-primary);

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}