.subscription-page {
    --dark-color: #002434;
    --gray-color: #858BA0;
    --border-color: #E6E9F5;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin: 1.2rem auto;
    max-width: 90rem;

    button {
        cursor: pointer;
        border: none;
        background-color: transparent;
    }

    .page-title {
        font-size: 2.2rem;
        color: var(--dark-color);
    }

    .ant-radio-button-wrapper {
        padding: 0 2.2rem;
        background-color: #EEF3F5;
        color: var(--gray-color);
        border: none;
    }

    .ant-radio-button-wrapper:hover {
        color: var(--dark-color);
    }

    .ant-radio-button-wrapper-checked {
        background-color: #32936F;
        color: white;
    }

    .ant-radio-button-wrapper-checked::before {
        display: none;
    }

    .sub-table-wrapper {
        border: 1px solid var(--border-color);
        border-radius: .5rem;
        max-width: 75rem;
        width: 100%;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-style: hidden;
        border-spacing: 0;
    }

    th,
    td {
        /* Every cell padding */
        padding: 1.5rem;
        border: 1px solid var(--border-color);
    }

    th:first-of-type {
        width: 16rem;
    }

    td+td {
        text-align: center;
        height: 100%;
        font-size: 14px;
    }

    .thead-left {
        text-align: left;
    }

    .thead-left__compare {
        display: flex;
        align-items: center;
        gap: .5rem;
        margin-bottom: .5rem;
    }

    .thead-left__compare span:first-of-type {
        text-wrap: nowrap;
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--dark-color);
    }

    .thead-left__badge {
        font-size: .85rem;
        background-color: #32936F;
        color: white;
        padding: .5rem 1rem;
        border-radius: 1rem;
        font-weight: bold;
        transform: translateY(2px);
        text-wrap: nowrap;
    }

    .thead-left__choose {
        font-size: .85rem;
        color: var(--gray-color);
        font-weight: normal;
    }

    .th-item {
        font-size: 1.5rem;
        font-weight: bold;
        padding-inline: 2rem;
        color: var(--dark-color);
    }

    .th-item button {
        font-size: 14px;
        background-color: var(--text-color);
        color: white;
        border-radius: 5px;
        padding: 1rem .5rem;
        margin-top: 1.5rem;
        width: 100%;
        font-weight: bold;
    }

    .th-item button:hover {
        background-color: var(--text-color-light);
    }

    .cell-detail {
        color: var(--gray-color);
        margin-top: .5rem;
    }

    /* tbody tr:last-child td, tfoot {
        border-bottom-color: var(--gray-color);
    } */

    tfoot td {
        border-color: var(--gray-color);
        border-inline: none;
        padding-block: 2rem;
    }

    .tfoot-first div {
        font-weight: bold;
        color: var(--dark-color);
    }

    .tfoot-option button {
        background-color: #32936F;
        color: white;
        font-weight: bold;
        margin-top: .5rem;
        padding: .75rem 1.25rem;
        border-radius: .5rem;
    }

    .tfoot-option button:hover {
        background-color: #266d53;
    }

    .thead-left-container {
        display: none;
    }

    .option-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding: 2rem;
        color: var(--dark-color);
        border: 1px solid var(--border-color);
        border-radius: .5rem;
        opacity: .8;
    }

    .option-card h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .option-card__middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
    }

    .option-card__price {
        color: var(--dark-color);
    }

    .option-card__subtitle {
        color: var(--gray-color);
        opacity: .6;
        text-align: center;
    }

    .option-card__custom-btn {
        font-size: 14px;
        text-decoration: underline;
    }

    .option-card__select-btn {
        font-size: 14px;
        background-color: var(--text-color);
        color: white;
        border-radius: 5px;
        padding: 1rem .5rem;
        width: 100%;
        font-weight: bold;
        transition: all .3s;
    }

    .option-card__select-btn:hover {
        background-color: var(--text-color-light);
    }

    .swiper {
        max-width: 100%;
    }

    .swiper-slice {
        max-width: 4rem;
        padding-block: .5rem;
    }

    .swiper-slide-active .option-card {
        opacity: 1;
        border: 2px solid #32936F;
    }


    /* MEDIA QUERIES */

    @media screen and (max-width: 768px) {
        .ant-radio-button-wrapper {
            padding: 0 1rem;
        }
    }

    @media screen and (max-width: 1120px) {
        .page-title {
            text-align: center;
            font-size: 1.5rem;
        }

        .thead-left-container {
            display: flex;
            justify-content: center;
            width: 100%;
            border: 1px solid var(--border-color);
            border-radius: 0.5rem;
            padding: 2rem;
        }

        .swiper {
            display: block;
        }

        .sub-table-wrapper {
            margin-top: 1rem;
        }

        table {
            table-layout: auto;
        }

        thead,
        .th-item,
        .content-cell,
        .tfoot-option {
            display: none;
        }

        tr {
            display: flex;
            align-items: center;
            border: 1px solid var(--border-color);
            min-height: 3.5rem;
        }

        th,
        td {
            /* Every cell padding */
            padding: .5rem 1rem;
            border: none;
            height: 100%;
        }

        td:first-child {
            flex: 5;
        }

        td.content-cell {
            flex: 1;
            min-width: 7.5rem;
        }

        tfoot {
            tr {
                flex-direction: column;
                gap: 1rem;
                padding: 2rem;
            }

            td {
                text-align: center;
                padding: 0;
            }
        }

        .th-item--active,
        .content-cell--active,
        .tfoot-option--active {
            display: block;
        }
    }
}