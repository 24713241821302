.portfolio-section-container {
    display: flex;
    flex-direction: column;
    --red: rgb(230, 82, 31);
    --green: #42CC7D;
    --content-radius: .5rem;
}

.portfolio-section-row {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 2rem;
}

.portfolio-section-row > * {
    flex: 1;
}

.portfolio-section-container .ant-statistic-content-value {
    text-wrap: nowrap;
}

.portfolio-section-container .ant-statistic-content {
    font-weight: 700;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .25rem;
}

.portfolio-section-container .ant-statistic-title {
    text-wrap: nowrap;
}

.title-line {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.title-text {
    font-size: 30px;
    font-weight: 500;
    padding-right: 1rem;
}


.title-line-text {
    color: #42CC7D;
}

.card {
    max-width: 400px;
    min-height: 120px;
    height: 100%;
    border: 1px solid #D7DBE7;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card .ant-card-body {
    position: relative;
    padding: 0;
    margin: 1.5rem;
}

.card .ant-statistic-content-suffix {
    display: flex !important;
}

.card.card-h-no-suffix .ant-statistic-content-value {
    background-color: gray;
    border-radius: var(--content-radius);
    padding-inline: .5rem;
    color: white;
}

.card.card-h-portfolio .statistic-suffix {
    color: white;
    background-color: var(--red);
    border-radius: var(--content-radius);
    padding-inline: .5rem;
    font-weight: bold;
    font-size: .85rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.card.card.card-h-portfolio .ant-statistic-content {
    background-color: var(--green);
    border-radius: var(--content-radius);
}

.card.card-h-portfolio .ant-statistic-content-value {
    padding-inline: .5rem;
    color: white;
}

.card.card-h-with-suffix .ant-statistic-content {
    display: flex;
    background-color: var(--green);
    border-radius: var(--content-radius);
    padding-inline: .5rem;
}

.card.card-h-with-suffix .ant-statistic-content-value,
.card.card-h-with-suffix .statistic-suffix {
    color: white;
}

.card.card-h-with-suffix .statistic-suffix {
    opacity: .8;
}


.card-h-no-suffix.card-h-green .ant-statistic-content-value,
.card-h-portfolio.card-h-green .ant-statistic-content,
.card-h-suffix-green .statistic-suffix,
.card-h-with-suffix.card-h-green .ant-statistic-content {
    background-color: var(--green) !important;
}

.card-h-no-suffix.card-h-red .ant-statistic-content-value,
.card-h-portfolio.card-h-red .ant-statistic-content,
.card-h-portfolio.card-h-suffix-red .statistic-suffix,
.card-h-with-suffix.card-h-red .ant-statistic-content {
    background-color: var(--red) !important;
}

.statistic-title-text {
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.statistic-title-text-group {
    text-wrap: nowrap;
}

.statistic-title-day {
    margin-left: 4px;
    color: #42CC7D;
}

.statistic-suffix {
    font-size: .75rem;
    font-weight: 400;
    color: #A9A9A9;
    text-wrap: nowrap;
}

.statistic-title-text2 {
    margin-left: 4px;
    color: #A9A9A9;
    margin-top: 3px
}

.statistic-suffix-element {
    font-size: 11px;
    font-weight: 600;
    color: #E6521F;
    margin-top: 10px
}

.statistic-currencies {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-around;
    align-items: center;
    padding-inline: 28px;

    position: absolute;
    width: 100%;
}

.usd-currency {
    color: #75767A
}

.eur-currency {
    color: #75767A
}

@media screen and (max-width: 1550px) {
    .portfolio-section-row {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1000px) {
    .portfolio-section-row {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
    }
}

@media screen and (max-width: 678px) {
    .portfolio-section-row {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}