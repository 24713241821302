.auto-invest-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.auto-invest-wrapper {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100vh;
  transition: duration-300;
  padding: 1.75rem 1.25rem;

  @media (min-width: 1024px) {
    padding: 1.75rem;
  }
}

.auto-invest-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100%;
}

.auto-invest-content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100vh;
  transition: duration-300;
}

.auto-invest-header {
  position: relative;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  
  svg {
    width: 24px;
    height: 24px;
  }
}

.auto-invest-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
}

.amount-button {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  height: 10rem;
  padding: 2.5rem 0;

  .amount {
    font-size: 1.875rem;
  }

  .amount-hint {
    color: #666;
  }
}

.amount-description {
  color: #666;
  margin-top: 1rem;
}

.settings-container {
  margin-top: 2.5rem;
  border-top: 0.03rem solid #e5e5e5;
  border-bottom: 0.03rem solid #e5e5e5;
  width: 100%;
}

.setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 0.03rem solid #f5f5f5;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: var(--action-primary);
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
}

.frequency-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  padding: 0 0rem;
  color: #000000;

  &:disabled {
    cursor: not-allowed;
  }

  .frequency-content {
    text-align: left;

    p:first-child {
      margin-bottom: 0.25rem;
    }

    .frequency-value {
      color: #000000;
      text-transform: capitalize;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    color: #666;
  }
}

.submit-button {
  margin-top: 2.5rem;
  width: 100%;
  padding: 1rem;
  background-color: var(--action-primary);
  color: white;
  border-radius: 0.25rem;
  font-weight: 500;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}