.currency-selector {
  padding: 28px 20px;
  
  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    color: #000;
    
    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    color: #666;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    border-top: 1px solid #e5e5e5;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 64px;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 12px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f5f5f5;
    }

    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  &__currency-name {
    margin-left: 12px;
  }

  &__currency-symbol {
    margin-left: 4px;
  }
}


@media (min-width: 1024px) {
  .currency-selector {
    padding: 28px 28px;
  }
}