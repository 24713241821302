.content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  padding: 1.75rem 1.25rem;

  @media (min-width: 1024px) {
    padding: 1.75rem;
  }
}

.content-inner {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  transition-duration: 300ms;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.authorization-content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  transition-duration: 300ms;
}

.header {
  position: relative;
  margin-bottom: 2.5rem;

  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color, #000);
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.description {
  margin-top: 2.5rem;
  text-align: center;
  color: var(--soft-color, #71879C);
}

.button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:focus {
    outline: none;
    ring: 2px;
    ring-color: var(--primary-color, #000);
  }
}

.charge-button {
  width: 100%;
  margin-top: 2rem;
  background-color: var(--action-primary);
  color: white;
  
  &:hover {
    background-color: var(--action-primary-hover);
  }
  
  &:active {
    background-color: var(--action-primary-hover);
  }
}
