.remade-real-estate-plan {
  .header {
    position: relative;
    height: 128px;
    color: white;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    h2, p, .back-button, .options-button {
      position: relative;
      z-index: 2;
    }

    h2 {
      font-weight: 600;
      font-size: 28px;
    }

    p {
      z-index: 10;
      font-weight: 500;
      font-size: 16px;

    }

    .back-button,
    .options-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: white;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        fill: white;
      }
    }

    .back-button {
      left: 16px;
      
    }

    .options-button {
      right: 16px;
    }
  }

  .content {
    padding: 28px 20px;
    text-align: center;

    .plan-balance {
      margin-bottom: 10px;

      h2 {
        font-size: 30px;
        margin: 5px 0;
      }
      p{
        font-size: 15px;
        font-weight: 500;
      }

      .masked-balance {
        font-size: 16px;
        color: #666;
      }
    }

    .gains {
      margin-bottom: 20px;

      .success {
        color: #4caf50;
      }
      p{
        font-size: 15px;
        font-weight: 500;
      }
    }

     .goal-progress {
    margin-bottom: 24px;

    .progress-labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: 14px;
      color: #666;
    }

    .progress-bar {
      height: 4px;
      background-color: #e0e0e0;
      border-radius: 2px;
      overflow: hidden;

      .progress {
        height: 100%;
        background-color: #20B2AA; 
        width: 100%; 
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 16px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
      cursor: pointer;
      background-color: #f5f5f5;
      color: #20B2AA; 
      width: 35%; 
      height: 60px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }

    .fund-plan {
      svg {
        fill: #20B2AA;
      }
    }

    .withdraw {
      svg {
        fill: #20B2AA; 
      }
    }
  }

   .auto-invest,
  .reinvest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid #e0e0e0;

    span {
      color: #4a4a4a;
      font-size: 16px;
    }

    .right-content {
      display: flex;
      align-items: center;
    }

    .chip {
      background-color: #FF6B00;
      color: white;
      padding: 6px 12px;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 8px;
    }

    .chevron-icon {
      width: 24px;
      height: 24px;
      fill: #4a4a4a; 
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 18px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #20B2AA;
        }

        &:checked + .slider:before {
          transform: translateX(22px);
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 30px;
          width: 30px;
          top:0px;
          left: 0px;
          bottom: 0px;
          background-color: white;
          transition: .4s;
          border-radius: 50%;
        }
      }
    }
  } 

    .performance {
      background-color: #2196f3;
      color: white;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 24px;

      .performance-header {
        text-align: center;
        margin-bottom: 16px;

        .amount {
          font-size: 24px;
          font-weight: 600;
          margin: 8px 0;
        }

        .date {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;

          svg {
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
        }
      }

      .performance-details {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
    }

    .performance-summary {
      margin-bottom: 24px;

      h3 {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 15px;
        color: #666;
        text-align: left;
        margin-bottom: 16px;
      }

      .summary-item {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        border-top: 1px solid #e0e0e0;

        &:first-child {
          border-top: none;
        }

        .success {
          color: #4caf50;
        }
      }
    }

    .recent-transactions {
      .transactions-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        h2 {
          font-size: 18px;
        }

        a {
          display: flex;
          align-items: center;
          color: #2196f3;
          text-decoration: none;
          font-weight: 600;

          svg {
            margin-left: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .transaction-item {
        display: flex;
        align-items: center;
        padding: 16px 0;

        .transaction-icon {
          width: 36px;
          height: 36px;
          background-color: rgba(33, 150, 243, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;

          svg {
            width: 20px;
            height: 20px;
            color: #2196f3;
          }
        }

        .transaction-details {
          flex-grow: 1;

          p:first-child {
            font-weight: 600;
          }

          p:last-child {
            font-size: 14px;
            color: #666;
          }
        }

        .transaction-amount {
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
  }
}