.save-card-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
  padding: 0 1.25rem;

  &__content {
    background-color: white;
    border-radius: 16px;
    padding: 1.75rem 1.25rem;
    margin-top: 2.5rem;
    width: 100%;
    max-width: 28rem;
    max-height: 18rem;
    position: relative;
    animation: slideIn 0.225s cubic-bezier(0, 0, 0.2, 1);
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
      max-width: 20ch;
    }
  }

  &__description {
    text-align: center;
    color: #71879C;
    margin-bottom: 1rem;
    font-size: 0.9375rem;
  }

  &__button {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 1rem;
    transition: background-color 0.2s ease;

    &--primary {
      background-color: var(--action-primary);
      color: white;

      &:hover {
        background-color: var(--action-primary);
      }
    }

    &--secondary {
      background-color: #EEF4FF;
      color: var(--action-primary);

      &:hover {
        background-color: darken(#EEF4FF, 5%);
      }
    }
  }
}

.close-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: var(--action-primary);
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}