.page-header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
  position: relative;
  min-height: 64px;

  &.with-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 150px;
    color: white;
    position: relative;

    .page-title {
      color: white;
      position: relative; 
      z-index: 2;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.8);
      position: relative; 
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.2)
      );
      z-index: 1; 
      pointer-events: none; 
    }
  }

  .back-button {
    padding: 8px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 16px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.1); 

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--action-primary);
    }
  }

  .title-container {
    flex: 1;
    text-align: center;
    padding: 0 48px;
    z-index: 2;
    position: relative;
  }

  .options-button {
    padding: 8px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    z-index: 3; 
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      width: 20px;
      height: 20px;
      color: white;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  /* min-height: 64px; */

  .back-button {
    padding: 8px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--action-primary);
    }
  }

  .title-container {
    flex: 1;
    text-align: center;
    padding: 0 48px;
    z-index: 1;
  }

  .page-title {
    margin: 0;
  }

  .subtitle {
    margin: 4px 0 0;
    color: rgba(0, 0, 0, 0.6);
  }
}
