.input-wrapper {
  position: relative;
  
  input {
    width: 100%;
    padding: 1rem;
    border: 1px solid rgba(113, 135, 156, 0.2);
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    background: transparent;
    
    &:focus {
      outline: none;
      border-color: var(--action-primary);
    }
    
    &:focus + label,
    &:not(:placeholder-shown) + label {
      transform: translateY(-1.5rem) scale(0.85);
      background-color: white;
      padding: 0 0.5rem;
    }
  }
  
  label {
    position: absolute;
    left: 1rem;
    top: 1rem;
    color: #71879C;
    transition: all 0.2s ease-in-out;
    transform-origin: left top;
    pointer-events: none;
  }
}