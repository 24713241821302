.profile-field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  
  .field-label {
    color: var(--soft-text);
    font-size: 1rem;
  }
}