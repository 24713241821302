.currency-selector {
  height: 100%;
  padding: 20px;

  &__header {
    position: relative;
    margin-bottom: 32px;

    h2 {
      text-align: center;
      font-weight: 400;
      font-size: 1.5rem;
    }

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: rgb(234, 235, 235);
      border: none;
      padding: 5px;
      cursor: pointer;
      color: inherit;
      border-radius: 50%;
      margin-left: 10px;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--action-primary);
      }
    }
  }

  &__list {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.currency-item {
  position: relative;
  height: 64px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 16px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__flag {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 0.938rem;
    }
  }

  button {
    position: absolute;
    inset: 0;
    background: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
  }
}

.exchange-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  z-index: 1000;

  &__content {
    background-color: white;
    border-radius: 12px;
    padding: 28px 20px;
    width: 100%;
    max-width: 450px;
    margin-top: 40px;
  }

  &__header {
    position: relative;
    margin-bottom: 40px;

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(227, 228, 227);
    border: none;
    cursor: pointer;
    padding: 7px;
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--action-primary);
    }
  }

  &__rates {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__rate-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: none;
    }

    .text-soft {
      font-size: 12px;
      color: #71879C;
    }
  }

  &__disclaimer {
    font-size: 12px;
    color: #71879C;
    text-align: center;
    margin: 24px 0;
  }

  &__button {
    width: 100%;
    padding: 16px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 32px;

    &:hover {
      background-color: var(--action-primary-hover);
    }
  }
}

.funding-methods {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.funding-method {
  position: relative;
  height: 64px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #71879C;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-size: 0.938rem;
  }

  &__description {
    font-size: 0.75rem;
    color: #71879C;
  }

  &__arrow {
    svg {
      width: 24px;
      height: 24px;
      fill: #71879C;
    }
  }

  button {
    position: absolute;
    inset: 0;
    background: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}