.make-best {
      margin-bottom: 16px;
    }

.quote-card {
      background-color: #f5f5f5;
      border-radius: 12px;

      .quote-label {
        display: block;
        color: #8c8c8c;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .quote-text {
        display: block;
        margin-bottom: 16px;
      }

      .quote-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
}