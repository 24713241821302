.ant-checkbox-group-item {
    display: block;
    margin-right: 0;
}

.accountslevel1{
    display: flex;
    justify-content: space-between;
}

.accountslevel2{
    font-size: 14px;
    font-weight: 600;
    color: #898989;
    margin-right: 26%;
}

@media screen and (max-width: 768px) {
  .accountslevel2 {
    margin-right: 16%;
  }
}

.responsive-input {
  width:38vw;
  height: 40px; 
}

@media screen and (max-width: 768px) {
  .responsive-input {
    height: 40px;
  }
}
.api-form-title{
    margin-bottom: 20px;
}

.api-form-add{
    background: #42CC7D; 
    color: #FFFFFF; 
    height:40px; 
    width:110px;
    
}

.brokerage-desc{
     font-size: 16px; 
     font-weight: 450; 
     color: #898989;
}

.brokerage-account{
    font-size: 16px; 
    font-weight: 450;
    color: #898989; 
}

.brokerage-account2{
    font-size: 16px; 
    font-weight: 450;
    color: #2475FE; 
}
.accounts{
    margin-top: 15px;
}


@media screen and (max-width: 768px) {
  .api-form-add {
    height: 40px;
  }
}

.responsive-typography {
  margin-right: 8px;
  display: flex;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 8px; 
  }
}

.api-form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.api-form-title {
  margin-bottom: 16px; 
  display: flex;
  align-items: center;
}
