.faq {
  &-item {
    border-bottom: 1px solid rgba(113, 135, 156, 0.2);
  }

  &-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  &-question {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4e4e4e;
    padding: 0;
    margin: 0;
  }
}

.plus-icon {
  font-size: 1.5rem;
  color: #71879C;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.open {
    transform: rotate(45deg);
  }
}

.faq-answer {
  background-color: rgba(113, 135, 156, 0.1);
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  
  p {
    margin: 0;
    font-size: 0.875rem;
    color: #4e4e4e;
    padding: 0;
  }
  
  &.open {
    max-height: 500px;
    opacity: 1;
    
    p {
      padding: 1rem;
    }
  }
}