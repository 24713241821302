.article-details {
  max-width: 1200px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.hero-image {
  position: relative;
  width: 100%;
  aspect-ratio: 7/6;
  margin-bottom: 2rem;
  
  @media (min-width: 1024px) {
    aspect-ratio: 4/3;
  }
}

.back-button-container {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 50;
  margin-top: 10px;
}

.share-container {
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  z-index: 50;

  @media (min-width: 640px) {
    bottom: 1.25rem;
    right: 1.25rem;
  }
}

.share-buttons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  opacity: 0;
  transition: all 0.3s;
  display: grid;
  grid-template-columns: repeat(6, 2rem);
  gap: 1rem;
  align-items: center;
  background: white;
  height: 3.5rem;
  border-radius: 1rem;
  padding: 0 1.25rem;

  @media (min-width: 640px) {
    height: 2.25rem;
  }

  &.active {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
}

.share-button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: none;
  background: #017189;
  color: white;
  cursor: pointer;
  
  @media (min-width: 640px) {
    width: 2.25rem;
    height: 2.25rem;
    background: white;
    color: #017189;
  }
}

.content {
  padding: 0 1.25rem;
}

h1 {
  font-size: 1.25rem;
  color: #171c22;
  margin-bottom: 0.75rem;
}

.meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.category {
  color: #017189;
  font-size: 0.875rem;
}

.date {
  color: #71879c;
  font-size: 0.875rem;
}

.article-body {
  color: #4a5567;
  font-size: 0.938rem;
  line-height: 1.5;
}

.article-body p {
  margin-bottom: 1rem;
}

.article-body h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #171c22;
  margin: 2rem 0 1rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}