
  .content-wrapper2 {

    .phone-header{
        margin-top: 20px;
        margin-left: 20px;
      }

    .content {
      margin-top: 32px;
      border-top: 1px solid #e5e5e5;

      
      .phone-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        .icon-container {
          height: 71px;
          width: 71px;
          background-color: #f5f5f5;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 35px;
            color: var(--action-primary);
          }
        }

        .details {
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          h2 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }

          p {
            color: #666;
            text-align: center;
            margin-top: 12px;
          }

          button {
            width: 100%;
            margin-top: 32px;
            padding: 16px;
            background-color: var(--action-primary);
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;

            &:hover {
              background-color: var(--action-primary);
            }
          }
        }
      }
    }
  }
