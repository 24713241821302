.notifications-page {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;

  header {
    position: relative;
    margin-bottom: 32px;
    text-align: center;

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: hsla(209, 18%, 53%, 10%);
      color: var(--action-primary);
      border-radius: 50%;
      height: 35px;
      width: 35px;
      font-size: 24px;
      cursor: pointer;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .notifications-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .notification-wrapper {
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 20px;
  }

  .notification {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .notification-header {
      display: flex;
      align-items: center;
      gap: 16px;

      .circular-container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
      }
    }

    .notification-image {
      height: 112px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
    }

    .amount-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 12px;

      .amount {
        font-weight: bold;
      }
    }

    .button {
      align-self: center;
      padding: 12px 24px;
      background-color: hsla(209, 18%, 53%, 10%);
      color: var(--action-primary);
      text-decoration: none;
      border-radius: 8px;
      font-weight: 500;
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--action-primary-hover);
      }
    }
  }

  .plan-funded, .wallet-funded, .plan-matured {
    .circular-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.05);

      .icon {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .plan-funded, .plan-matured {
    .amount-container {
      background-color: rgba(255, 0, 0, 0.1);

      .amount {
        color: red;
      }
    }
  }

  .wallet-funded {
    .amount-container {
      background-color: rgba(0, 255, 0, 0.1);

      .amount {
        color: green;
      }
    }
  }
}