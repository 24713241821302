.banks-and-cards {
  min-height: 100vh;  
  
  &__header {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: white;
    margin-left: 1.7rem;
    margin-bottom: 0px !important;
    
    &-back {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 50%;
      margin-right: 12px;
      
      &:hover {
        background-color: var(--hover-color, #f5f5f5);
      }
      
      svg {
        width: 20px;
        height: 20px;
        color: var(--icon-color, #666);
      }
    }
    
    &-title {
      font-size: 20px;
      font-weight: 500;
      color: var(--text-color, #333);
    }
  }
  

  &__navigation {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    border-bottom: 1px solid var(--border-color, #e0e0e0);
    text-decoration: none;
    color: var(--text-color, #333);

     &:last-child {
        border-bottom: none;
    }
    
    
    &-content {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: var(--icon-bg, #f0f7ff);
      border-radius: 50%;
      
      svg {
        width: 24px;
        height: 24px;
        color: var(--icon-color, #0066cc);
      }
    }
    
    &-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      
      &-title {
        font-size: 16px;
        font-weight: 500;
        color: var(--text-color, #333);
      }
      
      &-subtitle {
        font-size: 14px;
        color: var(--text-secondary, #666);
      }
    }
    
    &-arrow {
      svg {
        width: 20px;
        height: 20px;
        color: var(--icon-color-secondary, #999);
      }
    }
  }
}