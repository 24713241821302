.transaction-history {
  .container {
    padding: 1rem;

    .header {
      margin-bottom: 1rem;
    }

    .sticky-tabs {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 0.5rem 0;

      display: flex;
      overflow-x: auto; 
      white-space: nowrap; 
      -webkit-overflow-scrolling: touch; 

      
      scrollbar-width: none; 
      -ms-overflow-style: none;
      
      &::-webkit-scrollbar {
        display: none; 
      }

      .tab {
        flex: 0 0 auto; 
        margin-right: 0.5rem;
        padding: 1.2rem 1rem;
        border: none;
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 0.25rem;
        transition: background-color 0.3s, color 0.3s;
        margin-bottom: 8px;
        font-weight: 500;

        &:hover {
          background-color: var(--action-primary);
          color: white;
        }

        &.active {
          background-color: var(--action-primary);
          color: white;
        }
      }
    }
    .transactions {
      margin-top: 1rem;

      .transaction-item {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid #e0e0e0;
        text-decoration: none;

        .icon-container {
          width: 2rem;
          height: 2rem;
          background: rgba(242, 171, 32, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;

          svg {
            fill: #f29339;
            width: 1.3rem;
            height: 1.3rem;
          }
        }

        .transaction-details {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .info {
            .description {
              font-size: 1rem;
              color: #333;
            }

            .date {
              font-size: 0.875rem;
              color: #757575;
            }
          }

          .amount {
            font-weight: bold;
          }
        }
      }

      .no-transactions {
        text-align: center;
        font-size: 1rem;
        color: #757575;
        margin-top: 2rem;
      }
    }
  }
}
