.stock-plan {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;

  header {
    position: relative;
    text-align: center;
    margin-bottom: 10px;

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: rgba(165, 184, 187, 0.25);
      color: var(--action-primary);
    }

     .back-button .svg-button {
      font-size: 8px;  
      color: var(--action-primary);
    }

    .stocks-title {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem !important;
      font-family: Tomato, ui-monospace;
    }
  }

  .plan-image {
    display: block;
    width: 100px;
    height: 100px;
    margin: 10px auto;
    border-radius: 50%;
  }

  .plan-description {
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 1rem;
  }

 .features {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 96px;

      .feature-icon {
        background-color: #f0f0f0;
        color: var(--action-primary);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-bottom: 14px;
      }

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .historical-performance-title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 1.1rem;
  }

  .performance-grid {
    margin-bottom: 20px;
  }

  .performance-card {
    background-color: rgba(165, 184, 187, 0.25);

    .ant-card-body {
    padding: 10px;
    }

    .performance-year{
        font-weight: 600;
    }

    .performance {
      font-size: 18px;
      font-weight: bold;

      &.positive {
        color: #42bd04;
      }

      &.negative {
        color: #fc101b;
      }
    }
  }

  .info-card {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background-color:rgba(165, 184, 187, 0.25) ;

    .info-icon {
      font-size: 20px;
      color: var(--action-primary);
      margin-right: 10px;
    }
  }

  .get-started-button {
    height: 50px;
    font-size: 18px;
    background-color: var(--action-primary);
    
  }
  .get-started-button:hover {
    background-color: var(--action-primary-hover) !important;
}
}