.faq {
  &-container {
    height: 100%;
    background-color: #fff;
    margin-top: 10px;
  }

  &-list {
    padding: 0 2rem;

    @media (max-width: 768px) {
      padding: 0 1rem;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}