.right-side-container{
    background: #F7FAFC;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;

}
.right-side-title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #727272;
    text-space: 5;
}

.right-side-title{
    font-weight: 600;
    font-size: 28px;
    color: black;
}

.right-side-image{
    display: flex;
    margin-top: 15px ;
    justify-content: flex-end;
    align-items: center;

}

.right-side-image1{
    display: flex;
    justify-content: center;
    align-items: center;
}