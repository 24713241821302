.customer-corner {
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  
  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }

  &-title {
    font-size: 1.188rem !important;
    padding: 0.075rem 0;
    font-weight: 500 !important;
  }

  &-items {
    .ant-divider {
      margin: 3;
    }
  }

  &-item {
    position: relative;
    display: grid;
    grid-template-columns: 2.5rem auto;
    width: 100%;
    align-items: center;
    text-align: left;

    .icon-wrapper {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 20px rgba(53, 71, 89, 0.09);

      &.primary {
        background-color: #0facd3;

        .icon {
          width: 1.8em;
          height: 1.8em;
          fill: white;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      .ant-typography {
        
        &:first-child {
          color: #71879C;
          font-size: 1rem;
          white-space: nowrap;

          @media (min-width: 1280px) {
            font-size: 1.125rem;
          }
        }

        &:last-child {
          color: #71879C;
          font-size: 0.875rem;
        }
      }
    }

    .arrow-icon {
      position: absolute;
      right: 0;
      width: 2em;
      height: 2em;
      fill: #bfbfbf;
    }

    .overlay-button {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}