.ant-table-cell {
    border-radius: 0;
}

.table-link {
    text-decoration: underline;
}

.dropdown {
    background-color: rgba(233, 236, 248, 0.9);
    font-weight: bold;
    color: rgb(19, 15, 38);
    height: auto;
    width: 100%;
    padding: .2rem 1rem;
    border: none;
}

.ant-space-item .anticon {
    color: initial;
}

.dropdown--green {
    color: rgb(66, 204, 125);
}

.dropdown--red {
    color: rgb(230, 82, 31);
}

.cell--green {
    background-color: rgb(66, 204, 125, .7);
}

.cell--green-2 {
    background-color: rgb(66, 204, 125);
}

.cell--red {
    background-color: rgb(230, 82, 31, .7);
}

.dropdown-empty {
    color: #A3A8BA;
}

.diary-home .table-buttons {
    background-color: white;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
}

.editable-table-input {
    /* width: auto;
    min-width: 5rem; */
}

.editable-table-input.eti-picker {
    min-width: 7rem;
}

.diary-home .ant-card {
    width: 100%;
    height: 100%;
}

.tooltip-icon {
    opacity: 0.3;
}

.tooltip-icon:hover {
    opacity: .5;
}

.diary-charts-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

@media screen and (min-width: 992px) {
    .diary-charts-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .diary-charts-grid > *:nth-child(1) {
        grid-column: span 2;
    }

    .diary-charts-grid > *:nth-child(4) {
        grid-column: span 2;
    }
}

@media screen and (min-width: 1200px) {
    .diary-charts-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .diary-charts-grid > * {
        grid-column: span 2;
    }
}