.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
}

.modal-title {
  font-size: 1.25rem;
  margin: 0 auto;
}

.modal-close-button {
  position: absolute; 
  left: 0; 
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #555;
}

.modal-close-button:hover {
  color: #000;
}


.modal-body {
  margin-bottom: 1.5rem;
}

.plan-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-footer {
  text-align: center;
}

.confirm-button {
  padding: 0.75rem;
  width: 100%;
  background: #008080;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
}

.confirm-button:hover {
  background: #006666;
}
