.choose-card {
  min-height: 100vh;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);

  &__container {
    padding: 1.75rem 1.25rem;
    max-width: 768px;
    margin: 0 auto;

    @media (min-width: 1024px) {
      padding: 1.75rem;
    }
  }

  &__form {
    width: 100%;
  }

  &__header {
    position: relative;
    margin-bottom: 2.5rem;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
      color: #3D4853;
    }
  }

  &__content {
    margin-top: 2rem;
  }

  .card-list {
    margin-bottom: 1rem;
  }

  .no-cards-message {
    text-align: center;
    font-size: 0.875rem;
    color: #71879C;
    margin: 2rem 0;
  }

  .add-card-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid rgba(113, 135, 156, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &__text {
      text-align: left;
      
      p {
        color: #3D4853;
        font-size: 0.9375rem;
        
        &.subtitle {
          font-size: 0.75rem;
          color: #71879C;
          margin-top: 0.25rem;
        }
      }
    }
  }

  .card-icon {
    color: #71879C;
    width: 42px;
    height: 42px;
  }

  .arrow-icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: #71879C;
    margin-top: 3px;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}