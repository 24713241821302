.portfolio {
  padding: 20px;

  @media (min-width: 1024px) {
    padding: 28px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.125rem;
    }

    .see-our-portfolio {
      display: flex;
      align-items: center;
      color: #00A3B1;
      text-decoration: none;
      cursor: 'pointer';

      svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
      }
    }
  }

  .stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 40px;

    .stat-card {
      background-color: #f8f9fa;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 8px;

      p {
        color: #6c757d;
        font-size: 0.75rem;
      }

      h2 {
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }

  .cta {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p:nth-child(2) {
      color: #6c757d;
      text-align: center;
      width: 50%;
      margin-top: 8px;
    }

    button {
      color: #007bff;
      font-size: 1.125rem;
      margin-top: 16px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}