.profile-field-clickable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  
  &:hover {
    background: var(--hover-bg);
  }
  
  .field-label {
    color: var(--soft-text);
    font-size: 1rem;
  }
  
  .field-value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}