.more-page {
  padding: 20px;
  font-family: Arial, sans-serif;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      font-weight: 500;
    }

    .notification-button {
      position: relative;
      background: rgba(166, 203, 214, 0.5);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      font-size: 20px;
      cursor: pointer;

      
      .bell-icon {
        color: var(--action-primary);
        margin-top: 4px;
      }

      .notification-badge {
        position: absolute;
        top: -5px;
        right: -12px;
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 2px 5px;
        font-size: 12px;
      }
    }

    .logout-container {
      display: flex;
      align-items: center;

      .logout-button {
        color: rgb(214, 23, 23);
        background: none;
        border: none;
        cursor: pointer;
        margin-right: 5px;
      }

      .logout-icon {
        color: #888;
        font-size: 14px;
        color: rgb(214, 23, 23);
        margin-left: 10px;
      }
    }
  }

  .profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .profile-image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #f0f0f0;
      margin-right: 15px;
    }

    .profile-info {
      p {
        margin-bottom: 5px;
        font-weight: 300;
      }

      .edit-profile-button {
        background-color: #e0f0ff;
        color: var(--action-primary);
        padding: 5px 15px;
        border-radius: 3px;
        font-size: 14px;
        border: none;
        cursor: pointer;
      }
    }

    .profile-link {
      margin-left: auto;
      color: #888;
    }
  }

  h2 {
    text-transform: uppercase;
    color: #888;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .menu-section {
    
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid #eee;
      text-decoration: none;
      color: inherit;

      &:last-child {
        border-bottom: none;
      }

      span {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
      

      .id-verification {
        display: flex;
        align-items: center;
        justify-content: space-between;
        }

        .refer-earn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        }

      .verification-status {
        background-color: #2e7d32;
        color: #ffffff;
        padding: 4px 8px;
        border-radius: 12px;
        font-size: 12px;
        margin-right: 10px;
      }

      .referral-tag {
        background-color: #e0f0ff;
        color: var(--action-primary) !important;
        padding: 3px 8px;
        border-radius: 12px;
        border: 1px solid #bdbdbd;
        outline-color: #d9f1f3 !important;
        outline-width: 2px !important;
        outline-style: solid !important;
        font-size: 13px;
        margin-right: 10px;
        font-style: italic;
      }

      .switch {
            position: relative;
            display: inline-block;
            width: 45px;
            height: 16px;
            }

            .switch input {
            opacity: 0;
            width: 0;
            height: 0;
            }

            .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            }

            .slider:before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 0px;
            bottom: -4px;
            background-color: rgb(238, 238, 238);
            transition: .4s;
            border: 1px solid #bdbdbd;
            }

            input:checked + .slider {
            background-color: #2196F3;
            }

            input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
            }

            input:checked + .slider:before {
            transform: translateX(16px);
            }

           
            .slider.round {
            border-radius: 34px;
            }

            .slider.round:before {
            border-radius: 50%;
            }

            /* Disabled state */
            input:disabled + .slider {
            opacity: 0.5;
            /* cursor: not-allowed; */
            }
    }
  }
}