.country-select {
  position: relative;

  .country-dropdown {
    width: 120px;
    padding: 0.5rem;
    border: 1px solid #00b4b4;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    cursor: pointer;

    &:focus {
      border-color: #008888;
    }
  }
}
