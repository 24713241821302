.page-content {
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.select-bank {
  padding: 28px 20px;
  
  @media (min-width: 1024px) {
    padding: 28px 28px;
    border-left: 1px solid #e5e7eb;
    border-right: 1px solid #e5e7eb;
  }

  header {
    position: relative;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  .info-box {
    width: 100%;
    margin-top: 2rem;
    padding: 1.25rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #F8F9FA;

    svg {
      color: var(--action-primary);
      width: 24px;
      height: 24px;
    }

    p {
      color: #4A5568;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.5rem;

    .icon-container {
      height: 3.75rem;
      width: 3.75rem;
      border-radius: 50%;
      background-color: #F8F9FA;
      display: flex;
      align-items: center;
      justify-content: center;

      .bank-icon {
        color: var(--action-primary);
        font-size: 2.1875rem;
      }
    }

    p {
      margin-top: 1rem;
      text-align: center;
      color: #4A5568;
    }
  }

  .add-bank-button {
    width: 100%;
    margin-top: 2.5rem;
    padding: 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--primary-100);
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken(var(--action-primary), 5%) !important;
    }

    &:active {
      background-color: darken(var(--action-primary), 10%);
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}