.wallet {
  font-family: Arial, sans-serif;
  color: #222222;

  .wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    .wallet-logo {
      height: 26px;
    }

    .notifications-button {
      position: relative;
      background: none;
      border: none;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
        fill: #222222; 
      }

      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: rgb(233, 91, 91);
        color: white;
        border-radius: 50%;
        padding: 2px 5px;
        font-size: 0.75rem;
      }
    }
  }

  .wallet-content {
    padding: 20px;

    .balance-section {
      text-align: center;
      margin-bottom: 20px;

      .balance-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        color: #666;

        .hide-balance-button {
          background: none;
          border: none;
          cursor: pointer;
          margin-left: 10px;
          padding: 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .balance-amount {
        font-size: 32px;
        font-weight: bold;
        margin: 0;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 10px;
      padding: 30px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

      .action-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #333;
        margin: 0 15px;

        .icon-container {
          background-color: #f0f0f0;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          svg {
            width: 24px;
            height: 24px;

            
            
          }

          &.primary {
            background-color: #00a8a8;
            color: white;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }

    .linked-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;

      h3 {
        font-size: 16px;
        margin: 0;
      }

      p {
        font-size: 14px;
        color: #666;
        margin: 5px 0 0;
      }

      .link-card-button {
        display: flex;
        align-items: center;
        background-color: #00a8a8;
        color: white;
        border-radius: 20px;
        text-decoration: none;
        font-size: 14px;
        width: 100px;
        padding-left: 12px;

        svg {
          margin-left: 1px;
          height: 28px;
          width: 28px;
          fill: #fff;
        }
      }
    }

    .wallet-interest {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
      padding: 15px;
      border-radius: 10px;
      text-decoration: none;
      color: #333;
      margin-bottom: 20px;

      svg {
          margin-left: 5px;
          height: 30px;
          width: 30px;
        }

      p {
        margin: 0;
        font-size: 14px;

        .new-badge {
          background-color: #7b61ff;
          color: white;
          padding: 1px 6px;
          border-radius: 10px;
          font-size: 10px;
          margin-left: 5px;
          text-transform: uppercase; 
          font-weight: bold;
          position: relative;
          top: -3px; 
          display: inline-block;
        }
      }

      .interest-amount {
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
      }
    }

    .recent-transactions {
      h2 {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .transactions-list {
        .transaction-item {
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid #eee;
          text-decoration: none;
          color: inherit;

          .transaction-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #e6f7f7;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            font-size: 18px;
          }

          .transaction-details {
            flex-grow: 1;

            .transaction-title {
              font-size: 13px;
              margin: 0 0 5px;
            }

            .transaction-date {
              font-size: 12px;
              color: #666;
              margin: 0;
            }
          }

          .transaction-amount {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  }
}