.fund-wallet {
  height: 100%;
  padding: 20px 20px 0;

  @media (min-width: 1024px) {
    padding: 28px 28px 0;
  }

  &__header {
    position: relative;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--action-primary);

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
}

.funding-methods {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.funding-method {
  position: relative;
  height: 64px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--action-primary);

    svg {
      width: 42px;
      height: 42px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-size: 0.938rem;
  }

  &__description {
    font-size: 0.75rem;
    color: #71879C;
  }

  &__arrow {
    svg {
      width: 24px;
      height: 24px;
      fill: #71879C;
    }
  }

  button {
    position: absolute;
    inset: 0;
    background: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}