.portfolio-view {
  padding: 28px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;

  .header {
    position: relative;
    margin-bottom: 24px;

    h2 {
      text-align: center;
      font-size: 24px;
      max-width: 400px;
      margin: 0 auto;
    }

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;

      .back-arrow-icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
      }
    }
  }

  .subtitle {
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 20px;
  }

  .profile-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    margin-top: 56px;
    margin-bottom: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .profile-icon {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      width: 52px;
      height: 52px;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    h2 {
      margin-top: 16px;
      margin-bottom: 8px;
      text-align: center;
    }

    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      text-align: center;
    }
  }

  .distribution-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    max-width: 400px;
    margin: 0 auto;

    .line {
      height: 1.5px;
      flex: 1;
      background: linear-gradient(to right, rgba(128, 128, 128, 0.2), transparent);

      &:last-child {
        background: linear-gradient(to left, rgba(128, 128, 128, 0.2), transparent);
      }
    }

    p {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 3px;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
    }
  }

  .chart-section {
    position: relative;
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;

    .donut-chart {
      position: relative;
    }

    .chart-center-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 52px;
      height: 52px;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #BC1058;
    }
  }

  .asset-info {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;

    .asset-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      min-width: 200px;

      .asset-icon {
        width: 36px;
        height: 36px;
        background: rgba(188, 16, 88, 0.1);
        color: #BC1058;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .asset-text {
        .asset-name {
          font-size: 15px;
          margin-bottom: 4px;
        }

        .asset-value {
          font-size: 13px;
          color: #BC1058;
        }
      }
    }
  }

  .button-group {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .btn {
      width: 100%;
      padding: 16px;
      border-radius: 8px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &.btn-primary {
        background: var(--action-primary);
        color: white;

        &:hover {
          background: darken(var(--action-primary), 5%);
        }
      }

      &.btn-secondary {
        background: rgba(15, 14, 14, 0.05);
        color: var(--action-primary);
        border: 1px solid rgba(0, 191, 166, 0.1);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}