.duration-selector {
  padding: 28px 20px;
  
  .header {
    position: relative;
    text-align: center;
    
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .progress {
    margin-top: 56px;

    .caption {
      color: #666;
      margin-bottom: 16px;
      font-size: 0.875rem;
    }

    .progress-bar {
      height: 10px;
      border-radius: 20px;
      background-color: #e6e6e6;

      .progress-fill {
        height: 100%;
        border-radius: 20px;
        background-color: var(--action-primary);
      }
    }
  }

  .question {
    margin-top: 40px;
  }

  .returns-preview {
    display: flex;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 16px 20px;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;

    .returns-value {
      font-size: 1.75rem;
      font-weight: 700;
    }

    .returns-label {
      font-size: 0.875rem;
    }

    .chart-icon {
      width: 48px;
      height: 36px;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='36' viewBox='0 0 48 36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.1929 35.9243H44.2607C46.4233 35.9243 47.5151 34.8325 47.5151 32.6069V3.33838C47.5151 1.0918 46.4233 0 44.2607 0H38.1929C36.0513 0 34.9595 1.0918 34.9595 3.33838V32.6069C34.9595 34.8325 36.0513 35.9243 38.1929 35.9243Z' fill='currentColor'/%3E%3C/svg%3E");
    }
  }

  .selection-label {
    text-align: center;
    margin-top: 28px;
  }

  .duration-options {
    display: flex;
    justify-content: space-around;
    margin-top: 24px;

    .duration-option {
      height: 83px;
      width: 83px;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      background-color: #f8f9fa;
      color: #666;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      &.selected {
        background-color: var(--action-primary);
        border-color: var(--action-primary);
        color: white;
      }

      .duration-value {
        font-size: 1.75rem;
        font-weight: 700;
      }

      .duration-label {
        font-size: 0.875rem;
      }

      input {
        height: 0;
        width: 0;
        opacity: 0;
      }
    }
  }

  .disclaimer {
    text-align: center;
    color: #666;
    margin-top: 40px;
    font-size: 0.875rem;
  }

  .submit-button {
    width: 100%;
    margin-top: 32px;
    padding: 12px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: darken(var(--action-primary-hover), 5%);
    }
  }
}