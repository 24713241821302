.bank-accounts {
  .content {
    height: 100vh;
    padding: 1.75rem 1.25rem;

    @media (min-width: 1024px) {
      padding: 1.75rem 1.75rem;
    }

    .header {
      position: relative;

      .title {
        text-align: center;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }

    .account-list {
      margin-top: 1.75rem;
      border-top: 1px solid #e0e0e0;

      .account-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 0;
        text-decoration: none;

        .account-details {
          .account-main {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #012224;

            .account-number {
              font-size: 1rem;
            }

            .icon-small {
              width: 0.5rem;
              height: 0.5rem;
              fill: #bdbdbd;
            }

            .account-bank {
              font-size: 1rem;
            }
          }

          .account-holder {
            font-size: 0.875rem;
            color: #757575;
          }
        }

        .icon {
          width: 1rem;
          height: 1rem;
          fill: #757575;
        }
      }
    }

    .add-account-button {
      margin-top: 2.5rem;
      width: 100%;
      padding: 1rem;
      background-color: var(--action-primary);
      color: white;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      border-radius: 0.25rem;

      &:hover {
        background-color: var(--action-primary-hover);
      }
    }
  }
}
