.start-business-plan {
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
    padding-top: 0px;

    h2 {
      font-size: 24px;
      font-weight: 400;
    }

    .back-button {
      position: absolute;
      left: 0;
      top: 25%;
      transform: translateY(-50%);
      
      border-radius: 50%;
      background-color: rgba(165, 184, 187, 0.25);
      color: var(--action-primary);
      svg{
        height: 14px;
        width: 14px;
      }
      
    }
    
  }

  .cover-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 24px;
  }

  .description {
    text-align: center;
    color: #666;
    font-size: 16px;
    margin-bottom: 32px;
  }

  .features {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 96px;

      .feature-icon {
        background-color: #f0f0f0;
        color: var(--action-primary);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-bottom: 14px;
      }

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .info-card {
    background-color: #f0f0f0;
    margin-bottom: 64px;
    width: 100%;

    .ant-card-body {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .info-icon {
      color: var(--action-primary);
      font-size: 20px;
      margin-right: 8px;
    }

    p {
      font-size: 14.5px;
      margin: 0;
      font-weight: 500;
    }
  }

  .continue-button {
    width: 100%;
    height: auto;
    padding: 15px 0;
    font-size: 16px;
    background-color: var(--action-primary);
    border-color: var(--action-primary);

    &:hover,
    &:focus {
      background-color: var(--action-primary-hover)!important;
      border-color: var(--action-primary-hover);
    }

    &:active,
    &.ant-btn-primary:active {
      background-color: hsl(183, 90%, 23%);
      border-color: hsl(183, 90%, 23%);
    }
  }
}