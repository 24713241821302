.plan-name-container {
    height: 100vh;
    transition: duration 300ms;

    .form-wrapper {
        padding: 28px 20px;

        @media (min-width: 1024px) {
            padding: 28px 28px;
        }
    }

    .form-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .plan-form {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }

    .form-inner {
        position: relative;
    }

    .header-section {
        position: relative;

        h2 {
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
        }

        .back-button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: rgb(218, 219, 219);
            border: none;
            cursor: pointer;
            padding: 8px;
            border-radius: 50%;

            svg {
                width: 24px;
                height: 24px;
                color: var(--action-primary) !important;
            }
        }
    }

    .progress-section {
        margin-top: 56px;

        .progress-text {
            color: #666;
            font-size: 0.875rem;
            margin-bottom: 16px;
        }

        .progress-bar-container {
            height: 10px;
            border-radius: 20px;
            background-color: #e6e6e6;

            .progress-bar-fill {
                height: 100%;
                border-radius: 20px;
                background-color: var(--action-primary);
                transition: width 300ms ease;
            }
        }
    }

    .input-section {
        margin-top: 40px;

        p {
            margin-bottom: 20px;
        }

        .input-wrapper {
            input {
                width: 100%;
                padding: 16px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 1rem;

                &:focus {
                    outline: none;
                    border-color: var(--action-primary);
                }
            }
        }
    }

    .submit-button {
        width: 100%;
        margin-top: 32px;
        padding: 16px;
        background-color: var(--action-primary);
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: darken(hsl(183, 96%, 22%), 10%);
        }
    }

    .error-message {
        display: flex;
        align-items: center;
        margin-top: 16px;
        padding: 12px;
        background-color: #ffebee;
        border-radius: 4px;
        color: #d32f2f;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            fill: currentColor;
        }

        span {
            font-size: 0.875rem;
        }
    }
}