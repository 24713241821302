.slider-container {
    display: flex;
    flex-direction: column;

}

.slides {
    display: flex;
    margin-top: 20px;

}

.slides img {
    display: none;
    width: 40vw;
    height: 60vh;
    object-fit: cover;
    margin-top: 13px;
    align-self: center;
}

.slides img.active {
    display: block;
}

.pagination {
    display: flex;
}

.dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    border: solid #A0CFCF 1px;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
}

.dot.active {
    background-color: #09797A;
    border: solid 1px darkolivegreen;
}

.hide-at-phone-size {
    @media only screen and (max-width: 767px) {
        display: none;
    }
}