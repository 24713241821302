.title {
    font-size: 1.75rem !important;
    line-height: 2.125rem !important;
    font: 'Neue Power';
}

.description {
    color: var(--text-soft-color);
    margin-top: 0.75rem;
    line-height: 140%;
}

.alert {
    margin-top: 2rem;
}

.alert * {
    margin: 0;
}

.emailControl {
    composes: control from "../../Component/AuthLayout/InputField/style.module.css";
    margin-top: 2rem;
}

.passwordControl {
    composes: control from "../../Component/AuthLayout/InputField/style.module.css";
    margin-top: .75rem;
}

.submitButton {
    margin-top: 2rem;
    width: 100%;
    padding: 16.5px 0;
    background-color: var(--primary-color-2);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 100ms ease-in;

    &:hover {
        background-color: var(--primary-color);
    }
}

.details {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.url {
    color: var(--primary-color-2);
    font-weight: bold;
}

.authButtons {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
