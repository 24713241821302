.build-wealth {
  &__container {
    padding: 28px 20px;
    @media (min-width: 1024px) {
      padding: 28px 28px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: #E5E9EA;
    align-items: center;
    position: relative;
    margin: -2px -2px 10;
    @media (min-width: 1024px) {
      margin: -28px -28px 0;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    height: 200px;

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }

    .diamond-image {
      position: absolute;
      top: 58px;
      left: 50%;
      transform: translateX(-50%);
      width: 190px;
      z-index: 60;
    }
  }

  .info-section {
    background-color: white;
    border-radius: 12px;
    margin: -80px 20px 0;
    padding: 56px 16px 24px;
    z-index: 50;
    @media (min-width: 1024px) {
      margin: -80px 28px 0;
    }

    h2 {
      text-align: center;
      margin-bottom: 6px;
      font-size: 14px;
    }

    p {
      text-align: center;
      font-size: 15px;
      color: #666;
    }

    hr {
      margin: 20px 0;
      border: none;
      border-top: 1px solid rgba(200, 200, 200, 0.5);
    }

    .info-text {
      text-align: center;
      font-size: 15px;
      color: #666;

      p {
        margin-bottom: 20px;
      }
    }

    .risk-level {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14.5px;

      p {
        color: #666;
      }

      .risk-badge {
        display: flex;
        align-items: center;
        background-color: rgba(211, 208, 208, 0.2);
        border-radius: 6px;
        padding: 6px 10px;

        span {
          margin-right: 8px;
        }

        svg {
          width: 18px;
          height: 18px;
          fill: #007bff;
        }
      }
    }

    .info-box {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      margin-top: 20px;
      padding: 10px;
      border-radius: 8px;

      svg {
        width: 24px;
        height: 24px;
        fill: #0eacac;
        margin-right: 8px;
      }

      p {
        font-size: 14.5px;
      }
    }
  }

  .button-container {
    padding: 24px 20px;
    width: 100%;
    @media (min-width: 1024px) {
      padding: 32px 28px;
    }

    .start-investing-button {
      width: 100%;
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      color: white;
      background-color: var(--action-primary);
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: var(--action-primary-hover);
      }
    }
  }
}