.disclose {
   max-width: 85rem;
   margin-left: auto;
   margin-right: auto;

   .disclose-section {
    max-width: 100%;
    margin: 0 auto;
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;
    @media (max-width: 768px) {
       margin-left: 2rem;
     margin-right: 2rem;
    }

  @media (min-width: 768px) {
    padding-top: 4.125rem;
    padding-bottom: 4.125rem;
  }

  .banner {
    display: grid;
    place-items: center;
    height: 25.938rem;
    width: 100%;
    background-color: #0898A0;
    border-radius: 1.5rem;
    position: relative;
    overflow: hidden;

    @media (max-width: 1024px) {
        height: 15.938rem;
      }

    h1 {
      color: white;
      font-weight: 600;
      font-size: 5rem;

      @media (max-width: 1024px) {
          font-size: 3rem;
        }
    }

    img {
      position: absolute;
      color: transparent;

      &.dark-ball {
        left: -1rem;
        top: -1rem;
        width: 12.313rem;

         @media (max-width: 1024px) {
            width: auto;
            top: 0;
            left: 0;
            width: 7.313rem;
          }
      }

      &.light-ball {
        right: -1rem;
        bottom: -1rem;
        width: 16.375rem;

        @media (max-width: 1024px) {
            width: auto;
            bottom: -1rem;
            width: 12.375rem;
          }
      }
    }
  }
}

.disclose-main {
  container: wide;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  .sidebar {
    grid-column: span 3 / span 3;
    display: flex;
    position: sticky;
    top: 0;
    height: fit-content;
    
    

    .sidebar-content {
      background-color: #71879C1A;
      width: 43rem;
      padding: 1rem;
      font-weight: 500;
      font-size: 1.2rem;
      color: var(--text-soft-200);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  @media (max-width: 1024px) {
    .sidebar {
      display: none;
    }
}

  .spacer {
    grid-column: span 1 / span 1;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1.125rem;
    grid-column: span 8 / span 8;

    @media (max-width: 1024px) {
            grid-column: span 10 / span 10;
          }

    h2 {
      font-size: 3.5rem;
      font-weight: bold;
      display: display;
      @media (max-width: 1024px) {
        display: none;
        
      }
    }

    .last-modified {
      display: flex;
      align-items: center;
      gap: 0.375rem;

      svg {
        color: var(--text-soft-100);
        font-size: 0.875rem;
        display: none;
      }

      p {
        color: var(--text-soft-100);
        font-size: 1.25rem;
      }
    }

    .section-title {
      font-weight: bold;
      font-size: 1.325rem;
      margin-bottom: 1rem;
    }
  }
}
}
