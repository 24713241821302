.delete-modal {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 28rem;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  margin-left: 44px;

  .delete-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.15rem 1.25rem 1.25rem 1.25rem;
    background-color: white;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    margin-top: 2.5rem;

    .delete-header{
      padding-left: 0px !important;
    }
  }

  .modal-header {
    position: relative;
    margin-bottom: 0px;

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
    }
  }

  .modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }

    .error-alert {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      margin-top: 1rem;
      width: 100%;
      background-color: #fdeded;
      border-radius: 4px;
      color: #d32f2f;
      opacity: 0;
      transition: opacity 0.3s ease;

      &.visible {
        opacity: 1;
      }

      .error-message {
        margin-left: 0.5rem;
      }
    }

    .button-group {
      display: flex;
      gap: 1rem;
      width: 100%;
      margin-top: 3.3rem;

      button {
        flex: 1;
        padding: 0.875rem 1.75rem;
        font-size: 0.9375rem;
        font-weight: 500;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;
      }

      .cancel-button {
        background-color: #e7f3f3;
        color: var(--action-primary);
        &:hover {
           background-color: #e7f3f3;
        }
      }

      .delete-button {
        background-color: #d32f2f;
        color: white;

        &:hover {
          background-color: #c62828;
        }
      }
    }
  }
}