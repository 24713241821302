.control {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    border: solid 0.125rem hsl(205, 25%, 91%);
    transition: border-color 100ms ease-in;
    border-radius: 4px;
    margin-top: 2rem;
    width: 100%;

    &:hover,
    &:focus-within {
        border-color: var(--primary-color-2);
    }

    &:focus-within .label {
        color: var(--primary-color-2);
    }

    &:focus-within .label,
    &:has(.input:not(:placeholder-shown)) .label {
        transform: translate(0, -50%) scale(0.8);
        background-color: #ffff;
        height: min-content;
    }

    .label {
        transform-origin: left;
        transform: translate(0, 0) scale(1);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding-inline: 7px;
        margin-left: 7px;
        background-color: #fff0;
        color: var(--text-soft-color);
        transition:
            color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        width: fit-content;
        cursor: text;
        user-select: none;
    }

    .inputRoot {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        padding: 0 14px;
    }

    .input {
        font-size: medium;
        width: 100%;
        padding: 16.5px 0;
        background-color: transparent;
    }

    .input:focus-visible {
        outline: 0;
    }

    .hideButton {
        display: grid;
        place-items: center;
        background-color: var(--text-soft-color);
        border-radius: 9999px;
        padding: .5rem;
        font-size: 1.5rem;
        background-color: hsla(209, 18%, 53%, 10%);
        transition: background-color 100ms ease-in;

        svg {
            fill: var(--primary-color);
        }

        &:hover {
            background-color: hsla(209, 18%, 53%, 0);
        }
    }

}