.asset-mix {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;

  .ant-typography {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .chart {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

   .legend {
    width: 100%;

    .legend-item {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }

      .name {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .progress-bar {
        width: 120px;
        margin: 0;
        
        .ant-progress-inner {
          background-color: transparent;
        }
      }

      .percentage {
        width: 50px;
        text-align: right;
        font-size: 14px;
      }

      .value {
        width: 70px;
        text-align: right;
        font-size: 16px;
        margin-left: 120px;
      }
    }
  }

  :global(.ant-progress-text) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}