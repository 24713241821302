.analytics-container {
    display: flex;
    margin-bottom: 30px;
}

.analytics-container > div {
    width: 100%;
}

.analytics-title-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analytics-card {
    border: 1px solid #D7DBE7;
    background: white;
}

.pie-chart-legends-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
    max-height: 15rem;
    overflow: auto;
}

.pie-chart-legend {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
    font-size: small;
}

.pie-chart-legend-lideco {
    width: 9px;
    height: 9px;
    border-radius: 5px;
    margin-top: 2px;
}

.analytics-cards {
    margin: 0 auto;
}

.analytics-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 1rem;
}

h4.analytics-sub-title {
    margin: 0;
}

.analytics-card-component {
    height: 100%;
}

.analytics-card-component>.ant-card-body {
    height: 100%;
}

.title-wrapper {
    display: flex;
    align-items: center;
}

.company-name {
    margin-right: 5px;
    margin-left: 3px;
}

@media screen and (max-width: 768px) {
    .analytics-cards {
        max-width: 1200px;
    }
    .title-wrapper {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1200px) {
    .analytics-cards {
        max-width: 1500px;
    }
}

.analytics-col-wrapper {
    height: 100%;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.loading-icon {
    transform: rotate(0deg);
    font-size: 24;
    color: 'black';
}