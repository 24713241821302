.terms {
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;

    section {
    max-width: 100%;
    margin: 0 auto;
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;

    @media (max-width: 768px) {
     margin-left: 2rem;
     margin-right: 2rem;
    }

    .hero {
      display: grid;
      place-items: center;
      height: 25.938rem;
      width: 100%;
      background-color: #0898A0;
      border-radius: 1.5rem;
      position: relative;
      overflow: hidden;

      @media (max-width: 1024px) {
        height: 15.938rem;
      }

      h1 {
        color: white;
        font-weight: 600;
        font-size: 5rem;

        @media (max-width: 1024px) {
          font-size: 3rem;
        }
      }

      .dark-ball {
        position: absolute;
        left: -1rem;
        top: -1rem;
        width: 12.313rem;
        color: transparent;

        @media (max-width: 1024px) {
            width: auto;
            top: 0;
            left: 0;
            width: 7.313rem;
          }
      }

      .light-ball {
        position: absolute;
        right: -1rem;
        bottom: -1rem;
        width: 16.375rem;
        color: transparent;

        @media (max-width: 1024px) {
            width: auto;
            bottom: -1rem;
            width: 12.375rem;
          }
      }
    }
  }

  main {
    container: wide;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    .sidebar {
      grid-column: span 3 / span 3;
      display: flex;
      position: sticky;
      top: 0;
      height: fit-content;

      .nav {
        background-color: #71879C1A;
        padding: 1rem;
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--text-soft-200);
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        a {
          text-decoration: none;
          color: inherit;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}

    .content {
      grid-column: span 8 / span 8;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 1.325rem;

      @media (max-width: 1024px) {
            grid-column: span 10 / span 10;
          }

      h2 {
        font-size: 2.875rem;
        font-weight: bold;
        display: flex;
        @media (max-width: 1024px) {
           display: none;
          }
      }

      .date {
        display: flex;
        align-items: center;

        svg {
          color: #6B7280;
          font-size: 0.875rem;
          display: none;
        }

        p {
          color: #6B7280;
          font-size: 1.25rem;
        }
      }

      .section {
        h2 {
          font-weight: bold;
          font-size: 1.525rem;
          line-height: 1.75rem;
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: decimal;
        padding-left: 1.5rem;
      }

      .contact-info {
        p {
          &.title {
            font-weight: bold;
            font-size: 1.125rem;
            margin-bottom: 0.75rem;
            line-height: 1.75rem;
          }
        }

        a {
          color: #0898A0;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

}