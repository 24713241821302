.ant-table-thead > tr > th::before {
    display: none
}

.ant-table-tbody .ant-table-row {
    background-color: #F7F8FA;
}


.ant-table-wrapper .ant-table-thead > tr > th {
    background-color: white;
    border: none;
}

.ant-table-wrapper table {
    border-spacing: 0 8px;
}

.ant-table-row {
    font-size: 12px;

}

.custom-table .ant-table-tbody > tr.ant-table-row:hover > td,
.custom-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: lightgray;

}


