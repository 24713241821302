.whysection {
    @keyframes ball-bounce {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(60px);
        }
    }

    @keyframes ball-roll {
        0% {
            rotate: 60deg;
            translate: 0;
        }

        100% {
            rotate: -90deg;
            translate: -20%;
        }
    }

    /* Components */

    .hero {
        overflow: hidden;
        position: relative;
        max-width: 90rem;
        margin: auto;

        @media (min-width: 1024px) {
            margin-top: 2rem;
        }

        &-container {
            padding-top: 2rem;
            padding-bottom: 2rem;
            display: grid;
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            /* md */
            @media screen and (min-width: 640px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            /* lgMax */
            @media screen and ((min-width: 640px) and (max-width: 1024px)) {
                text-align: center;
                display: flex;
                width: 44rem;
            }

            /* lg */
            @media screen and (min-width: 1024px) {
                padding-top: 4rem;
                padding-bottom: 4rem;
                padding-right: 0;
                gap: 5rem;
            }

            /* xl */
            @media screen and (min-width: 1280px) {
                gap: 10rem;
            }
        }

        &-content {
            display: grid;
            gap: 2rem;

        }

        &__title {
            font-weight: 500;
            font-size: 2.25rem;
            line-height: 1.2;
            font-family: 'Neue Power';

            @media (min-width: 768px) {
                font-size: 3.75rem;
            }
            @media (max-width: 768px) {
                display: none;
                font-size: 3.75rem;
            }

            @media (min-width: 1024px) {
                font-size: 3rem;
            }

            @media (min-width: 1280px) {
                font-size: 3.75rem;
            }

            &--highlight {
                font-weight: 400;
                font-style: italic;
                color: var(--primary-color);
            }
        }
        &__mobile{
            display: none;
             @media (max-width: 768px) {
                display: grid;
                font-weight: 600;
                font-size: 2.0rem;
                line-height: 1.3;
                font-family: 'Neue Power';
            }
        }

        &__description {
            font-size: 1.125rem;
            color: var(--text-soft-color);
            line-height: 1.5;

            @media (min-width: 1024px) {
                font-size: 1.25rem;
            }
        }

        &__actions {
            display: flex;
            align-items: center;

            /* smMax */
            @media screen and (max-width: 768px) {
                justify-content: space-around;
                row-gap: 0.5rem;
            }

            /* md & lgMax */
            @media screen and (min-width: 768px) {
                gap: 1.5rem;

                @media screen and (max-width: 1024px) {
                    justify-content: center;
                    gap: 1.5rem;
                }
            }
        }

        &-img {
            display: flex;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: flex-start;

            @media (min-width: 1024px) {
                position: absolute;
                width: 40%;
            }

            &__container {
                position: relative;
                padding-top: 2rem;
            }

            &__ball-1 {
                aspect-ratio: 1 / 1;
                position: absolute;
                left: 1rem;
                top: -1rem;
                width: 45%;

                animation: ball-roll 2.8s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;

                @media (min-width: 1024px) {
                    top: 0;
                    left: -1rem;
                }
            }

            &__ball-2 {
                aspect-ratio: 1 / 1;
                display: grid;
                position: absolute;
                place-content: center;
                width: 70%;
                bottom: 0%;
                left: 43%;
                top:7%;

                animation: ball-bounce 2.8s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            }

            &__ball-img {
                filter: hue-rotate(320deg);
            }
        }
    }


   .why-section {
            margin: 0 auto;
            padding-bottom: 4rem;
            padding-top: 2rem;
            max-width: 82rem;

            h2 {
                font-size: 3rem;
                font-weight: 600;
                color: #002434;
                line-height: 1.2;
            }

            ul {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                gap: 1.5rem;
                padding-top: 3.625rem;

                .content-wrapper {
                display: grid;
                gap: 1.5rem;
                grid-column: span 7 / span 7;
                }

                li {
                background-color: #ebfafa;
                padding: 2.5rem;
                border-radius: 1.5rem;

                img {
                    color: transparent;
                }

                h3 {
                    font-weight: 500;
                    color: #002434;
                    font-size: 2rem;
                }

                p {
                    color: #858ba0;
                    font-size: 1.25rem;
                    font-weight: 300;
                }
                }
            }

          
            .bg-secondary {
                background-color: #fef3ef;
            }

            .bg-tertiary {
                background-color: #f5f1fe;
                grid-column: span 5 / span 5;
                height: fit-content;

                .highlighted {
                h3 {
                    font-weight: 600;
                    color: #796cf7;
                    font-size: 3rem;
                }
                }
            }
            }

            /* Media Queries */
            @media (max-width: 768px) {
            .why-section {
                padding: 2rem;
                h2 {
                font-size: 2rem;
                }

                ul {
                /* padding: 2rem; */
                grid-template-columns: 1fr;

                .content-wrapper {
                    grid-column: span 12 / span 12;
                }

                h3 {
                    font-size: 1.5rem;
                }

                p {
                    font-size: 1rem;
                }
                .bg-tertiary {
                grid-column: span 12 / span 12;
                padding-bottom: 2rem;
                }
                }
            }
            }
            @media (max-width: 1024px) {
                .why-section{
                    .bg-tertiary{
                        grid-column: span 6 / span 6;
                        
                    }
                }
            }

            @media (min-width: 769px) and (max-width: 1024px) {
            .why-section {
                padding: 2rem;
                h2 {
                font-size: 2.5rem;
                }

                ul {
                grid-template-columns: repeat(6, 1fr);

                .content-wrapper {
                    grid-column: span 6 / span 6;
                }

                h3 {
                    font-size: 1.75rem;
                }

                p {
                    font-size: 1.125rem;
                }
                }
            }
    }
}