.stocks-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.header-column{
    background-color: #F7F8FA;
    border-top-left-radius: 8px;
    border-top-right-radius:8px ;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;

}
.titles-alignment{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom:5px ;
    font-weight: 510;
    font-size: 30px;
}
.title-left{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.stock-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.stock-icon{
    width: 18px;
    height: 18px;
}

.pnl-text {
  color: #5B5B5B;
  font-weight: 510;
}
