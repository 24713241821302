.vbar-chart tbody {
    
}

.vbar-chart__line, .vbar-chart__xaxis {
    padding-left: 1rem;
    width: 100%;
}

.vbar-chart__name {
    text-align: right;
    white-space: nowrap;
    opacity: .8;
}

.vbar-chart__line div {
    position: relative;
    height: 10px;
    background-color: red;
    border-radius: 0 0.5rem 0.5rem 0;
}

.vbar-chart__line span {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(6px, -50%);
    white-space: nowrap;
}

.vbar-chart__xaxis > div {
    position: relative;
    margin-top: .5rem;
    width: 100%;
}

.vbar-chart__label {
    position: absolute;
    opacity: .3;
}