.page-container {
  .page-content {
    .page-header2 {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-left: 1.7rem;
      margin-bottom: 0px;
      
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .back-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px;
        border-radius: 50%;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        .arrow-icon {
          width: 24px;
          height: 24px;
        }
      }
    }

    .content-wrapper2 {
      padding: 0 2rem;

      .description {
        margin-top: 0rem;
        color: #666;
        text-align: left;
      }

      .verification-list2 {
        margin-top: 0rem;
        border-top: 1px solid #e5e5e5;

        .verification-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 4rem;
          border-bottom: 1px solid #e5e5e5;
          text-decoration: none;
          color: inherit;
          
          &[aria-disabled="true"] {
            pointer-events: none;
          }

          .status-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .status-chip {
              padding: 0 0.75rem;
              height: 24px;
              border-radius: 12px;
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              
              &.success {
                background-color: #4caf50;
                color: white;
              }
            }

            .arrow-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
