.add-bank-page {
  height: 100vh;
  transition: all 0.3s;
  padding: 18px 20px;
  
  @media (min-width: 1024px) {
    padding: 28px 28px;
  }
}

.header {
  position: relative;
  margin-bottom: 2.5rem;

  h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }
}

.warning-text {
  margin-top: 2.5rem;
  color: #4A5568;
  line-height: 1.5;
}

.form-group {
  margin-top: 1.75rem;
}

.input-wrapper {
  position: relative;
  width: 100%;
  
  input {
    width: 100%;
    padding: 1rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.375rem;
    font-size: 1rem;
    transition: all 0.2s;
    background: transparent;
    outline: none;
    
    &:focus {
      border-color: var(--action-primary);
      box-shadow: 0 0 0 1px var(--action-primary);
    }

    &:focus ~ label,
    &.has-value ~ label,
    &:not(:placeholder-shown) ~ label {
      transform: translateY(-1.5rem) scale(0.85);
      background-color: white;
      padding: 0 0.25rem;
      color: var(--action-primary) !important;
    }
  }

  label {
    position: absolute;
    left: 1rem;
    top: 1rem;
    color: #718096;
    transition: all 0.2s;
    transform-origin: left top;
    pointer-events: none;
    background-color: transparent;
  }
}

.continue-button {
  margin-top: 2rem;
  width: 100%;
  padding: 1rem 1.5rem; 
  background-color: var(--action-primary);
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
  height: 48px; 
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    background-color: var(--action-primary-hover);
    color: white !important;
  }

  &:disabled {
    background-color: var(--action-primary);
    cursor: not-allowed;
    color: #e7e8e9;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}