.next-of-kin {
  max-width: 800px;
  /* margin: 0 auto; */
  padding: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, sans-serif;

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
  }
}

.form-field {
  position: relative;
  margin-bottom: 8px;

  input,
  select {
    width: 100%;
    height: 48px;
    padding: 0 12px;
    font-size: 16px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: white;
    color: #1a202c;
    transition: all 0.15s ease;

    &::placeholder {
      color: transparent;
    }

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }

  select {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-right: 32px;
    color: #1a202c;

     option {
      color: #1a202c;
      padding: 12px;
      font-size: 16px;
      background: white;
    }

    
    &:invalid {
      color: #6B7280;
    }

    &:required:invalid {
      color: #6B7280;
    }
  }

  .form-label {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #6B7280;
    pointer-events: none;
    transition: all 0.15s ease;
    background: transparent;
    padding: 0 4px;
  }

  input:focus ~ .form-label,
  input:not(:placeholder-shown) ~ .form-label,
  select:focus ~ .form-label,
  select:not(.empty) ~ .form-label {
    top: -9px;
    left: 3;
    transform: translateY(0);
    font-size: 13px;
    color: var(--action-primary);
    background-color: white;
  }
}

.phone-field {
  .phone-input {
    display: flex;
    gap: 12px;

    .country-code {
      width: 120px;
      flex-shrink: 0;
    }

    .phone-input-wrapper {
      position: relative;
      flex: 1;
    }
  }

  .form-label {
    left: 132px;
  }

  input:focus ~ .form-label,
  input:not(:placeholder-shown) ~ .form-label {
    left: 0; 
  }
}

.error-message {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
  color: #F05252;
  margin-top: 4px;
}

.submit-error {
  text-align: center;
  color: #F05252;
  margin: -12px 0 12px;
}

.submit-button {
  width: 100%;
  height: 48px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;

  &:hover:not(:disabled) {
    background: darken(#5eb0e5, 5%);
  }

  &:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
  }
}

@media (max-width: 640px) {
  .next-of-kin {
    padding: 16px;

    form {
      padding: 0;
    }
  }

  .form-field {
    .phone-input {
      flex-direction: column;
      
      .country-code {
        width: 100%;
      }
    }
    
    &.phone-field .form-label {
      left: 12px;
    }
  }
}