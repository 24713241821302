.profile-page {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto !important;
  padding: 1.75rem 1rem !important;
  padding-bottom: 7rem;
  box-sizing: border-box;
  

  position: relative;
  left: 0;
  right: 0;
  
  @media (min-width: 640px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  
  @media (min-width: 1024px) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}


.profile-content {
  width: 100%;
  max-width: 768px;
  margin: 2.5rem auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.profile-header {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  
  .back-button {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    
    @media (min-width: 640px) {
      left: 2rem;
    }
    
    @media (min-width: 1024px) {
      left: 4rem;
    }
    
    &:hover {
      opacity: 0.8;
    }
  }
  
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.profile-picture-container {
  position: relative;
  align-self: center;
  margin-bottom: 2.5rem;
}

.profile-picture-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.profile-picture {
  height: 6.25rem;
  width: 6.25rem;
  border-radius: 9999px;
  background: var(--light-bg);
  margin-left: 20px;
}

.profile-emoji {
  position: absolute;
  right: 2rem;
  bottom: 0;
}

.profile-picture-hint {
  color: var(--soft-text);
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.profile-picture-button {
  position: absolute;
  inset: 0;
}

.profile-info {
  width: 100%;
}

.help-text {
  color: var(--soft-text);
  text-align: center;
  font-size: 0.875rem;
  margin: 1rem 0;
}

.contact-section, .more-info-section {
  margin-top: 3rem;
  
  h1 {
    color: var(--soft-text);
    font-weight: 500;
    font-size: 0.875rem;
    font-family: sans-serif;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
  }
}

.contact-fields {
  margin-top: 1rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}