.wallet-interest-container {
  height: 100vh;
  transition: duration 300ms;

  .page-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    margin-left: 1.7rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .content-container {
    margin-top: 2rem;
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @media (min-width: 1024px) {
      padding: 0 1.75rem;
    }

    .description {
      color: #666;
      font-size: 0.875rem;
      text-align: center;

      @media (min-width: 768px) {
        width: 80%;
      }
    }

    .toggle-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4rem;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 38px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #1976d2;
      }

      &:checked + .slider:before {
        transform: translateX(20px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 6px;
        bottom: 6px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
}