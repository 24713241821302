.page-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;

  header {
    padding: 28px 20px;

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid #E5E5E5;
    background-color: #fff;
    justify-content: space-between;
    padding: 0 100px;

    .tab {
      padding: 12px 24px;
      font-weight: 500;
      cursor: pointer;
      color: #616161;
      border: none;
      background: none;
      position: relative;
      transition: color 0.3s ease;

      &.active {
        color: #00A3B1;
        font-weight: 700;
        font-size: 0.875rem;

        &::before,
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
          height: 3px;
          background-color: #00A3B1;
          width: 45px;
          border-radius: 5px 5px 0 0;
        }
      }

      &:hover {
        color: #008C99;
      }
    }
  }

  .tab-content {
    padding: 20px;
  }

  .section {
    margin-bottom: 20px;

    h2 {
      font-size: 1.125rem !important;
      line-height: 1.25;  
      font-weight: 500;
      margin-bottom: 20px;
    }

    .card-scroll {
      display: flex;
      min-height: 250px;
      overflow-x: auto;
      gap: 20px;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .plan-card {
    min-width: 180px;
    min-height: 240px;
    padding: 0px;
    border-radius: 0.75rem;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
    

    .plan-info {
      p {
        font-size: 14px;
      }

      .balance {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .build-wealth-card {
    display: block;
    text-decoration: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
    overflow: hidden;
    height: 25rem;
    width: 100%;
    padding: 1.75;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .card-content {
      display: flex;
      justify-content:  space-between;
      align-items: center;
      padding: 2rem;
      background-color: transparent;
    }

    .card-text {
      max-width: 80%;
      color: #ffffff;
    }

    .card-title {
      font-size: 1.25rem;
      font-weight: 600;
      font-family: 'Tomato', sans-serif;
      margin-bottom: 0.5rem;
    }

    .card-description {
      font-size: 0.9375rem;
      margin-bottom: 1rem;
      line-height: 1.4;
    }

    .start-investing {
      font-size: 1rem;
      text-decoration: underline;
      font-weight: 500;
    }

    .arrow-icon {
      width: 24px;
      height: 24px;
    }
  }

  .matured-plans {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    color: inherit;
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }

    h2 {
      font-size:  1.125rem !important;
      line-height: 1.75rem !important;
      margin: 0;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      width: 24px;
      height: 24px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-left: auto;
      margin-right: 12px;
    }
  }
}