.custom-carousel {
      background: linear-gradient(to right, #ffffff, #ffffff);
      border-radius: 10px;
      padding: 3px;
      position: relative;
      margin-bottom: 10px;

      .ant-carousel {
        .slick-slide {
          text-align: center;
          height: 190px;
          line-height: 5px;
          background: #f0f1f3;
          overflow: hidden;
          border-radius: 10px;
        }

         .slick-dots {
          bottom: -20px;  

          li {
            margin: 0 4px;  
            width: 10px; 
            height: 10px;

            button {
              background: #d9d9d9;
              opacity: 0.4;
              width: 6px; 
              height: 6px; 
              border-radius: 50%; 
              padding: 0; 
            }

            &.slick-active {
              width: 12px; 
              height: 5px;

              button {
                opacity: 1;
                width: 12px; 
                height: 5px;
                border-radius: 30%;
                background: #818181;
              }
            }
          }
        }
    }
}