.general-statistic-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.general-statistic-title{
    font-weight:510;
    font-size:30px ;
    margin-bottom: -5px;
    margin-left: 10px;
}

.scrollable-container {
    overflow-x: auto;
    white-space: nowrap;
}

.ant-table-tbody .ant-table-row:hover > td {
  background: inherit !important;
}