.bank-account {
  .container {
    height: 100vh;
    padding: 1.75rem 1.25rem;

    .header {
      position: relative;

      .back-button {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
          width: 1rem;
          height: 1rem;
          fill: #1976d2;
        }
      }

      .title {
        text-align: center;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }

    .content {
      margin-top: 2rem;

      .circular-container {
        margin: 0 auto;
        height: 3.75rem;
        width: 3.75rem;
        background-color: #f0f0f0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .custom-icon {
          width: 2.2rem;
          height: 2.2rem;
          fill: var(--action-primary);
        }
      }

      .details {
        margin-top: 2rem;
        text-align: center;

        .account-number {
          font-size: 1.25rem;
          font-weight: 500;
        }

        .bank-info {
          margin-top: 0.5rem;

          .bank-name {
            color: #757575;
          }
        }
      }

      .info-list {
        margin-top: 2.5rem;

        .info-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 3rem;

          .label {
            color: #757575;
            font-weight: 500;
          }

          .value {
            color: #000000;
            font-weight: 600;
            font-size: larger;
          }
        }
      }

      .delete-button {
        margin-top: 4rem;
        width: 100%;
        padding: 1rem;
        background-color: #f44336;
        color: white;
        border: none;
        font-size: 1rem;
        border-radius: 0.25rem;
        cursor: pointer;

        &:hover {
          background-color: #d32f2f;
        }
      }
    }
  }
}
