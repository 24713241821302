.personalize-form {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 2rem 2rem;

  .form-header {
    position: relative;
    text-align: center;
    margin-bottom: 0rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .form-field {
    position: relative;
    margin-bottom: 2rem;

    input {
      width: 100%;
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      font-size: 1rem;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: var(--action-primary);
      }

      &:focus + .floating-label,
      &:not(:placeholder-shown) + .floating-label {
        top: -10px;
        left: 10px;
        font-size: 0.75rem;
        color: var(--action-primary);
      }
    }

    .floating-label {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      background: white;
      padding: 0 0.15rem;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.6);
      pointer-events: none;
      transition: all 0.3s ease;

      &.active {
        top: -4px;
        left: 10px;
        font-size: 0.75rem;
        color: var(--action-primary);
      }
    }
  }

  .image-upload {
    margin-top: 0.5rem;
    position: relative;

    .image-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
    }

    .image-preview {
      height: 15rem;
      width: 100%;
      border-radius: 0.75rem;
      margin-top: 1rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }

    input[type="file"] {
      position: absolute;
      inset: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }
  }

  .error-alert {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    margin-top: 1rem;
    background-color: #fdeded;
    border-radius: 4px;
    color: #d32f2f;

    .error-message {
      margin-left: 0.5rem;
    }
  }

  .submit-button {
    background-color: var(--action-primary);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1.5rem;

    &:hover {
      background-color: var(--action-primary-hover);
    }
  }

  .cancel-button {
    background-color: #dad8d8;
    color: var(--action-primary);
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      background-color: #eeeeee;
    }
  }
}
