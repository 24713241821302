.currency-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px;

  &__container {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 24px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }

  &__rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 12px 16px;
    border-radius: 8px;
  }

  &__rate-text {
    font-size: 14px;
    color: #5f6368;
    font-weight: 500;
  }

  &__info-button {
    background: none;
    border: none;
    color: var(--action-primary);
    font-size: 14px;
    padding: 4px 8px;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--action-primary);
      background: rgba(26, 115, 232, 0.1);
      border-radius: 4px;
    }
  }

  &__submit {
    margin-top: 32px;
  }
}