
.rp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F7FAFC;
}

h1 {
    font-size: 1.5rem;
}

h1, p{
    margin: 0;
}

.rp-container h1 + p {
    color: #333;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    max-width: 20rem;
    padding: 3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.wrapper .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.logo {
    max-width: 6rem;
    margin-bottom: 1rem;
}

.submit-btn {
    width: 100%;
}

.wrapper .bottom {
    text-align: center;
}

.timer {
    text-align: center;
    margin-top: .5rem;
}
@media screen and (max-width: 768px) {
    .wrapper {
        padding: 1.5rem;
    }
}