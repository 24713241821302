.container {
  .full-page {
    height: 100vh;

    .content-wrapper {
    

      .header {
        position: relative;
        margin-bottom: 2rem;
        text-align: center;

        .header-title {
          font-size: 1.75rem;
          font-weight: bold;
          color: #000;
        }
      }

      .form-container {
        width: 100%;
        max-width: 500px;

        .form-control {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.5rem;

          .form-label {
            display: none;
          }

          .input-wrapper {
            position: relative;
            display: flex;
            align-items: center;

            .password-input {
              width: 100%;
              padding: 0.75rem 2.5rem 0.75rem 1rem;
              border: 1px solid #00b4b4;
              border-radius: 5px;
              font-size: 1rem;
              outline: none;
              transition: border-color 0.3s;

              &:focus {
                border-color: #008888;
              }
            }

            .visibility-button {
              position: absolute;
              right: 1rem;
              background: none;
              border: none;
              cursor: pointer;

              svg {
                width: 20px;
                height: 20px;
                fill: #008888;
              }
            }
          }
        }

        .alert-container {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          background-color: #fdecea;
          border: 1px solid #f5c2c7;
          padding: 0.5rem;
          border-radius: 5px;
          color: #d32f2f;

          .alert-icon {
            margin-right: 0.5rem;

            svg {
              width: 20px;
              height: 20px;
              fill: #d32f2f;
            }
          }

          .alert-message {
            font-size: 0.875rem;
          }
        }

        .submit-button {
          width: 100%;
          padding: 0.75rem;
          background-color: #bce7e7;
          color: #fff;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          text-align: center;
          cursor: not-allowed;

          &:not(:disabled) {
            background-color: #008888;
            cursor: pointer;

            &:hover {
              background-color: #006666;
            }
          }
        }
      }
    }
  }
}
