.referral {
  &-container {
    height: 100%;
    background-color: #fff;
  }

  &-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(113, 135, 156, 0.1);

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-left: 1rem;
    }

    .back-button {
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 50%;
      }

      .back-icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }
}

.form {
  &-container {
    padding: 2rem 1.25rem;
    max-width: 600px;
    margin: 0 auto;

    @media (min-width: 1024px) {
      padding: 2rem 1.75rem;
    }
  }

  &-description {
    color: #71879C;
    margin-bottom: 1.5rem;
  }

  &-field {
    margin-top: 1.5rem;
    position: relative;
  }
}

.error-alert {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  background-color: rgba(211, 47, 47, 0.1);
  border-radius: 4px;
  color: #d32f2f;

  .error-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    fill: currentColor;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }
}