.standard-layout {
    /* Components */
    .header {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-size: 0.875rem;
        line-height: 1.25rem;

        .header-content {
            justify-content: space-between;
            font-family: 'Neue Power';
        }

        .header-logo {
            max-width: 3rem;
            /* display: none; */
        }

        .mobile-nav {
            position: relative;

            &__button {
                height: 1.5rem;
                width: 1.5rem;
                color: var(--primary-color-2);
            }

            @media screen and (min-width: 1024px) {
                display: none;
            }

            .header-menu {
                width: 100vw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                position: fixed;
                gap: 1.5rem;
                top: 0;
                right: 0;
                opacity: 0;
                transform: scale(0);
                transform-origin: top;

                z-index: 50;
                padding-left: 2rem;
                padding-right: 2rem;
                align-items: stretch;
                text-align: left;
                background-color: white;

                @media (max-width: 640px) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                &--visible {
                    opacity: 1;
                    transform: scale(1);
                }

                &__header {
                    display: flex;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 100%;
                }

                &__button {
                    @extend .flex-center;
                    color: var(--primary-color-2);
                    height: 1.5rem;
                    width: 1.5rem;

                    @media screen and (min-width: 1024px) {
                        display: none;
                    }

                    svg {
                        width: 1.25rem;
                        height: 1.25rem;
                        fill: var(--primary-color-2)
                    }
                }

                &__nav-link {
                    font-weight: 500;
                    color: var(--text-dark-color);
    
                    @media (max-width: 1024px) {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }

                    &.active {
                        color: var(--primary-color-2);
                    }
                }

                &__actions {
                    display: grid;
                    padding-top: 2.5rem;
                    padding-bottom: 2.5rem;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    column-gap: 0.5rem;
                    font-size: .875rem !important;
                        line-height: 1.25rem !important;

                    @media (min-width: 640px) {
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        column-gap: 1.5rem;
                    }

                    a {
                        text-align: center;
                        width: 100%;
                        display: block;
                        padding: 1rem;
                        font-weight: bold;
                        border-radius: 9999px;

                        &.tsignin {
                            background-color: rgb(113 135 156 / 0.05);
                        }

                        &.tsignup {
                            background-color: var(--primary-color-2);
                            color: white;
                        }
                    }
                }
            }
        }

        .larger-nav {
            column-gap: 4rem;

            @media screen and (max-width: 1024px) {
                display: none;
            }

            .nav-links {
                column-gap: 2.25rem;

                &__item {
                    font-weight: 500 !important;
                    color: var(--text-dark-color) !important;
                }
            }

            .nav-actions {
                column-gap: .5rem;
                font-weight: 500;

                * {
                    padding: 0.875rem 1.5rem;
                    border-radius: 9999px;
                }

                &__login {
                    background-color: rgb(113 135 156 / 0.05);
                }

                &__signup {
                    background-color: var(--primary-color-2);
                    color: white;
                }
            }
        }
    }

    .call-to-download {
        margin-top: 4rem;
        margin-bottom: 3rem;

        .ctd-card {
            background-color: rgb(113 135 156 / 0.05);
            border-radius: 1.5rem;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr;
            margin: 2rem 0;

            @media (min-width: 768px) and (max-width: 1023px) {
                grid-template-columns: minmax(auto, 23.3rem) 1fr;
            }

            @media (min-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (min-width: 1280px) {
                gap: 2rem;
            }

            &__content {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                padding-top: 2.75rem;
                padding-bottom: 2.75rem;


                @media (min-width: 768px) {}

                @media (min-width: 1024px) {
                    padding-top: 3.5rem;
                    padding-bottom: 3.5rem;
                }

                @media (min-width: 1280px) {
                    padding-left: 2.75rem;
                    padding-right: 2.75rem;
                }

                @media (min-width: 768px) and (max-width: 1023px) {
                    padding-right: 0;
                }

                &-text {
                    color: var(--text-soft-color);

                    @media (min-width: 768px) {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                    }
                }

                &-headline {
                    font-size: 1.875rem;
                    line-height: 2.25rem;
                    font-weight: 700;
                    font-family: 'Neue Power';


                    @media (min-width: 768px) {
                        font-size: 2.25rem;
                        line-height: 2.5rem;
                    }

                    @media (min-width: 1024px) {
                        line-height: 1.625;
                    }

                    @media (min-width: 1280px) {
                        color: 2.5rem;
                    }
                }

                &-button-group {
                    display: flex;
                    margin-top: 8rem;
                    gap: 0.5rem;
                    row-gap: 1.25rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 500;

                    &>* {
                        padding: 1rem !important;
                    }

                    @media (min-width: 640px) {
                        align-items: center;
                    }

                    @media (min-width: 1024px) {
                        gap: 1.5rem;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }

                    @media (max-width: 640px) {
                        flex-direction: column;

                        &>* {
                            justify-content: center;
                            width: 100%;
                        }
                    }

                    @media (max-width: 768px) {
                        margin-bottom: 6rem;
                    }

                    .da-button {
                        background-color: rgba(113, 135, 156, 0.1) !important
                    }

                }
            }

            &__image {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                transition: transform, opacity 500ms ease 300ms;
                transform: translateY(50%);
                opacity: 0;

                &.isVisible {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    .newsletter {
        background-image: linear-gradient(180deg, #fff 50%, #046739 0);

        &__wrapper {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }

        &__content {
            display: grid;
            align-items: center;
            border-radius: 0.75rem;
            max-width: 100%;
            background-color: #059b54;

            @media (min-width: 768px) {
                padding: 2.5rem;
            }

            @media (max-width: 768px) {
                padding: 1rem;
                padding-bottom: 1.5rem;
            }

            @media (min-width: 1024px) {
                grid-template-columns: 3fr 2fr;
                gap: 1.5rem;
            }

            @media (min-width: 1280px) {
                gap: 0;
            }
        }

        &__head {
            color: white;

            &-title {
                margin-bottom: 0.5rem;
                font-size: 1.5rem;
                line-height: 2rem;
                font-weight: 700;

                @media (min-width: 1280px) {
                    margin-bottom: 0;
                }
            }

            &-desc {
                font-weight: 600;

                @media (min-width: 1280px) {
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                }
            }
        }

        .form {
            position: relative;
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media (min-width: 1024px) {
                margin-top: 0;
            }

            &__group-a {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1.5rem;

                @media (min-width: 768px) {
                    gap: 1rem;
                    margin-top: 0;
                    flex-direction: row;
                }

                .input-container {
                    padding: 1.25rem 1rem;
                    width: 100%;

                    @media (min-width: 768px) {

                        &:first-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:last-child {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }

            }

            &__group-b {
                &.input-container {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    padding: 0.375rem;
                    padding-left: 1rem;
                }
            }

            .input-container {
                --ring-color: var(--primary-color);
                --ring-offset-shadow: 0 0 0 0px white;
                --ring-shadow: 0 0 0 3px transparent;
                box-shadow: var(--ring-offset-shadow), var(--ring-shadow), 0 0 #0000 !important;
                transition: box-shadow 0.3s ease;

                position: relative;
                background-color: rgb(230, 245, 246);
                border-radius: 9999px;

                &:focus-within {
                    --ring-shadow: 0 0 0 3px var(--ring-color);
                }
            }

            &__input {
                width: 100%;
                min-width: 0;
                background-color: transparent;
                font-family: SFPro;
                font-size: 1rem;

                &:focus {
                    outline-style: none !important;
                }
            }

            &__label {
                display: flex;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                align-items: center;
            }

            &__submit {
                padding: 0.85rem 1.25rem;
                background-color: black;
                color: white;
                border-radius: 9999px;

                &:hover {
                    svg {
                        transform: translateX(50%);
                    }
                }
            }
        }
    }

    .footer {
        background-color: var(--primary-color);
        color: white;
        overflow: hidden;
        padding-top: 6px;
        
        footer {
            position: relative;
            padding-bottom: 10rem;
            margin-top: -0.25rem;

            @media (min-width: 1024px) {
                padding-top: 1.75rem;
            }

            .social-icon {
                font-size: 1.25rem;
                fill: white;
            }
        }

        &-nav {
            display: grid;
            align-items: start;
            gap: 2rem;

            @media (min-width: 768px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (min-width: 1024px) {
                grid-template-columns: auto 1fr auto;
            }

            @media (min-width: 1280px) {
                gap: 3rem;
            }

            &__logo {
                align-items: start;
                column-gap: 1rem;
                max-width: 100%;

                @media (max-width: 768px) {
                    display: flex;
                }

                @media (max-width: 640px) {
                    display: block;
                }

                svg {
                    width: 1.5rem;
                    height: 1.5rem;

                }

                p {
                    font-size: 0.875rem;
                    margin-top: 0.75rem;
                    margin-bottom: 1rem;

                    @media (min-width: 768px) {
                        max-width: 10rem;
                    }
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                gap: 1.25rem;

                @media (min-width: 1024px) {
                    order: 1;
                }

                .dapp {
                    color: white;
                    background-color: #f7f8fa55 !important;
                    font-weight: bold;
                    justify-content: center;

                    span {
                        border-color: white;
                    }
                }

                .start-invest {
                    border: solid 1px #fff9;
                    justify-content: center;
                    background-color: transparent !important;
                }
            }

            &__links {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 1.5rem;
                row-gap: 2rem;
                font-family: 'Neue Power';

                @media (min-width: 768px) {
                    grid-template-columns: repeat(4, minmax(0, 1fr));

                    @media (max-width: 1024px) {
                        grid-column: 1 / -1;
                    }
                }

                @media (max-width: 640px) {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    margin-top: 1rem;
                }

                &-title {
                    font-weight: 600;
                    margin-bottom: 0.75rem;
                    color: white;

                    @media (min-width: 1024px) {
                        margin-bottom: 1.25rem;
                    }

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }

                &-list {
                    gap: 0.75rem;
                    display: grid;
                    font-weight: 300;
                    font-size: 0.875rem;
                    letter-spacing: 0.075rem;
                }

            }
        }

        &-text {
            display: grid;
            width: 100%;
            align-items: center;
            color: #43e5a0;
            /* or #1acd81 */
            font-size: 1rem;
            overflow: hidden;

            @media (min-width: 768px) {
                grid-template-columns: 1fr auto;
            }

            &>hr {
                border-bottom: 1px solid rgba(113, 135, 156, 0.2);
                border-top: 0;
                grid-column: 1 / -1;
                height: 0 !important;
                margin-top: 2rem;
                margin-bottom: 1.5rem;
            }

            &__text {
                display: flex;
                gap: 0.5rem;
                font-size: 0.875rem;

                @media (max-width: 640px) {
                    justify-content: space-between;
                    width: 100%;

                    span:first-child {
                        order: 1;
                    }
                }

                @media (min-width: 768px) and (max-width: 1024px) {
                    grid-column: 1 / -1;
                }

                @media (max-width: 1024px) {
                    order: 1;
                }
            }

            &__list {
                display: flex;
                gap: 1.5rem;
                flex-shrink: 0;
                max-width: 100%;
                flex-wrap: wrap;
                font-weight: 500;

                @media (max-width: 768px) {
                    margin-top: 2rem;
                    order: -1;
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    @media (min-width: 768px) {
                        font-size: 0.875rem;
                    }
                }
            }

            &__logo {
                width: 100%;
                    filter: invert(1);
                    opacity: 0.1;
                    transform: translateY(40%);
                    position: absolute;
                    bottom: 0;
            }
        }
    }
}