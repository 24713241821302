.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    
    .toggle-slider {
      background-color: #E5E5EA;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:checked + .toggle-slider {
      background-color: var(--teal-300);

      &:before {
        transform: translateX(28px);
        background-color: var(--action-primary);
      }
    }
  }

  .toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #989998;
    transition: all 0.2s ease;
    border-radius: 24px;
    overflow: visible;
    
    &:before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      left: -3px;
      bottom: -3px;
      background-color: white; 
      /* border: 2px solid var(--action-primary);  */
      transition: all 0.2s ease;
      border-radius: 50%;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
    }
  }
}