.EmailPage {
  margin-bottom: 10px;


  .email-header {
    position: relative;
    padding: 28px 20px 0;
    margin-left: 10px;
    
   
    @media (min-width: 1024px) {
      padding: 28px 28px 0;
    }
  }

  .content {
    margin-top: 32px;
    border-top: 1px solid #e5e5e5;

    .email-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 32px;

      .icon-container {
        height: 71px;
        width: 71px;
        background-color: #f5f5f5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 26px;
          color: var(--action-primary);
        }
      }

      .details {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
        }

        p {
          color: #666;
          text-align: center;
          margin-top: 12px;
        }

        button {
          width: 100%;
          margin-top: 32px;
          padding: 16px;
          background-color: var(--action-primary);
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: var(--action-primary);
          }
        }
      }
    }
  }
}