.feed {
  &__section {
    padding-top: 0.5rem;
    
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 20px;
  }

  &__content {
    margin: 0 1.25rem;
    position: relative;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 0.875rem;
    letter-spacing: 0.2em;
    color: #71879C;
    padding: 1.25rem 0 1.5rem;

    @media (min-width: 640px) {
      padding: 1.25rem 0 2rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @media (min-width: 640px) {
      grid-template-columns: 48% 48%;
      justify-content: space-between;
      gap: 2rem;
    }
  }
}

.feed-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 160px;
  cursor: pointer;
  border-radius: 7.8px;
  border: 1px solid #FBFCF8;
  transition: border-color 0.3s;

  &:hover {
    border-color: #41BCC4;
  }

  &__content {
    display: grid;
    grid-template-columns: 30% 65%;
    height: 100px;
    border-bottom: 1px solid rgba(113, 135, 156, 0.5);

    @media (min-width: 640px) {
      grid-template-columns: 33% 67%;
      height: 105px;
    }

    @media (min-width: 1024px) {
      height: 90px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 7.8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__text {
    overflow: hidden;
    padding: 1rem 6% 0;
    height: 100%;
  }

  &__title {
    font-family: 'Tomato', sans-serif;
    font-size: 0.75rem;
    color: #222222;
    overflow: hidden;
  }

  &__excerpt {
    font-size: 0.6875rem;
    color: #71879C;
    padding-top: 0.25rem;
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    height: 60px;

    @media (min-width: 640px) {
      height: 55px;
    }

    @media (min-width: 1024px) {
      height: 50px;
    }
  }

  &__category {
    font-size: 0.725rem;
    color: #41BCC4;
    padding-left: 1.25rem;
  }

  &__time {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0.8;
    padding-right: 11%;

    svg {
      margin-right: 0.5rem;
    }

    span {
      font-size: 0.6875rem;
      color: #71879C;
      white-space: nowrap;
    }
  }
}