.performance-chart {
  background-color: white;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;


  .wrap-performance-header{
    background-color: #41BCC4;
    border-radius: 16px;

  }
  .performance-header {
    text-align: center;
    margin-bottom: 16px;
    background-color: #098f99;
    border-radius: 16px;
    height: 130px;

    .title {
      font-size: 14px;
      opacity: 0.9;
      margin-bottom: 8px;
      color: #feffff;
      font-weight: 500;
      padding-top: 20px;
    }

    .amount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #feffff;

      .currency {
        color: #9FDCE1;
        margin-right: 4px;
      }
    }

    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #feffff;

      svg {
        margin-right: 4px;
      }
    }
  }

  .performance-details {
    display: flex;
    justify-content: space-between;
    background-color: #41BCC4;
    border-radius: 0 0 12px 12px;
    padding: 12px 16px;
    margin-top: -12px;
    color: white;

    .detail-item {
      display: flex;
      align-items: center;
      font-size: 16px;

      .separator {
        opacity: 0.5;
        margin: 0 4px;
      }
    }
  }

  .chart-container {
    margin-top: 24px;
  }

  .chart-tabs {
    display: flex;
    justify-content: space-between;
    background-color: rgba(224, 224, 224, 0.5);
    border-radius: 20px;
    padding: 4px;
    margin-top: 24px;

    .tab {
      flex: 1;
      text-align: center;
      padding: 8px 0;
      font-size: 14px;
      border-radius: 16px;
      border: none;
      background: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &.active {
        background-color: white;
        color: #41BCC4;
        font-weight: 600;
      }
    }
  }
}

.custom-tooltip {
  background-color: #171C22;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  color: white;

  .tooltip-header {
    display: flex;
    font-size: 12px;
    color: #94A1AD;
    margin-bottom: 8px;

    .separator {
      opacity: 0.6;
      margin: 0 4px;
    }
  }

  .tooltip-content {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    div {
      p:first-child {
        font-weight: 600;
        margin-bottom: 4px;
      }

      p:last-child {
        font-size: 12px;
        color: #94A1AD;
      }
    }
  }
}