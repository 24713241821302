.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
    overflow-y: auto;
    padding: 1rem;
  }

  &-container {
    background-color: white;
    width: 100%;
    max-width: 28rem;
    margin-top: 1.5rem;
    border-radius: 1.5rem;
    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);

    &:focus {
      outline: none;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;

    .header {
      position: relative;
      padding: 1.5rem 1.25rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .close-button {
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        color: #2D2D2D;

        .close-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .modal-title {
        text-align: center;
        font-weight: 500;
        font-size: 1.25rem;
        color: #2D2D2D;
        margin: 0;
      }
    }
  }
}

.rates {
  &-section {
    padding: 0 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    .rate-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);

      &:last-child {
        border-bottom: none;
      }

      .rate {
        &-info {
            background-color: transparent !important;
          .rate-label {
            color: #2D2D2D;
            font-size: 1rem;
            margin: 0;
            margin-bottom: 0.25rem;
          }

          .rate-subtitle {
            font-size: 0.75rem;
            color: var(--action-primary);
            margin: 0;
          }
        }

        &-value {
          font-size: 1rem;
          color: #2D2D2D;
          margin: 0;
          font-weight: 500;

          .currency {
            color: rgba(0, 0, 0, 0.6);
            font-weight: normal;
            margin-right: 0.125rem;
          }
        }
      }
    }
  }
}

.disclaimer {
  padding: 1.25rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: left;
}

.accept-button {
  margin: 0 1.25rem 1.25rem;
  width: calc(100% - 2.5rem);
  padding: 1rem;
  background-color: var(--action-primary);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken(#009A6A, 5%);
  }
}