.confirmation-modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        width: 100%;
        max-width: 28rem;
        transform: none;
        transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.75rem 1.25rem;
        background-color: white;
        border-radius: 1rem;
        overflow: hidden;
    }

    .modal-header {
        position: relative;
        display: flex;
        justify-content: center;

        h2 {
            text-align: center;
            font-weight: 500;
            font-size: 1.25rem;
            width: 20ch;
        }
    }

    .modal-body {
        margin-top: 2.5rem;

        p {
            color: #666;
            font-size: 0.875rem;
            text-align: center;
            
            &:not(:first-child) {
                margin-top: 0.5rem;
            }
        }
    }

    .modal-actions {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}