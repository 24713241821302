.tinkoffButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    appearance: none;
    width: 100%;
    border: none;
    background-color: #FFDD2D;
    color: #000C;
    padding: 1rem 0;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
}

.tinkoffButton:hover {
    background-color: #FCC521;
}

.tinkoffButton:active {
    background-color: #FAB619;
}

.tinkoffButton img {
    height: 22px;
    margin-left: 4px;
}