.plans-content {
  padding: 20px;

  .section {
    margin-bottom: 20px;
    margin-top: 20px;

    h2 {
      font-size: 1.125rem !important;
      line-height: 1.25;  
      font-weight: 500;
      margin-bottom: 20px;
    }

    .card-scroll {
      display: flex;
      min-height: 250px;
      overflow-x: auto;
      gap: 20px;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .create-plan-header {
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;

    .return-button {
    position: absolute;
    left: 0; 
    padding: 12px;
    border-radius: 50%;
    background-color: #eaecec;
     font-size: 14px;
     color: var(--action-primary);
    }
}

.create-plan-header 

  .build-wealth-card {
    display: block;
    text-decoration: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
    overflow: hidden;
    height: 25rem;
    width: 100%;
    padding: 1.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .card-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      background-color: transparent;
    }

    .card-text {
      max-width: 80%;
      color: #ffffff;
    }

    .card-title {
      font-size: 1.25rem;
      font-weight: 600;
      font-family: 'Tomato', sans-serif;
      margin-bottom: 0.5rem;
    }

    .card-description {
      font-size: 0.9375rem;
      margin-bottom: 1rem;
      line-height: 1.4;
    }

    .start-investing {
      font-size: 1rem;
      text-decoration: underline;
      font-weight: 500;
    }

    .arrow-icon {
      width: 24px;
      height: 24px;
    }
  }

  .matured-plans {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    color: inherit;
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }

    h2 {
      font-size: 1.125rem !important;
      line-height: 1.75rem !important;
      margin: 0;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      width: 24px;
      height: 24px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-left: auto;
      margin-right: 12px;
    }
  }
}