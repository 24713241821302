.page-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100vh;

  .page-content {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    transition-duration: 300ms;
  }
}

.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;

  .back-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: inherit;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.content-section {
  margin-top: 1.25rem;
  padding: 0 1.25rem;
  
  @media (min-width: 1024px) {
    padding: 0 1.75rem;
  }

  .add-card-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-decoration: none;
    color: inherit;
  }
}

.card-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &:hover {
      background-color: transparent !important;
    } 

  .card-icon {
    font-size: 2.625rem;
    color: var(--primary-color, #007bff);
     margin-top: 15px;
  }
  .bank-icon {
    font-size: 2.425rem;
    color: var(--action-primary);
    margin-top: 15px;
    background-color: rgb(238, 238, 238) !important;
    border-radius: 50%;
  }

  .text-content {
    .subtitle {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      color: var(--soft-color, #71879C);
    }
  }
}

.arrow-icon {
  width: 24px;
  height: 24px;
  color: var(--soft-color, #71879C);
}

.cards-list {
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .no-cards-message {
    margin: 2.75rem 0 1rem;
    font-size: 0.875rem;
    color: var(--soft-color, #71879C);
    text-align: center;
  }
}