.container {
  .full-page {
    height: 100vh;

    .content-wrapper {
      padding: 1.25rem;
      display: grid;
      grid-template-rows: auto 1fr;

      .header-title {
        text-align: center;
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }

      .form-container {
        max-width: 500px;
        margin: 0 auto;

        .form-description {
          font-size: 1rem;
          color: #555;
          margin-bottom: 1.5rem;
        }

        .input-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 1.5rem;

          .phone-input {
            flex-grow: 1;
            margin-left: 1rem;

            .form-label {
              display: none;
            }

            .phone-number {
              width: 100%;
              padding: 0.75rem;
              border: 1px solid #00b4b4;
              border-radius: 5px;
              font-size: 1rem;
              outline: none;
              transition: border-color 0.3s;

              &:focus {
                border-color: #008888;
              }
            }
          }
        }

        .error-message {
          color: #d32f2f;
          font-size: 0.875rem;
          margin-bottom: 1rem;
        }

        .submit-button {
          width: 100%;
          padding: 0.75rem;
          background-color: #00b4b4;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;

          &:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            background-color: #008888;
          }
        }
      }
    }
  }
}
