.options-modal {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 28rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .modal-content {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-top: 2.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    width: 90%;
    position: relative;

    .modal-header {
        display: flex;               
        justify-content: center;     
        align-items: center;      
        text-align: center;
        margin-bottom: 1.5rem;

        h2 {
          font-size: 1.25rem;
          font-weight: 500;
          margin: 0;
          color: #333;
        }
      }

    .close-button {
      position: absolute;
      background: #f5f5f5;
      border: none;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: #333;
      }
    }
  }

  .modal-header {
    text-align: center;
    margin-bottom: 1.5rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
      color: #333;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0px!important;

    .option-item {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      text-decoration: none;
      color: #333;
      border: none;
      background: #f9f9f9;
      border-radius: 0.5rem;
      width: 100%;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .icon-container {
        width: 2.5rem;
        height: 2.5rem;
        background: #e0f7fa;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: #00796b;
        }
      }

      span {
        flex: 1;
        text-align: left;
        color: #333;
        font-size: 1rem;
      }

      &.delete {
        .icon-container {
          background: #ffebee;

          svg {
            fill: #d32f2f;
          }
        }

        span {
          color: #d32f2f;
        }

        &:hover {
          background-color: rgba(211, 47, 47, 0.1);
        }
      }
    }
  }
}