.naira-wallet {
  &__title {
    font-weight: 500;
    margin-bottom: 1.5rem;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__container {
    position: relative;
    margin-bottom: 1.5rem;
  }

  &__bg-image {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    height: 11.6rem;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__content {
    @media (min-width: 768px) {
      width: 100%;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      width: auto;
    }
  }

  &__flex-container {
    display: flex;
    margin-top: 1.5rem;

    @media (min-width: 1024px) {
      margin-top: 0;
    }
  }

  &__mobile-image-container {
    background-color: #F0F3F7;
    padding-left: 0.75rem;
    padding-top: 0.75rem;
    border-top-left-radius: 0.75rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: block;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__mobile-image {
    border-top-left-radius: 0.5rem;
    width: 12.8rem;
    height: 7.9rem;

    @media (min-width: 768px) {
      width: 18.4rem;
    }
  }

  &__text-container {
    margin: auto 0;
    padding-left: 1rem;

    @media (min-width: 768px) {
      padding-left: 1.5rem;
    }

    @media (min-width: 1024px) {
      text-align: center;
      padding-left: 0;
    }
  }

  &__subtitle {
    font-weight: 600;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  &__description {
    font-size: 0.75rem;
    color: #4A5568;

    @media (min-width: 768px) {
      font-size: 0.875rem;
    }

    @media (min-width: 1024px) {
      padding: 0 0.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__cta {
    display: grid;
    text-align: center;
    background-color: var(--action-primary);
    font-size: 0.875rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    color: white;
    font-weight: 500;
    margin: auto 0;
    padding: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  &__link {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}