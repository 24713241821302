.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: transparent;
  width: 100%;
  max-width: 28rem;
  margin-top: 24px;
  outline: none;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);

  &__content {
    display: flex;
    flex-direction: column;
    padding: 28px 20px;
    background-color: white;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__close-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--action-primary);

    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    &:hover {
      color: darken(var(--action-primary-hover), 10%);
    }
  }

  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    width: 15ch;
    margin: 0;
  }

  &__details {
    margin-top: 40px;
  }

  &__detail-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    margin: 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-top: none;
    }
  }

  &__detail-label {
    color: #666;
    white-space: nowrap;
  }

  &__detail-value {
    text-align: right;
    word-break: break-word;
  }

  &__continue-button {
    margin-top: 32px;
    width: 100%;
    padding: 12px 24px;
    background-color: var(--action-primary);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover:not(:disabled) {
    background-color: var(--action-primary-hover);
    color: white !important;
  }
  }
}