  
  .policy {
     max-width: 85rem;
     margin-left: auto;
     margin-right: auto;
  section {
    padding-top: 2.625rem;
    padding-bottom: 2.625rem;

    @media (max-width: 768px) {
       margin-left: 2rem;
     margin-right: 2rem;
    }

    .hero {
      display: grid;
      place-items: center;
      height: 25.938rem;
      width: 100%;
      background-color: #0898A0;
      border-radius: 1.5rem;
      position: relative;
      overflow: hidden;

      @media (max-width: 1024px) {
        height: 15.938rem;
      }

      h1 {
        color: white;
        font-weight: 600;
        font-size: 5rem;

        @media (max-width: 1024px) {
          font-size: 3rem;
        }
      }

      img {
        position: absolute;
        color: transparent;

        &.dark-ball {
          left: -1rem;
          top: -1rem;
          width: 12.313rem;

          @media (max-width: 1024px) {
            width: auto;
            top: 0;
            left: 0;
            width: 7.313rem;
          }
        }

        &.light-ball {
          right: -1rem;
          bottom: -1rem;
          width: 16.375rem;

          @media (max-width: 1024px) {
            width: auto;
            bottom: -1rem;
            width: 12.375rem;
          }
        }
      }
    }
  }

  main {
    container: wide;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    .sidebar {
    grid-column: span 3 / span 3;
    display: flex;
    position: sticky;
    top: 0;
    height: fit-content;
    
}

.nav {
  background-color: #71879C1A;
  padding: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--text-soft-200);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.nav a {
  text-decoration: none;
  color: inherit;
}

.nav a:hover {
  text-decoration: underline;
}

/* Media query for mobile view */
@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}


    .spacer {
      grid-column: span 1 / span 1;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 1.125rem;
      grid-column: span 7 / span 7;

      @media (max-width: 1024px) {
            grid-column: span 10 / span 10;
          }

      h2 {
        font-size: 2.875rem;
        font-weight: bold;
        display: flex;
        @media (max-width: 1024px) {
           display: none;
          }
      }

      .date {
        display: flex;
        align-items: center;
        gap: 0.375rem;

        svg {
          color: var(--text-soft-100);
          font-size: 0.875rem;
          display: none;
        }

        p {
          color: var(--text-soft-100);
          font-size: 1.25rem;
          text-align: justify;
        }
      }

      .section {
        margin-bottom: 1rem;

        h3 {
          font-weight: bold;
          font-size: 1.25rem;
          margin-bottom: 0.75rem;
          
        }
      }

      ul {
        margin-bottom: 1rem;
        padding-left: 1.5rem;
      }

      a {
        color: #0898A0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}