h1.title {
    font-weight: 500;
    margin-bottom: 2rem;
}

.diary-submit-button {
    font-weight: bold;
    background-color: #42CC7D;
    color: white;
    width: 100%;
    height: auto;
    margin: auto;
    border: none;
    border-radius: 5px;
    padding: .8rem;
    max-width: 12rem;
    cursor: pointer;

    margin-top: 1rem;
}

.diary-submit-button:hover {
    background-color: #3CBF73;
}

.diary-submit-button:active {
    background-color: #33a060;
}

.ant-input-number-affix-wrapper {
    width: 100%;
}

.diary-settings .table-form-input {
    max-width: 28rem;
}

.diary-settings .table-form {
    max-width: 28rem;
}