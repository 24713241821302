.loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loading-section .lds-ellipsis > div {
    background: #a3a8ba !important;
}