.transfer-page {
  height: 100vh;
  padding: 28px 20px;
  
  .header {
    position: relative;
    text-align: center;
    margin-bottom: 32px;

    .back-button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: rgb(233, 235, 235);
      border: none;
      cursor: pointer;
      padding: 5px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .transfer-options {
    border-top: 1px solid #e0e0e0;
  }

  .transfer-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid #e0e0e0;

    .option-content {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .icon-container {
      height: 40px;
      width: 40px;
      background-color: #f5f5f5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .option-text {
      display: flex;
      flex-direction: column;
    }

    .option-title {
      font-size: 16px;
    }

    .option-description {
      font-size: 12px;
      color: #757575;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    fill: #00a8a8;
  }

  .icon-container .icon {
    width: 22px;
    height: 22px;
    color: #00a8a8;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}