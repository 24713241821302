.addMoneyContainer {
  height: 100vh;
  transition: duration 300ms;
  padding: 1.75rem 1.25rem;

  @media (min-width: 1024px) {
    padding: 1.75rem;
  }

  .addMoneyGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;

    .addMoneyContent {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      height: 100%;

      .contentWrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        
        .header {
          position: relative;
          
          h2 {
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
          }
        }

        .walletInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 3.5rem;

          svg {
            width: 1.4rem;
            height: 1.4rem;
            margin: 0 auto;
            color: #07af99;
          }

          .walletDetails {
            display: flex;
            flex-direction: column;
            
            &:first-child {
              text-align: left;
            }
            
            &:last-child {
              text-align: right;
            }
            
            .amount {
              color: #6c757d;
            }
          }
        }

        .amountInput {
          display: flex;
          align-items: center;
          border-top: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;
          font-weight: 600;
          margin-top: 2rem;
          
          label {
            padding: 0 0.5rem;
          }
          
          input {
            flex-grow: 1;
            height: 4rem;
            text-align: right;
            border: none;
            
            &:focus {
              outline: none;
            }
            
            &::placeholder {
              color: black;
            }
          }
        }

        .continueButton {
          margin-top: 2.5rem;
          width: 100%;
          padding: 0.75rem 1.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          color: white;
          background-color: #0a917f;
          border: none;
          border-radius: 0.25rem;
          transition: background-color 0.2s;
          
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
          
          &:not(:disabled):hover {
            background-color: #077c6b;
          }
        }
      }
    }
  }
}