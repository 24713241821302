.reset-pin-container {

    .reset-header{
        margin-top: 15px;
        margin-left: 1.7rem;
    }

  .form-container {
    .instruction-text {
      color: #6b7280;
      margin-top: 0.75rem;
    }

    .password-input {
      margin-top: 1.5rem;
      width: 100%;
      position: relative;

      .input-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #d1d5db;
        border-radius: 0.25rem;
        position: relative;

        input {
          flex-grow: 1;
          /* padding: 1.55rem 2.5rem 0.5rem 0.75rem; */
          border: none;
          outline: none;
          width: 100%;

          &:focus,
          &:not(:placeholder-shown) {
            & + .floating-label {
              top: -0.2rem;
              left: 0.5rem;
              transform: translateY(-50%) scale(0.8);
              background-color: white;
              padding: 0 0.25rem;
              color: var(--action-primary);
            }
          }

          &:not(:placeholder-shown) + .floating-label {
            color: #6b7280;
          }
        }

        .floating-label {
          position: absolute;
          top: 50%;
          left: 0.75rem;
          transform: translateY(-50%);
          transition: all 0.2s ease;
          pointer-events: none;
          color: #9ca3af;
          transform-origin: left center;
          background-color: transparent;
          z-index: 1;
        }

        .toggle-visibility {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: rgb(244, 245, 245);
          border-radius: 50%;
          margin-right: 10px;
          cursor: pointer;
          padding: 0.5rem;
          color: var(--action-primary);
          z-index: 10;

          svg {
            width: 25px;
            height: 25px;
            fill: currentColor;
          }

          &:hover {
            background: rgb(244, 245, 245);
          }

        }
      }
    }

    .error-alert {
      margin-top: 1rem;
      display: none;

      &.show {
        display: flex;
        align-items: center;
        background-color: #fee2e2;
        color: #dc2626;
        padding: 0.75rem;
        border-radius: 0.25rem;

        .error-icon {
          margin-right: 0.5rem;
        }
      }
    }

    .reset-button {
      margin-top: 3rem;
      width: 100%;
      padding: 1rem;
      background-color: var(--action-primary);
      color: white;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;

      &:disabled {
        background-color: #93c5fd;
        cursor: not-allowed;
      }
    }
  }
}
