.form-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 28px 20px;
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, sans-serif;

  @media (min-width: 1024px) {
    padding: 28px;
  }

  .intro-text {
    font-size: 14px;
    color: #666;
    margin: 32px 0;
    line-height: 1.5;
  }

  form {
    margin-top: 40px;

    .section {
      margin-bottom: 64px;

      &:last-of-type {
        margin-bottom: 40px;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #1a1a1a;
        margin-bottom: 16px;
      }
    }

    .radio-group {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media (min-width: 768px) {
        gap: 24px;
      }
    }

    .radio-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;

      input[type="radio"] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #d1d5db;
        border-radius: 50%;
        margin: 0;
        position: relative;
        transition: all 0.2s ease;

        &:checked {
          border-color: var(--action-primary);
          background-color: var(--action-primary);

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            background: white;
            border-radius: 50%;
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px rgba(94, 176, 229, 0.2);
        }
      }

      span {
        font-size: 14px;
        color: #1a1a1a;
      }
    }

    .slider-container {
      padding: 20px 0 40px;
      
      .ant-slider {
        margin: 8px 6px 0;

        .ant-slider-mark {
          font-size: 13px;
          
          
          .ant-slider-mark-text {
            color: #666;
            font-weight: 400;
            
            &.ant-slider-mark-text-active {
              color: #333;
            }
          }
        }

        .ant-slider-dot {
          border-color: var(--action-primary);
          border-width: 2px;
          width: 8px;
          height: 8px;
          
          
          &.ant-slider-dot-active {
            border-color: var(--action-primary);  
          }
        }

        .ant-slider-track {
          /* background-color: #5eb0e5; */
          background-color: var(--action-primary);
          color: var(--action-primary);;
          height: 4px;
        }

        .ant-slider-rail {
          background-color: #e2e8f0;
          height: 4px;
        }
      }
    }

    .employment-options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;

        .radio-label {
          flex: 0 0 calc(50% - 12px);
        }
      }
    }

    select {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      background-color: white;
      font-size: 16px;
      color: #1a1a1a;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: 16px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: var(--action-primary);
        box-shadow: 0 0 0 4px rgba(94, 176, 229, 0.1);
      }

      option {
        padding: 12px;
      }
      
    }

    .submit-button {
      width: 100%;
      height: 48px;
      background-color: var(--action-primary);
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover:not(:disabled) {
        background-color: darken(var(--action-primary-hover), 5%);
      }

      &:disabled {
        background-color: #e2e8f0;
        color: #94a3b8;
        cursor: not-allowed;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba(94, 176, 229, 0.2);
      }
    }
  }
}