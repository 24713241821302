.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  .grid-content {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100vh;
    transition: duration-300;
  }
}

.content-wrapper {
  padding: 28px 20px;

  @media (min-width: 1024px) {
    padding: 28px;
  }
}

.header {
  position: relative;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 24px;
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
}

.back-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.description {
  color: #666;
  text-align: center;
  margin: 16px 0;
}

.plans-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plan-item {
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  gap: 12px;
  border-bottom: 1px solid #eee;
  text-decoration: none;
  color: inherit;
  
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.plan-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #007bff;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.plan-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  min-width: 0;

  h3 {
    margin: 0;
    font-weight: normal;
  }

  span {
    color: #666;
    font-size: 14px;
    font-style: italic;
  }
}

.plan-amount {
  font-weight: 600;
}

.loader {
  text-align: center;
  margin-top: 40px;
  grid-column: 1 / 3;
}