.asset-mix-chart {
  width: 200px;
  margin: 0 auto;
  text-align: left;
  font-family: Arial, sans-serif;
  position: relative;

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    color: black;
    margin-bottom: 15px;
    font-weight: normal;
    display: flex;
    justify-content: center;
  }

  .chart-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    
    svg {
      position: absolute;
      width: 100%;
      height: 100%;

      path {
        transition: opacity 0.3s ease;

        &.hovered {
          opacity: 0.8;
        }
      }
    }
  }

  .center-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    span {
      font-size: 20px;
    }
  }

  .hover-info {
    position: absolute;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: white;
    pointer-events: none;
    transform: translate(-50%, -100%);
    white-space: nowrap;

    strong {
      font-weight: bold;
    }
  }
}