
.currency-selector {
  height: 100vh;
  padding: 1.75rem 1.25rem;
  transition: all 0.3s;

  @media (min-width: 1024px) {
    padding: 1.75rem 1.75rem;
  }
}

.currency-selector-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}

.currency-selector-content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  transition: all 0.3s;
}

.currency-selector-header {
  position: relative;
  margin-bottom: 2rem;
}

.back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  border: none;
  border-radius: 50%;
  background: rgb(232, 233, 233);
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.selector-title {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
}

.currency-options {
  margin-top: 2rem;
}

.currency-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  position: relative;
  padding: 0 1rem;
  border-radius: 8px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.option-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.currency-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.currency-name {
  font-size: 0.938rem;
}

.select-button {
  position: absolute;
  inset: 0;
  background: transparent;
  width: 100%;
  cursor: pointer;
  border: none;
}

.currency-flag {
  width: 24px;
  height: 24px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
}

.modal {
  background-color: transparent;
  width: 100%;
  max-width: 28rem;
  margin-top: 24px;

  .modal-header {
    position: relative;
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    h2 {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      margin: 0;
    }
  }

  .close-button {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #009A6A;

    .close-icon {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }
  }

  .rates-container {
    padding: 0;
    background-color: white;

    .rate-item {
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0;

      p {
        margin: 0;
        color: #333;
        font-size: 14px;
      }

      .rate-subtitle {
        margin-top: 4px;
        color: #666;
        font-size: 12px;
      }

      .rate-value {
        font-weight: normal;
      }
    }
  }

  .disclaimer {
    padding: 16px;
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    color: #666;
    background-color: rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  .accept-button {
    width: 100%;
    padding: 16px;
    border: none;
    background-color: var(--action-primary);
    color: white;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    &:hover {
      background-color: darken(#009A6A, 5%);
    }
  }
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}